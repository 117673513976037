import React from 'react';
import styled, { keyframes } from 'styled-components';

type ToastUndoButtonProps = {
  onClick: () => void;
  lable: string;
  duration: number; // длительность анимации в миллисекундах
};

// Обёртка для кнопки – абсолютная позиционирование нужно для корректного расположения SVG
const ButtonContainer = styled.div`
  position: absolute;
  left: 4%;
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  padding: 0.5em;
`;

// Сама кнопка, без стандартного бордера
const StyledButton = styled.button`
  position: relative;
  padding: 0.5em 1em;
  background: transparent;
  color: #297F76;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px;
  z-index: 2;
`;

// SVG-контейнер, который растягивается на всю область обёртки
const SvgContainer = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* клики проходят сквозь SVG */
  z-index: 1;
`;


const shrinkAnimation = keyframes`
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 1;
  }
`;


// Важно: для корректной анимации мы устанавливаем stroke-dasharray равным 1 и pathLength равным 1,
// чтобы animatable значение stroke-dashoffset изменялось от 0 до 1 независимо от размеров.
const AnimatedRect = styled.rect<{ duration: number }>`
  stroke: #297F76;
  stroke-width: 6;
  fill: #fff;
  rx: 25; /* скруглённые углы */
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  animation: ${shrinkAnimation} ${(props) => props.duration}ms linear forwards;
`;

const ToastDurationButton = ({ onClick, duration, lable}: ToastUndoButtonProps) => {
  return (
    <ButtonContainer>
      <StyledButton onClick={onClick}>{lable}</StyledButton>
      <SvgContainer viewBox="0 0 100 40" preserveAspectRatio="none">
        <AnimatedRect x="0" y="0" width="100" height="40" duration={duration} pathLength={1} />
      </SvgContainer>
    </ButtonContainer>
  )
}

export default ToastDurationButton