import React, { useState, useMemo, useEffect } from "react";
import useDataFetcher from "../knisot/hooks/useFetchData";
import { IOrderRehesh, IOrderReheshDetailes, IPatchStatusOrderLine } from "../../../../modules/API/AzmanotRehesh/models/order";
import { Rehesh } from "../../../../modules/API/AzmanotRehesh/azmanotRehesh";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/reducers/rootReducer";
import { LOAD, LOAD_END, SELECT_DATA_FOR_REHESH } from "../../../../../store/actions/actionsTypes";
import { Route, Switch } from "react-router-dom";
import OrderlikeLayout from "../../../../generic/Layouts/OrderlikeLayout";
import ControlSearchPannelLayout from "../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout";
import SearchInput from "../../../../generic/inputs/inputSearch";
import DatePickerModule from "../../../../generic/datePicker/datePicker";
import BodyScrollContainer from "../../../../generic/Layouts/Wrappers/BodyScrollContainer";
import TitlesList from "../../../../generic/titlesRowItem/TitlesList";
import CustomBlockLoader from "../knisot/generic/CustomBlockLoader";
import AzmanotReheshItem from "./Components/AzmanotReheshItem";
import { Defaults } from "../../../../modules/API/Defaults/defaults";
import { IGrowerItem, IGrowerNodeGetResponse } from "../../../../modules/API/Defaults/models/growers";
import DropdownByIcon from "./Layouts/DropdownByIcon";
import { EditIcon, FilterIcon } from "../../../../generic/icons/Icons";
import GrowerFilter from "./Components/GrowerFilter";
import ColumModal from "../../../../generic/Layouts/ColumModal";
import StatusModal from "./Components/StatusModal";
import styled from "styled-components";
import AzmanotReheshDetailsItem from "./Components/AzmanotReheshDetailsItem";
import { convertToDate } from "../../../../modules/convertDateString";
import CircleActiveFilterBtn from "../../../../generic/particles/CircleActiveFilterBtn";
import DropdownTooltip from "../../../../generic/tooltips/DropdownTooltip";


export interface IOrderReheshSelected extends IOrderReheshDetailes {
  parrentID: number;
}

const AzmanotReheshMain = () => {

  const dispatch = useDispatch();

  const setDefaultDate = useSelector(
    (state: RootState) => state.orderRehesh.date
  );

  const isGrower = useSelector((state: RootState) => state.mainReducer.user?.rights)?.some((e) => e === "GROWER")
  const [date, setDate] = useState<string>("");
  const [input, setInput] = useState<string>("");
  const [tabIsTitle, setTabIsTitle] = useState<boolean>(!isGrower);
  const [isStopAnimation, setIsStopAnimation] = useState<boolean>(false);
  const [isOpenStatusModal, setIsOpenStatusModal] = useState<boolean>(false);
  const [isOpenMassSaveModal, setIsOpenMassSaveModal] = useState<boolean>(false);
  const [selectedItemForStatus, setSelectedItemForStatus] = useState<IOrderReheshSelected | null>(null);
  
  const { 
    data: orderData, 
    isLoading: isLoadingOrder, 
    setData: setOrderData,
    error: errorOrder,
    errorCode: errorCodeOrder
  } = useDataFetcher<
    IOrderRehesh[]
  >(
    Rehesh.getOrders, `DateFrom=${date}&DateTo=${date}` //DateFrom=date&DateTo=date
  );

  useEffect(() => {
    if(errorCodeOrder === 405) {
      setDate(errorOrder || "")
    }
  },[errorOrder, errorCodeOrder])

  const { data: growerData} = useDataFetcher<IGrowerNodeGetResponse>(Defaults.getGrowerList, date)
  const [selectedGrower, setSelectedGrower] = useState<IGrowerItem | null>(null);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<number | null>(null);
  const [isFilterRemark, setIsFilterRemark] = useState<boolean>(false);

 
  const patchLineReq = async (data: IPatchStatusOrderLine[], parentID: number) => {
    try {
      await Rehesh.patchStatusOrderLine(data)
      saveStatus(data, parentID)
    } catch (error) {
      console.log(error)
    } finally {

    }
  }
  const acceptMultipleStatusReq = async (status: number) => {
    if (!orderData) return
    dispatch({ type: LOAD })
    const dataPach = orderData.map((item) => {
      return item.DetailsByGrowers.filter(g =>
        (selectedGrower ? g.GrowerID === selectedGrower.GrowerID : true) &&
        (selectedStatusFilter ? g.Status === selectedStatusFilter : true)

      ).map((e) => {
        return { RecID: e.RecID, Status: status, Remarks: e.Remarks }
      })
    }).flat()
    try {
      await Rehesh.patchStatusOrderLine(dataPach)
      const newArr = [...orderData].map((item) => {
        return {
          ...item, DetailsByGrowers: item.DetailsByGrowers.map((e) => {
            return { ...e, Status: status }
          })
        }
      })
      setOrderData(newArr)
      handleMassSaveModal()
    } catch (error) {

    } finally {
      dispatch({ type: LOAD_END })
    }
  }

  const handleDate = (e: object) => {
    dispatch({ type: SELECT_DATA_FOR_REHESH, payload: e });
  };
  const handleFilterControl = () => {
    setIsFilterRemark(!isFilterRemark)
  }

  const clearedGrowerData = useMemo(() => {
    if (!growerData || !orderData) return []
    const growerIds = orderData.map((item) => [...item.DetailsByGrowers]).flat();
    const hashGrowerIds = growerIds.reduce((acc: Record<number, IOrderReheshDetailes>, item) => {
      acc[item.GrowerID] = item;
      return acc
    }, {})
    return growerData.growers.filter((item) => {
      return hashGrowerIds[item.GrowerID]
    }).map((item) => {
      return { ...item, id: item.GrowerID }
    })

  }, [growerData, orderData])

  const renderData = useMemo(() => {
    if (!orderData) return []
    return orderData.filter((item) => {
      return (
        (
          item.OrderID.toString().includes(input) ||
          item.GrowerName?.trim().includes(input) ||
          item.ProductDesc.toString().includes(input)
        ) &&
        (selectedGrower ? item.DetailsByGrowers.some((grower) => grower.GrowerID === selectedGrower.GrowerID) : true)
        &&
        (selectedStatusFilter ? item.DetailsByGrowers.some((grower) => grower.Status === selectedStatusFilter) : true) 
        &&
        (isFilterRemark ? item.DetailsByGrowers.some((grower) => grower.Remarks.trim()) : true)
      );
    })
  }, [orderData, input, selectedGrower, selectedStatusFilter, isFilterRemark])
  
  const handleSelectItemForStatus = (item: IOrderReheshSelected) => {
    setSelectedItemForStatus(item)
    handleStatusModal()
  }

  const handleStatusModal = () => {
    setIsOpenStatusModal(!isOpenStatusModal)
  }

  const saveStatus = (data: IPatchStatusOrderLine[], parrentID?: number) => {
    if (!orderData) return
    let newArr = [...orderData]
    const index = newArr.findIndex((item) => item.RecID === parrentID)
    newArr[index].DetailsByGrowers = newArr[index].DetailsByGrowers.map((item) => {
      const find = data.find((e) => e.RecID === item.RecID)
      if (find) {
        return { ...item, Status: find.Status, Remarks: find.Remarks }
      }
      return item
    })
    setOrderData(newArr)
    handleStatusModal()
  }

  const additioanlData = {
    isStopAnimation: isStopAnimation,
    handleSelectItemForStatus: handleSelectItemForStatus,
    isGrower: isGrower

  }
  const additioanlDataGrower = {
    list: clearedGrowerData,
    selectedGrower: selectedGrower,
    setSelectedGrower: setSelectedGrower,
    selectedStatus: selectedStatusFilter,
    setSelectedStatus: setSelectedStatusFilter
  }
  const additioanlDataStatus = {
    data: selectedItemForStatus,
    save: patchLineReq
  }
  const massSaveProps = {
    save: acceptMultipleStatusReq,
    isMassSave: true
  }

  const detailsRender = useMemo(() => {
    if (!orderData) return []
    return orderData.map((item) => {
      return item.DetailsByGrowers.map(e => {
        return { ...e, parrentID: item.RecID }
      }).filter((grower) =>
        (
          grower.OrderID?.toString().includes(input) ||
          grower.ProductDesc.toString().includes(input)
        ) &&
        (selectedGrower ? grower.GrowerID === selectedGrower.GrowerID : true) &&
        (selectedStatusFilter ? grower.Status === selectedStatusFilter : true) &&
        (isFilterRemark ? grower.Remarks.trim() : true)
      )
    }).flat()
  }, [
    orderData, 
    input, 
    selectedGrower, 
    selectedStatusFilter,
    isFilterRemark
  ])

  const handleMassSaveModal = () => {
    setIsOpenMassSaveModal(!isOpenMassSaveModal)
  }

  return (
    <Switch>
      <Route exact>
        <OrderlikeLayout label={`הזמנות רכש ל ${convertToDate(date)}`}>
          {
            isOpenMassSaveModal &&
            <ColumModal
              title="עדכון גורף"
              Children={StatusModal}
              childrenProps={massSaveProps}
              controlFunc={handleMassSaveModal}
            />
          }
          {
            isOpenStatusModal &&
            <ColumModal
              title="סטטוס"
              Children={StatusModal}
              childrenProps={additioanlDataStatus}
              controlFunc={handleStatusModal}
            />
          }
          <ControlSearchPannelLayout>
            <DropdownTooltip
              tooltipText="הערות למגדל" 
            >

              <CircleActiveFilterBtn 
                withAction
                func={handleFilterControl}
                >
                <EditIcon />
              </CircleActiveFilterBtn>
            </DropdownTooltip>
            <DropdownByIcon
              isSelected={!!selectedGrower || !!selectedStatusFilter}
              icon={<FilterIcon />}
            >
              <GrowerFilter
                {...additioanlDataGrower}
              />
            </DropdownByIcon>
            <DatePickerModule
              disabled={false}
              selected={false}
              setSelectedDateStart={setDate}
              defEndDate={false}
              saveWithoutFormat={handleDate}
              multyDate={false}
              setSelectedDateEnd={false}
              ignorBrod={false}
              isNeedWithoutFormated={true}
            />
            <SearchInput
              input={input}
              setInput={setInput}
            />
          </ControlSearchPannelLayout>
          <Container

          >
            {
              !tabIsTitle &&
              <SideButtonWrapper onClick={handleMassSaveModal}>
                עדכון גורף
              </SideButtonWrapper>
            }
            <TabContainer>

              <Tab
                active={!tabIsTitle}
                onClick={() => setTabIsTitle(false)}
              >
                פרוט
              </Tab>
              {
                !isGrower &&
                <Tab
                  active={tabIsTitle}
                  onClick={() => setTabIsTitle(true)}
                >
                  ריכוז
                </Tab>
              }
            </TabContainer>
            <BodyScrollContainer>
              {
                isLoadingOrder ? (
                  <CustomBlockLoader />
                ) : (
                  <>
                    {
                      tabIsTitle && !isGrower ?
                        <TitlesList
                          list={renderData}
                          uniqueKey={"RecID"}
                          Element={AzmanotReheshItem}
                          noPadding={false}
                          backgroundColor={"#e9f3f2"}
                          additionalData={additioanlData}
                        />
                        :
                        <TitlesList
                          list={detailsRender}
                          uniqueKey={"RecID"}
                          Element={AzmanotReheshDetailsItem}
                          noPadding={false}
                          backgroundColor={"#e9f3f2"}
                          additionalData={additioanlData}
                        />
                    }
                  </>
                )
              }
            </BodyScrollContainer>
          </Container>
        </OrderlikeLayout>
      </Route>
    </Switch>
  );
};
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  /* background: blue; */
  /* z-index: 1; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: #eff3f4;
  border-radius: 30px 30px 0 0;
`;

const Tab = styled.div<{ active: boolean }>`
  padding: 8px 16px;
  color: #838484;
  width: 7em;
  border-radius: 30px 30px 0 0;
  cursor: pointer;
  ${props =>
    props.active ?
      `
                background: #fff;
                border-bottom: none;
                color: #7ab0ab;
            `
      :
      `
            
            `
  }
`;
const SideButtonWrapper = styled.div`
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  width: 6em;
  padding: 0.3em 0.5em;
  color: #297F76;
  height: 2em;
  background: #fff;
  z-index: 2;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  :hover {
    transform: scale(1.1);
    box-shadow: 2px 2px 5px #297F76;
  }
`
export default AzmanotReheshMain;
