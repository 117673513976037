import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FilterAzmanotList from '../likutWorkers/filterAzmanot/selects/FilterAzmanotList'
import SearchTransportAreas from '../../products/ProductChanges/Components/SearchTransportAreas'

export const MainModalWrapper = styled.div`
    position: absolute;
    width: 105%;
    /* height: 20em; */
    top: 4em;
    left: -2.5%;
    border-radius: 20px;
    background: #eff3f4;
    z-index: 2;
    display:  ${props => props.primal ? 'flex' : 'none'};
    flex-flow: column;
    align-items: center;
    padding: 0.5em;
    gap: 0.5em;
    -webkit-box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
`

export const FilterContainer = styled.div`
    width: 90%;
    /* min-height: 4em; */
    background: #fff;
    padding: 0.5em;
    border-radius: 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    -webkit-box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1); 
    box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1);
`
export const AddBtn = styled.button`
    width: 6em;
    height: 2em;
    background: rgba(41,127,118, 0.7);
    border: none;
    border-radius: 30px;
    color: #fff;
    cursor: pointer;
    font-size: 1.2em;
`

export default function LikutMonitorFilter({
    setIsOpen, isOpen, filterFunc, deliveryCodeList, 
    workerGroupList, deliveryID, setDeliveryID, workerKwucaID, 
    setWorkerKwucaID, setProdID, prodID, prodTypes, transportationList, 
     setTransportationID, transportationID
}) {
  
  return (
    <MainModalWrapper primal={isOpen}>
        <FilterContainer>
            <FilterAzmanotList
                setId={setDeliveryID}
                list={deliveryCodeList}
                label={'סבב הפצה - הכל'}
                id={'Delivery_Code'}
                desc={'Delivery_Description'}
                selectedValue={deliveryID}
            />
            <FilterAzmanotList
                setId={setWorkerKwucaID}
                list={workerGroupList}
                label={'קבוצת ליקוט - הכל'}
                id={'SecondCode'}
                desc={'Description'}
                selectedValue={workerKwucaID}
            />
            <FilterAzmanotList
                setId={setProdID}
                list={prodTypes}
                label={'קבוצת הזמנה - הכל'}
                id={'GroupID'}
                desc={'GroupDesc'}
                selectedValue={prodID}
            />
            <SearchTransportAreas 
                list={transportationList}
                defaultID={transportationID}
                setTransportAreaID={setTransportationID}
            />
        </FilterContainer>
        <AddBtn onClick={() => {filterFunc()}}>סנן</AddBtn>
    </MainModalWrapper>
  )
}

LikutMonitorFilter.propTypes = {
    setIsOpen: PropTypes.func,
    isOpen: PropTypes.bool,
    filterFunc: PropTypes.func
}
