import React, {useState} from 'react'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import { IGrowerItem, IGrowerNodeGetResponse } from '../../../../../modules/API/Defaults/models/growers'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import GrowerItem from './GrowerItem'
import { IInvitationPostData } from '../../../../../modules/API/Auth/models/invitation'
import { Auth } from '../../../../../modules/API/Auth/auth'
import { handleNotifications } from '../../../../../generic/utils/handleNotifications'

type Props = {}

const SendInvitePage = (props: Props) => {
  const [serachGrower, setSearchGrower] = useState<string>("")
  const {
    data: growers
  } = useDataFetcher<IGrowerItem[]>(
    Defaults.getUnregistredGrowerList, "data"
  )
  const getInvite = async (data: IInvitationPostData) => {
    try {
      const res = await Auth.getInviteLink(data)
      handleNotifications("ההזמנה נשלחה בהצלחה", {type: 'success'})
    } catch (err) {
      handleNotifications("שליחת ההזמנה נכשלה", {type: 'error'})
    } finally {

    }
  }

  const addData = {
    getInvite
  }
  return (
    <OrderlikeLayout
      label='שליחת הזמנה'
    >
      <ControlSearchPannelLayout>
        <SearchInput 
          input={serachGrower}
          setInput={setSearchGrower}
        />
      </ControlSearchPannelLayout>
      <TitlesList 
        key={'GrowerID'}
        list={growers || []}
        noPadding={false}
        uniqueKey={'GrowerID'}
        additionalData={addData}
        Element={GrowerItem}
      />
    </OrderlikeLayout>
  )
}

export default SendInvitePage