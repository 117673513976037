import { he } from 'date-fns/locale'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import styled from 'styled-components'
import { CalendatDay } from '../../../../../../generic/icons/Icons'

type Props = {
  selected: Date,
  onChange: (date: Date) => void
}

const DatePickerISO = ({
  selected,
  onChange

}: Props) => {
  return (
    <MainWrapper>
      <ReactDatePicker 
        className='date-picker-iso'
        selected={selected}
        onChange={onChange}
        shouldCloseOnSelect
        locale={he}
        dateFormat={'dd/MM/yyyy'}
      
      />
      <IconWrapper>
        <CalendatDay />
      </IconWrapper>
  </MainWrapper>
  )
}
const MainWrapper = styled.label`
    position: relative;
    background: #fff;
    width: 135px;
    display: flex;
    justify-content: flex-end;
    border-radius: 30px;
    padding: 1em ;
    color: #297F76;
    z-index: 2;
    box-shadow: 0px 1px 2px 2px rgba(41,127,118, 0.1);
    span {
      display: none;
    }
    
  .date-picker-iso {
    border: none;
    direction: rtl;
    width: 100px;
    font-size: 1.1em;
    outline: none;
    color: #297F76;
    cursor: pointer;
    z-index: 55;
  }
`
const IconWrapper = styled.div`
  position: absolute;
  top: calc(50% - 1.1em);
  left: 0;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #80E081; */
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    opacity: 0.8;
  }
`
export default DatePickerISO