import React from 'react'
import { IProductMlaiForCreate } from '../../../../../modules/API/Mlai/models/productsForCreate'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { AcceptIcon, AppleIcon, PackageIcon, UserIcon } from '../../../../../generic/icons/Icons'
import RowInput from '../../../../../generic/inputs/RowInput'
import ProductImage from '../../queries/TeudotDetails/Components/ProductImage'
import styled from 'styled-components'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'

interface Props extends IProductMlaiForCreate {
  item: IProductMlaiForCreate,
  index: number,
  handleChangeQty: (qty: number, item: IProductMlaiForCreate) => void
  disabled?: boolean,
  handleSaveFunc?: (item: IProductMlaiForCreate) => void
}

const ProductItem = ({
  index, handleChangeQty, WeightActual, isEdited,
  NumPackagesActulal, ProductDesc, VarietyDesc, disabled,
  GeneralProductID, ProductID, GrowerName, GrowerID, item,
  handleSaveFunc, SizeID
}: Props) => {
  const weightPerPack = WeightActual && NumPackagesActulal ?  
  WeightActual / NumPackagesActulal : 0
  const [prev, setPrev] = React.useState<number>(0)
    if(`${ProductDesc?.trim()}${VarietyDesc}` === 'שסק') {
      console.log(weightPerPack)
    }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(e.target.value) === 0) {
      setPrev(Number(e.target.value))
    }
    handleChangeQty(Number(e.target.value), item)
  }
  const handleSave = () => {
    if(handleSaveFunc) {
      handleSaveFunc(item)
    }

  }
  return (
    <TitleLayout>
      <SidleWrapper>
        {
          isEdited ? 
          <CircleActiveFilterBtn
            func={handleSave}
          >
            <AcceptIcon />
          </CircleActiveFilterBtn>
          :
          <ProductImage 
            id={GeneralProductID}
          />
         
        }
      </SidleWrapper>
      <TitleRow>
        <TitleRowItem
          name={`${ProductDesc.trim()} ${VarietyDesc.trim()} ${SizeID.trim()}`} 
          icon={<AppleIcon />}
        />
      </TitleRow>
      {
        GrowerID ?
        <TitleRow>
          <TitleRowItem
            name={GrowerName?.trim()} 
            icon={<UserIcon />}
          />
        </TitleRow> : ''

      }
      <TitleRow>
        <RowInput 
          // placeholder={'כמות'}
          
          width={6}
          icon={<PackageIcon />}
          iconColor={'#297F76'}
          defValue={NumPackagesActulal}
          handleInput={handleChange}
          isDisabled={!!disabled}
        />
      </TitleRow>
    </TitleLayout>
  )
}
const SidleWrapper = styled.div`
    position: absolute;
    left: 0.5em;
    width: 3.5em;
    height: 3.5em;
    top: calc(50% - 1.75em);
`
export default ProductItem