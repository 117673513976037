import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import BrodMigdalModal from './BrodMigdalModal';
import { BrodMigdalContext } from './Contexts/ContextBrodMigdalProvider';

interface IProps {
  children: React.ReactNode | React.ReactNode[];
}

export const BrodMigdalProvider = ({ 
  children 
}: IProps) => {
  const [modalNumbers, setModalNumbers] = useState<number[]>([])
  const [isOpen, setIsOpen] = useState(false)

  const openModal = (numbers: number[]) => {
    setModalNumbers(numbers)
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <BrodMigdalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {isOpen && (
        <Overlay onClick={closeModal}>
          <ModalWrapper onClick={(e) => e.stopPropagation()}>
            <BrodMigdalModal brodMigdalArraOfNumbers={modalNumbers} />
          </ModalWrapper>
        </Overlay>
      )}
    </BrodMigdalContext.Provider>
  )
}

export const useBrodMigdalModal = () => {
  const context = useContext(BrodMigdalContext)
  if (!context) {
    throw new Error('useBrodMigdalModal must be used within a BrodMigdalProvider')
  }
  return context
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalWrapper = styled.div`
  background: white;
  padding: 1em;
  border-radius: 8px;
  max-height: 90vh;
  overflow-y: auto;
`

