import React from 'react'
import { IDefaults } from '../../../../modules/API/Defaults/models/defaults'
import { ETYPES_OF_BM, TYPES_OF_BM } from '../helpers/typeOfBM'
import styled from 'styled-components'
import TextComponent from './TextComponent'
import TitleLayout from '../../../../main/second/mainbar/kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../../main/second/mainbar/kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../../main/second/mainbar/kosherTeudot/generic/TitleRowItem'
import ToggleIOS from '../../../Toggles/ToggleIOS'

interface Props extends IDefaults {
  item: IDefaults
}

const BrodMigdalItem = ({
  Type, item, Description
}: Props) => {
  const typeBM: ETYPES_OF_BM = TYPES_OF_BM[Type]

  const content = 
    typeBM === ETYPES_OF_BM.Logical_Value ?
     <ToggleIOS 
      disabled 
      defaultChecked={item[typeBM]} 
      index={`${item.Int_ID} ${item.Sub_ID}`} 
     />
     :
      item[typeBM]
  return (
    <TitleLayout>
      <TitleRow >
        <TitleRowItem 
          name={content}
          icon={<></>}
        />
        <TitleRowItem 
          name={
            <TextComponent
              lines={2}
              width='clamp(2em, 180px, 400px)'
              title={Description.trim()}
            >
              {Description.trim()}
            </TextComponent>
          }
          icon={<></>}
        />
        
        <TitleRowItem 
          name={
            <Wrapper>
              {item.Int_ID}
            </Wrapper>
          }
          icon={<>#</>}
        
        />
      </TitleRow>
    </TitleLayout>
  )
}
const Wrapper = styled.div`
  width: clamp(1em, 2em, 200px);
  padding: 0 0.1em;
`
export default BrodMigdalItem