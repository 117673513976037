import React from 'react'
import { VARIATION_FILTER_KAV_LIST } from '../generic/filterKavs'
import styled from 'styled-components'

type Props = {
  id: number
  setId: React.Dispatch<React.SetStateAction<number>> | ((id: number) => void)
}

const KavFilter = ({
  id, setId
}: Props) => {
 
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setId(+e.target.value)
  }
 
  return (
    <MainWrapper>
      <Selector
        onChange={handleSelect}
        defaultValue={id}
      >
        {VARIATION_FILTER_KAV_LIST.map(item => 
          <option value={item.id} key={item.id}>{item.desc}</option>
        )}
      </Selector>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  padding: 0.2em;
`
const Selector = styled.select`
  width: 250px;
  height: 40px;
  padding: 0.5em;
  direction: rtl;
  border-radius: 15px;
  border: none;
  appearance: none;
  color: #8A97AC;
  cursor: pointer;
  font-size: 1.2em;
  outline: none;
`
export default KavFilter