import React, {useState, useMemo} from 'react'
import { IEntriesItem } from '../../../../../modules/API/Miun/models/sortingList'
import styled from 'styled-components';
import ImageComponentHead from '../../../../../generic/imageComponents/ImageComponentHead';
import Image from '../../../../../generic/assets/cogsPNG.png'
import CreateEntrieItem from '../Create/CreateEntrieItem';
import CircleNumericInput from '../../../../../generic/inputs/CircleNumericInput';
import ControlButton from '../../../../../generic/buttons/ControlButton';
import SearchInput from '../../../../../generic/inputs/inputSearch';
import { IWarhouseListItem } from '../../../../../modules/API/Defaults/models/warhouseList';
import { IPackagesItem } from '../../../../../modules/API/Defaults/models/packages';
import SearchWarehouse from '../../knisot/Create/Components/SearchWarehouse';
import SearchPackage from '../../knisot/Create/Components/SearchPackage';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/reducers/rootReducer';
import selectBrodMigdal from '../../../../../generic/utils/selectBrodMigdal';

type Props = {
    item: IEntriesItem;
    saveFunc: (
        item: IEntriesItem, 
        qty: number, 
        restItem: IEntriesItem,
        tranzit: number, 
        packID: number
    ) => void
    warehouseList?: IWarhouseListItem[],
    packageList?: IPackagesItem[]
}

const DivideModal = ({
    item, saveFunc, warehouseList, packageList
}: Props) => {
    const [qty, setQty] = useState(0)
    const [selectedPackage, setSelectedPackage] = useState<number>(0)
    const [selectedWarehouse, setSelectedWarehouse] = useState<number>(0)
    const defaults = useSelector((state: RootState) => state.defaults.defaults)
    const isAllowToChangeTranzit = selectBrodMigdal(defaults, 405, "Logical_Value")
    const render = useMemo(() => {
        let grossPerUnit = item.Gross / item.AmountPack
        let restGross = Math.round(item.Gross - (grossPerUnit * (qty)))
        let restPacks = item.AmountPack - qty
        return {
            rest: {
                ...item,
                Gross: restGross,
                AmountPack: restPacks,
                isSelected: false

            },
            current: {
                ...item,
                Gross: item.Gross - restGross,
                AmountPack: item.AmountPack - restPacks,
                isSelected: false
            }
        }
    }, [qty, item])
    const isAlowtoSave = qty > 0 && (item.AmountPack - qty > 0)
    const handleSave = () => {
        saveFunc(item, qty, render.rest, selectedWarehouse, selectedPackage)
    }
  return (
    <MainWrapper>
        <ImageWrapper>

        <ImageComponentHead
            img={Image}
            large
            />
        </ImageWrapper>
        <ContentWrapper>

            <MainContent>
                {/* <SideWrapper>
                    <CircleNumericInput 
                        defaultValue={qty} 
                        changeFunc={setQty}
                        />
                </SideWrapper> */}
                <SearchInput 
                    input={qty+""}
                    type={'number'}
                    setInput={setQty}
                    iconHide={true}
                    value={'כמות לפיצול'}
                />
                <CreateEntrieItem
                    {...render.rest}
                    item={item}
                    index={0}
                    
                    />
                    {
                        qty > 0 &&
                            <>
                            <CreateEntrieItem
                                {...render.current}
                                item={item}
                                index={0} 
                            />
                            {
                                warehouseList && packageList && (
                                    <>
                                        {
                                            isAllowToChangeTranzit && (
                                                <SearchWarehouse 
                                                    defaultID={selectedWarehouse}
                                                    setWarehouseID={setSelectedWarehouse}
                                                    list={warehouseList}
                                                    placeholder={'בחר מחסן'}
                                                />

                                            )
                                        }
                                        <SearchPackage 
                                            setPackageID={setSelectedPackage}
                                            defaultID={selectedPackage}
                                            list={packageList}
                                            placeholder={'בחר סוג אריזה יציאה '}
                                        />
                                    
                                    </>
                                
                                )
                            }
                            </>
                    }
            </MainContent>
            {
                isAlowtoSave &&
                <ControlButton
                    lable={'שמור'}
                    handleClick={handleSave}
                />
            }
        </ContentWrapper>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    align-items: center;
    height: 100%;
`
const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;


`
const MainContent = styled.div`
    position: relative;
    display: flex;
    width: 90%;
    min-height: 10em;
    flex-flow: column;
    justify-content: flex-start;
    gap: 0.5em;
    /* background: red; */
`
const ImageWrapper = styled.div`
    height: 30%;
`
const SideWrapper = styled.div`
    position: absolute;
    top: calc(50% - 1.5em);
    left: 0.5em;
    z-index: 2;
`
export default DivideModal