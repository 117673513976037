import React, { useState } from 'react'
import { IEntrieDetails, IEntriesKnisotItem } from '../../../../../modules/API/Knisot/models/entrie'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import { SET_IS_OPEN } from '../../../../../../store/reducers/printer'
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import { IPrinterData } from '../../../../../modules/API/Defaults/models/printSticker'
import { handleNotifications } from '../../../../../generic/utils/handleNotifications'
import { IPutDivideData } from '../../../../../modules/API/Miun/models/fetchData'
import { IEntriesItem } from '../../../../../modules/API/Miun/models/sortingList'
import { Miun } from '../../../../../modules/API/Miun/miun'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { IPackagesItem } from '../../../../../modules/API/Defaults/models/packages'
import { IWarhouseListItem } from '../../../../../modules/API/Defaults/models/warhouseList'
import { Product } from '../../../../../modules/API/Products/products'
import { ISizeItem } from '../../../../../modules/API/Products/models/size'
import { IGetReuqestResponseNode } from '../../../../../modules/API/Defaults/models/pallets'
import { IProductsWithVarietyItem } from '../../../../../modules/API/Defaults/models/compliteProducts'
import { IEntriePutData } from '../../../../../modules/API/Market/models/putRequestInterface'
import { Knisot } from '../../../../../modules/API/Knisot/knisot'
import { format } from 'date-fns'



 const useDataKnisaLines = () => {
  const dispatch = useDispatch()
  const printerState = useSelector((state: RootState) => state.printerReducer)
  const packageList: IPackagesItem[] = useSelector((state: RootState) => state.defaults.packageList)
  const user = useSelector((state: RootState) => state.mainReducer.user)
  const isOffice = user?.rights?.includes('OFFICE')
  
  const [isOpenProduct, setIsOpenProduct] = useState<boolean>(false)
  const [isOpenDivideModal, setIsOpenDivideModal] = useState<boolean>(false)
  const [selectedItemForDivide, setSelectedItemForDivide] = useState<IEntriesKnisotItem | null>(null)
  const [knisaDetailsData, setKnisaDetailsData] = useState<IEntriesKnisotItem[]>([])
  const [selectedItem, setSelectedItem] = useState<IEntriesKnisotItem | null>(null)
  

  const {
    data: warehouseList
  } = useDataFetcher<IWarhouseListItem[]>(
    Defaults.getWarhouseList, 'data'
  )
  const {
    data: productsWithVariety
  } = useDataFetcher<IProductsWithVarietyItem[]>(
    Defaults.getProductsWithVariety, 'data'
  )
  const {
    data: palletTypeList
  } = useDataFetcher<IGetReuqestResponseNode>(
    Defaults.getPalletTypes, 'data'
  )
  const {
    data: sizeList
  } = useDataFetcher<{sizes: ISizeItem[]}>(
    Product.getSizes, 'data'
  )

  const handleOpenDivideModal = () => {
    setIsOpenDivideModal(!isOpenDivideModal)
  }

  

  const getKnisaLineByBarcode = async (barcode: string) => {
    const code = barcode.split('-')
    if(code.length !== 2) return 
    dispatch({type: LOAD})
    try {
      const res = await Knisot.getEntryDetailsByBarcode(code[0], code[1])
      setKnisaDetailsData(addIDForTheList(res))
    } catch (err: any) {
      handleNotifications('משהו השתבש', {type: "error"}) 
      
    } finally {
      dispatch({type: LOAD_END})
    }
  }

  const getKnisotLinesByProduct = async (productId: number, date: Date[], growerID: number) => {
    dispatch({type: LOAD})
    const dateFrom = date[0]
    const dateTo = date[1]
    console.log(date)
    try {
      const res = await Knisot.getEntryDetailsByParams(productId, format(dateFrom, 'yyyyMMdd'), format(dateTo, 'yyyyMMdd'), growerID)
      console.log(res)
      setKnisaDetailsData(addIDForTheList(res))
    } catch (err: any) {
      handleNotifications('משהו השתבש', {type: "error"}) 
      
    } finally {
      dispatch({type: LOAD_END})
    }
   
  }

  const handlePrintKnisa = async (entryNum: number | string) => {
    const selectedPrinter = printerState.printerID
    if(!selectedPrinter) {
        dispatch({type:SET_IS_OPEN, payload: true})
        return
    }
    const printerData: IPrinterData = {
        ZPL_15_75: 4,
        PalletEntryNum: entryNum
    }
    dispatch({type: LOAD})
    try {
        await Defaults.printSticker(selectedPrinter, printerData)
        handleNotifications(`כניסה מספר ${entryNum} הודפס בהצלחה`, {type: "success"})
       
    } catch {
        handleNotifications(`כישלון בהדפסת כניסה מספר ${entryNum}`, {type: "error"})
        
    } finally {
        dispatch({type: LOAD_END})

    }
  }

  const changeDeteilsItem = async (data: IEntriePutData ) => {
    if(!selectedItem) return
    try {
      const {Details}: IEntrieDetails = await Knisot.putDetailsKnisa(selectedItem.EntryNum, data)
      dispatch({type: 'CREATE_ENTRIE_DETAILS', payload: Details})
      const foundItem = Details.find(item => item.DetailNum === selectedItem.DetailNum)
      
      const newArr = knisaDetailsData?.map(knisa => {
        if(knisa.EntryNum === selectedItem.EntryNum && knisa.DetailNum === selectedItem.DetailNum) {
          knisa = {
            ...knisa,
            ...foundItem
          }
        }
        return knisa
      })
      setKnisaDetailsData(newArr)
      handleControlProduct()
    } catch (error) {
      console.log(error)
    }
  }
  
  const handleControlProduct = () => {
    setIsOpenProduct(!isOpenProduct)
    setSelectedItem(null)
  }

  const handleSelectDetailsItem = (item: IEntriesKnisotItem) => {
    if(!isOffice) return
    setSelectedItem(item)
    setIsOpenProduct(true)
  }

  const selectForDivide = (item: IEntriesKnisotItem) => {
    if(item.AmountPack <= 1) {
      handleNotifications('לא ניתן לפצל כניסה עם כמות 1', {type: "error"})
    return
    }
    setSelectedItemForDivide(item)
    handleOpenDivideModal()
  }

  const fetchDivide = async (
    putData: IPutDivideData, 
    restItem: IEntriesKnisotItem, 
    item: IEntriesKnisotItem
  ) => {
    dispatch({type: LOAD})
    try {
      let res: IEntriesItem = await Miun.putDivideEntrie(putData)
      if(!knisaDetailsData) return
      
      let newArr = [...knisaDetailsData].map(knisa => {
        if(knisa.EntryNum === restItem.EntryNum && knisa.DetailNum === restItem.DetailNum) {
          knisa = {
            ...restItem,
            Gross: Math.round(knisa.Gross - res.Gross),
            AmountPack: knisa.AmountPack - res.AmountPack
            
          }
        }
        return knisa
      })
      let pushItem = addIDForTheList([{
        ...item,
        ...res
      }])
      newArr.push(...pushItem)
      setKnisaDetailsData(newArr)
      handleOpenDivideModal()
    } catch (err) {
      
    } 
    finally {
      dispatch({type: LOAD_END})
    }
  }

  const saveDevidedItem = (
    item: IEntriesKnisotItem, 
    qty: number, 
    restItem: IEntriesKnisotItem, 
    tranzit: number, 
    packID: number
  ) => {
    if(qty < 1 || item.AmountPack <= qty){
      handleNotifications('כמות לא תקינה', {type: "error"})
      return
    }

    const putData = {
      AmountPack: +qty,
      DetailNum: item.DetailNum,
      EntryNum: item.EntryNum,
      Gross: Math.round((item.Gross / item.AmountPack) * qty),
      OutPackId: packID ? packID : item.OutPackId,
      Tranzit: tranzit
    }
    fetchDivide(putData, restItem, item)
    
  }

  const addProductProps = {
    productsWithVariety: productsWithVariety || [],
    palletList: palletTypeList?.pallets || [],
    packageList: packageList || [],
    cashedDetails: selectedItem,
    sizeList: sizeList?.sizes || [],
    // handleCreateDetails: handleAddDetailesItem,
    handleChangeDetails: changeDeteilsItem,
    handleControlModal: handleControlProduct,
    warehouseList: warehouseList || []
  }

  const divideModalP = {
    item: selectedItemForDivide,
    saveFunc: saveDevidedItem,
    warehouseList: warehouseList || [],
    packageList: packageList || []
  }

  const detailsProps = {
    handleSelectItem: handleSelectDetailsItem,
    print: handlePrintKnisa,
    selectForDivide,
    isOffice: isOffice,
  }

  return (
    {
      knisaDetailsData,
      setKnisaDetailsData,
      getKnisaLineByBarcode,
      getKnisotLinesByProduct,
      handleOpenDivideModal,
      divideModalP,
      isOpenDivideModal,
      addProductProps,
      selectedItem,
      handleControlProduct,
      isOpenProduct,
      detailsProps
    }
  )
}

export default useDataKnisaLines