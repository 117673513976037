import React from 'react'
import ReactDatePicker from 'react-datepicker'
import styled from 'styled-components'
import { CalendatDay } from '../../../../../generic/icons/Icons'

type Props = {
  selectedDates: Date[]
  onChange: React.Dispatch<React.SetStateAction<Date[]>> | ((dates: Date[]) => void)
}

const DatePickerRangeISO = ({
  selectedDates,
  onChange
}: Props) => {
  const handleChange = (dates: [Date, Date]) => {
    onChange(dates)
  }
  return (
    <ReactDatePicker 
      startDate={selectedDates[0]}
      endDate={selectedDates[1]}
      selectsRange
      onChange={handleChange}
      shouldCloseOnSelect
      customInput={
        <MainWrapper>
          <Input 
            value={selectedDates[0]?.toLocaleDateString()}
            readOnly
          />
          -
          <Input 
            value={selectedDates[1]?.toLocaleDateString()}
            readOnly
          />
          <IconWrapper>
            <CalendatDay />
          </IconWrapper>
        </MainWrapper>
      }
    />
  )
}
const MainWrapper = styled.label`
    position: relative;
    background: #fff;
    width: 240px;
    display: flex;
    justify-content: flex-end;
    gap: 0.5em;
    border-radius: 30px;
    padding: 1em ;
    color: #297F76;
    z-index: 2;
    box-shadow: 0px 1px 2px 2px rgba(41,127,118, 0.1);
    span {
      display: none;
    }
    
  
`
const IconWrapper = styled.div`
  position: absolute;
  top: calc(50% - 1.1em);
  left: 0.5em;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #80E081; */
  z-index: 99;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    opacity: 0.8;
  }
  `
const Input = styled.input`
    border: none;
    direction: rtl;
    max-width: 80px;
    font-size: 1em;
    outline: none;
    color: #297F76;
    cursor: pointer;
    z-index: 55;
    /* padding: 0 0.5em; */
`
export default DatePickerRangeISO