import React, { useState } from 'react'
import styled from 'styled-components'
import KnisotMain from './KnisotMain'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../store/reducers/rootReducer'
import { SELECT_DATA_FOR_ENTRY } from '../../../../../store/actions/actionsTypes'
import { format } from 'date-fns'

type Props = {}

const KnisotTeudotIndex = (props: Props) => {
  const dispatch = useDispatch()
  const setDefaultDate = useSelector((state: RootState) => state.entryReducer.date)
  const [date, setDate] = useState<string>(format(new Date(), 'yyyyMMdd'))
  const [dateEnd, setDateEnd] = useState<string>(format(new Date(), 'yyyyMMdd'))
  const selectedDate =  Array.isArray(setDefaultDate) ? setDefaultDate : new Date(setDefaultDate)
  const handleDate = (e: object | object[]) => {
            dispatch({type: SELECT_DATA_FOR_ENTRY, payload: e}
        )
    } 

    console.log(date)
  return (
    <MainWrapper>
      <KnisotMain 
        date={date}
        setDate={setDate}
        dateEnd={dateEnd}
        setDateEnd={setDateEnd}
        handleDate={handleDate}
        selectedDate={selectedDate}
        setDefaultDate={setDefaultDate}
      />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
`
export default KnisotTeudotIndex