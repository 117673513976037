import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/reducers/rootReducer'
import { IWorkerItem } from '../../modules/API/Defaults/models/workers'

type Props = {
  codeInternal: string
}

export enum ENUM_WORKER_TYPES {
  WORKER = "WORKER",
  MALGIZAN = "MALGIZAN",
  MILAKET = "MILAKET",
  DRIVER = "DRIVER",
  ADMIN = "ADMIN",
  MASHGIAH = "MASHGIAH",
  ARIZA = "ARIZA"
}
export const workerTypes = {
  1: ENUM_WORKER_TYPES.WORKER,
  2: ENUM_WORKER_TYPES.MALGIZAN,
  3: ENUM_WORKER_TYPES.MILAKET,
  4: ENUM_WORKER_TYPES.DRIVER,
  5: ENUM_WORKER_TYPES.ADMIN,
  6: ENUM_WORKER_TYPES.MASHGIAH,
  7: ENUM_WORKER_TYPES.ARIZA
}


const useWorkerType = ({codeInternal}: Props) => {
  const workerList: IWorkerItem[] = useSelector((state: RootState) => state.defaults.workerList)
  const rights = useSelector((state: RootState) => state.mainReducer.user?.rights)
  const isWorker = rights?.includes('WORKER')

  const worker = useMemo(() => {
    if(!isWorker) return "NOT_WORKER"
    const foundWorker = workerList.find(worker => worker.WorkerId === +codeInternal)
    if(!foundWorker) return workerTypes[1]
    const type = workerTypes[foundWorker.Role_108]
    return type 
  }, [codeInternal, workerList, isWorker])

  return (
    {
      type: worker
    }
  )
}

export default useWorkerType