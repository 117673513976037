import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

export interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type'> {
  checked?: boolean;
  indeterminate?: boolean;
  onChange?: (checked: boolean) => void;
}

/* Container styled as a label */
const CheckboxContainer = styled.label`
  position: relative;
  display: inline-block;
`;

/* The hidden input – note that it is visually hidden but still accessible */
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;

  &:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
  }

  &:focus {
    opacity: 0.12;
  }

  &:hover {
    opacity: 0.04;
  }

  &:hover:focus {
    opacity: 0.16;
  }

  &:disabled {
    opacity: 0;
  }
`;

/* The visual part of the checkbox */
const StyledCheckbox = styled.span`
  display: inline-block;
  width: 100%;
  cursor: pointer;
  position: relative;

  /* The box */
  &::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    margin: 3px 11px 3px 1px;
    border: solid 2px;
    border-color: rgba(0, 0, 0, 0.6);
    border-radius: 2px;
    width: 18px;
    height: 18px;
    vertical-align: top;
    transition: border-color 0.2s, background-color 0.2s;
  }

  /* The checkmark */
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 1px;
    width: 10px;
    height: 5px;
    border: solid 2px transparent;
    border-right: none;
    border-top: none;
    transform: translate(3px, 4px) rotate(-45deg);
  }

  /* Checked and indeterminate state styling */
  ${HiddenCheckbox}:checked + &::before,
  ${HiddenCheckbox}:indeterminate + &::before {
    border-color: #018786;
    background-color: #018786;
  }

  ${HiddenCheckbox}:checked + &::after,
  ${HiddenCheckbox}:indeterminate + &::after {
    border-color: #fff;
  }

  ${HiddenCheckbox}:indeterminate + &::after {
    border-left: none;
    transform: translate(4px, 3px);
  }

  /* Disabled state styling */
  ${HiddenCheckbox}:disabled + & {
    color: rgba(0, 0, 0, 0.38);
    cursor: initial;
  }

  ${HiddenCheckbox}:disabled + &::before {
    border-color: currentColor;
  }

  ${HiddenCheckbox}:checked:disabled + &::before,
  ${HiddenCheckbox}:indeterminate:disabled + &::before {
    border-color: transparent;
    background-color: currentColor;
  }
`;

const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  indeterminate = false,
  onChange,
  disabled,
  ...props
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.target.checked);
  };

  return (
    <CheckboxContainer>
      <HiddenCheckbox
        ref={checkboxRef}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        {...props}
      />
      <StyledCheckbox />
    </CheckboxContainer>
  );
};

export default Checkbox;