import React from 'react'
import styled from 'styled-components'

interface TruncatedTextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  width?: string
  children: React.ReactNode | string
  lines?: number
}

const TextComponent = ({
  width = '100%',
  children,
  lines = 1,
  ...rest
}: TruncatedTextProps) => {
  return (
    <StyledText lines={lines} {...rest} width={width}>
      {children}
    </StyledText>
  )
}
const StyledText = styled.p<TruncatedTextProps>`
  width: ${({ width }) => width || '200px'};
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  text-align: right;

  ${({ lines }) =>
    lines === 1
      ? `
    white-space: nowrap;
    display: inline-block;
  `
      : `
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    -webkit-line-clamp: ${lines};
  `}
`
export default TextComponent