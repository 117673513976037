import React, {useState, useMemo, useEffect} from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import { useHistory, useParams } from 'react-router-dom'
import ButtonLayout from '../Layouts/ButtonLayout'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import ModalKnisaTitle from './Components/Modal/ModalKnisaTitle'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import { PlusIcon } from '../../../../../generic/icons/Icons'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import useDataFetcher from '../hooks/useFetchData'
import { IGrowerNodeGetResponse } from '../../../../../modules/API/Defaults/models/growers'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import { ICarItemResponse } from '../../../../../modules/API/Defaults/models/cars'
import { IProductsWithVarietyItem } from '../../../../../modules/API/Defaults/models/compliteProducts'
import ModalKnisaAddProduct from './Components/Modal/ModalKnisaAddProduct'
import { IWarhouseListItem } from '../../../../../modules/API/Defaults/models/warhouseList'
import { IEntrie, IEntrieDetails, IEntriesKnisotItem } from '../../../../../modules/API/Knisot/models/entrie'
import { IGetReuqestResponseNode } from '../../../../../modules/API/Defaults/models/pallets'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import { IPackagesItem } from '../../../../../modules/API/Defaults/models/packages'
import TitleCreateKnisa from './Components/TitleCreateKnisa'
import { Knisot } from '../../../../../modules/API/Knisot/knisot'
import { IEntriesItem } from '../../../../../modules/API/Miun/models/sortingList'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import EntryDetailsItem from '../Components/EntryDetailsItem'
import { IEntriePutData } from '../../../../../modules/API/Market/models/putRequestInterface'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import { CLEAR_ENTRY_DETAILS, CREATE_ENTRIE_DETAILS, LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import { createObjectsArray } from './Components/Helpers/createObjectsArray'

import { ISizeItem } from '../../../../../modules/API/Products/models/size'
import { Product } from '../../../../../modules/API/Products/products'
import { LINKS } from '../../../../../Routs/config'
import { ITeudotTypeItem } from '../../../../../modules/API/Defaults/models/teudotTypes'
import { IPrinterData } from '../../../../../modules/API/Defaults/models/printSticker'
import { SET_IS_OPEN } from '../../../../../../store/reducers/printer'
import { useTranslation } from 'react-i18next'
import { IPutDivideData } from '../../../../../modules/API/Miun/models/fetchData'
import DivideModal from '../../miun/modal/DivideModal'
import { Miun } from '../../../../../modules/API/Miun/miun'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import useWorkerType, { ENUM_WORKER_TYPES } from '../../../../../generic/hooks/useWorkerType'
import { handleNotifications } from '../../../../../generic/utils/handleNotifications'

type Props = {
  refetch?: () => void,
  print?: (entryNum: number | string) => void
}
export interface IDetailsSelected {
  productQty: number,
  productID: number,
  varietyID: number,
  price: number,
  weight: number,
  remark: string,
  palletID: number,
  palletQty: number,
  packageID: number,
  externalPalletNum: number,
  sizeID: number,
  tranzit: number
}

const CreateKnisa = () => {
  const [t] = useTranslation(['knisa', 'buttons'])
  const history = useHistory()
  const dispatch = useDispatch()
  const {id} = useParams<{id: string}>()
  const user = useSelector((state: RootState) => state.mainReducer.user)
  const { type } = useWorkerType({codeInternal: user?.code_internal || ''})
  const isOffice = user?.rights?.includes('OFFICE') || type === ENUM_WORKER_TYPES.ADMIN
  const printerState = useSelector((state: RootState) => state.printerReducer)
  const {title, details} = useSelector((state: RootState) => state.entryReducer.create)
  const packageList: IPackagesItem[] = useSelector((state: RootState) => state.defaults.packageList)
  const [isOpenTitle, setIsOpenTitle] = useState<boolean>(false)
  const [isOpenProduct, setIsOpenProduct] = useState<boolean>(false)
  const [knisaTitleData, setKnisaTitleData] = useState<IEntrieDetails | null>(title)
  const [knisaDetailsData, setKnisaDetailsData] = useState<IEntriesKnisotItem[] | null>(details)
  const [selectedItem, setSelectedItem] = useState<IEntriesKnisotItem | null>(null)
  const [searchDetails, setSearchDetails] = useState<string>('')
  
  
  // console.log(selectedItem)
  useEffect(() => {
    if(title || id ) return
    setTimeout(() => {
      setIsOpenTitle(true)
    }, 100)
  }, [])
  const {
    data: growerList
  } =  useDataFetcher<IGrowerNodeGetResponse>(
    Defaults.getGrowerList, 'data'
  )

  const {
    data: carList
  } = useDataFetcher<ICarItemResponse>(
    Defaults.getCarList, 'data'
  )
  const {
    data: warehouseList
  } = useDataFetcher<IWarhouseListItem[]>(
    Defaults.getWarhouseList, 'data'
  )
  const {
    data: productsWithVariety
  } = useDataFetcher<IProductsWithVarietyItem[]>(
    Defaults.getProductsWithVariety, 'data'
  )
  const {
    data: palletTypeList
  } = useDataFetcher<IGetReuqestResponseNode>(
    Defaults.getPalletTypes, 'data'
  )
  const {
    data: sizeList
  } = useDataFetcher<{sizes: ISizeItem[]}>(
    Product.getSizes, 'data'
  )
  const {
    data: teudaTypes
  } = useDataFetcher<ITeudotTypeItem[]>(
    Defaults.getTblTeudotTypes, 'כ'
  )

  const isAllowToShowTitle = isOpenTitle && warehouseList && teudaTypes
  // console.log(config)
  const handleControlTitle = (isSave?: boolean) => {
    
    setIsOpenTitle(!isOpenTitle)
    if(!!!isSave) return handleBack()
  }
  const handleControlProduct = () => {
    setIsOpenProduct(!isOpenProduct)
    setSelectedItem(null)
  }

  const handlePrintKnisa = async (entryNum: number | string) => {
    const selectedPrinter = printerState.printerID
    if(!selectedPrinter) {
        dispatch({type:SET_IS_OPEN, payload: true})
        return
    }
    const printerData: IPrinterData = {
        ZPL_15_75: 4,
        PalletEntryNum: entryNum
    }
    dispatch({type: LOAD})
    try {
        await Defaults.printSticker(selectedPrinter, printerData)
        handleNotifications(`כניסה מספר ${entryNum} הודפס בהצלחה`, {type: "success"})
    } catch {
        handleNotifications(`כישלון בהדפסת כניסה מספר ${entryNum}`, {type: "error"})
    } finally {
        dispatch({type: LOAD_END})
    }
}
  const changeDeteilsItem = async (data: IEntriePutData ) => {
    if(!knisaTitleData) return
    try {
      const {Details}: IEntrieDetails = await Knisot.putDetailsKnisa(knisaTitleData.EntryNum, data)
      dispatch({type: 'CREATE_ENTRIE_DETAILS', payload: Details})
      setKnisaDetailsData(addIDForTheList(Details))
      handleControlProduct()
    } catch (error) {
      console.log(error)
    }
  }
  const changeTitle = async (data: IEntrie) => {
    if(!knisaTitleData) return
    try {
      const {
        EntryNum,
        ...rest
      } = data
      const res: IEntrieDetails = await Knisot.putDetailsKnisa(knisaTitleData.EntryNum, rest)
      dispatch({type: 'CREATE_ENTRIE_TITLE', payload: res})
      setKnisaTitleData(res)
      handleControlTitle(true)
      handleNotifications("הכניסה עודכנה בהצלחה", {type: "success"})
    } catch (error) {
      handleNotifications("משהו השתבש", {type: "error"})
    }
  }

  const handleCreateTitle = async (data: IEntrieDetails) => {
   
    try {
      const {Details, ...rest} = data
      const res: IEntrie = await Knisot.createTitleKnisa(rest)
      dispatch({type: 'CREATE_ENTRIE_TITLE', payload: res})
      setKnisaTitleData({...res, Details})
      handleControlTitle(true)
    } catch (err: any) {
      let error
      if (err.response.status === 405) {
        error = `אסמכתא כבר נקלטה בתאריך ${err.response.data.error}`
      }
      handleNotifications( error || "משהו השתבש", {type: "error"})
      
    }
  }

  const handleAddDetailesItem = async (data: IDetailsSelected, addictional?: {multyplier: number, isCopyWithWeigh: boolean, isEdit: boolean}) => {
    if(!knisaTitleData) return
    const dataFormed: IEntriesKnisotItem = {
      AmountPack: data.productQty,
      DetailNum: 0,
      EntryNum: knisaTitleData.EntryNum,
      Gross: data.weight,
      Tranzit: data.tranzit,
      PalletDetailsCount: 0,
      // ExternalPallet: 0,
      GrowerName: '',
      Pack_Name: '',
      Invoice: 0,
      ExternalPallet: +data.externalPalletNum || 0,
      // id?: string;
      // isSelected?: boolean;
      OutPackId: 0,
      // Given?: boolean,
      // Type?: string
      AmountPallets: data.palletQty,
      Discount: 0,
      Pallete_Desc: '',
      PriceWeight: data.price,
      ProductDesc: '',
      ProductID: data.productID,
      SizeDesc: data.sizeID.toString(),
      SizeNum: data.sizeID,
      TypePack: data.packageID,
      TypePallet: data.palletID,
      VarietyDesc: '',
      VarietyID: data.varietyID,
      // Image: '',
      GeneralProductID: 0,
      CalculationMethod: 0,
      AmountReject:0,
      Reject: false,
      WeightReject: 0,
      PalletsReject: 0,
      Remark: data.remark,
      WeightNeto: data.weight,
      // EntryDetailNum: '',
    }
    let result:IEntriesKnisotItem[]  = [dataFormed]
    if (addictional?.multyplier) {
      
      result = createObjectsArray(
        dataFormed, 
        addictional?.isEdit ? addictional.multyplier : addictional.multyplier + 1, 
        // addictional.multyplier + (addictional?.isEdit ? 0 : 1), 
        addictional.isCopyWithWeigh,
        
      )
    }

    try {
      const {Details}: IEntrieDetails = await Knisot.createDetailsKnisa(knisaTitleData.EntryNum, result)
      // console.log(res)
      dispatch({type: 'CREATE_ENTRIE_DETAILS', payload: Details})
      setKnisaDetailsData(addIDForTheList(Details))
      
      setSelectedItem(null)
      handleControlProduct()
    } catch (error) {
      handleNotifications("משהו השתבש", {type: "error"})
    }
  }
 
  const handleSelectDetailsItem = (item: IEntriesKnisotItem) => {
    if(!isOffice) return
    setSelectedItem(item)
    setIsOpenProduct(true)
  }

  const handleDeleteItem = async (item: IEntriesKnisotItem) => {
    if(!knisaTitleData) return
    dispatch({type: LOAD})
    try {
      await Knisot.deleteEntryDetail(knisaTitleData.EntryNum, item.DetailNum)
      const newArr = knisaDetailsData?.filter(i => i.DetailNum !== item.DetailNum)
      console.log(item.DetailNum)
      dispatch({type: CREATE_ENTRIE_DETAILS, payload: newArr})
      setKnisaDetailsData(newArr || [])
    } catch (error) {
      handleNotifications("משהו השתבש", {type: "error"})

    } finally {
      dispatch({type: LOAD_END})
    }
  }
  
  const handleBack = () => {
    history.push(LINKS.knisot.teudot.mainURL)
    dispatch({type: CLEAR_ENTRY_DETAILS})
  }

  useEffect(() => {
    if(!id) return
    const fetchData = async () => {
      dispatch({type: LOAD})
      setIsOpenTitle(false)
      try {
        const res: IEntrieDetails = await Knisot.getEntryDetails(id)
        const {Details, ...rest} = res
        setKnisaTitleData(res)
        setKnisaDetailsData(addIDForTheList(Details))
      } catch (error) {
      } finally {
        dispatch({type: LOAD_END})
      
      }
    }
    fetchData()
  }, [id])

  const selectForDivide = (item: IEntriesKnisotItem) => {
    if(item.AmountPack <= 1) {
      handleNotifications("can not divided", {type: "error"})
      
    return
    }
    setSelectedItemForDivide(item)
    handleOpenDivideModal()
  }

  const render = useMemo(() => {
    if(!knisaDetailsData) return []
    return knisaDetailsData.filter(item => 
      `${item.ProductDesc.trim()} ${item.VarietyDesc.trim()}`.toLocaleLowerCase().includes(searchDetails.toLocaleLowerCase())
    )
  }, [searchDetails, knisaDetailsData])

  const modalProps = {
    fetchedKtisa: title || knisaTitleData,
    productsWithVariety: productsWithVariety,
    growerList: growerList?.growers || [],
    carList: carList?.cars || [],
    warehouseList: warehouseList || [],
    createTitle: handleCreateTitle,
    teudaTypes: teudaTypes || [],
    changeTitle: changeTitle,
  }
  const addProductProps = {
    productsWithVariety: productsWithVariety || [],
    palletList: palletTypeList?.pallets || [],
    packageList: packageList || [],
    cashedDetails: selectedItem,
    warehouseList: warehouseList || [],
    sizeList: sizeList?.sizes || [],
    handleCreateDetails: handleAddDetailesItem,
    handleChangeDetails: changeDeteilsItem,
    handleControlModal: handleControlProduct,
    tranzitID: knisaTitleData?.Tranzit || 0
  }
  const detailsProps = {
    handleSelectItem: handleSelectDetailsItem,
    print: handlePrintKnisa,
    isOffice: isOffice,
    handleDeleteItem: handleDeleteItem,
    selectForDivide: selectForDivide
  }

  // TODO: divide capabilities
  const [isOpenDivideModal, setIsOpenDivideModal] = useState<boolean>(false)
  const [selectedItemForDivide, setSelectedItemForDivide] = useState<IEntriesKnisotItem | null>(null)
  
  const handleOpenDivideModal = () => {
    setIsOpenDivideModal(!isOpenDivideModal)
  }


  const fetchDivide = async (putData: IPutDivideData, restItem: IEntriesKnisotItem, item: IEntriesKnisotItem) => {
    dispatch({type: LOAD})
    try {
      let res: IEntriesItem = await Miun.putDivideEntrie(putData)
      if(!knisaDetailsData) return
      
      let newArr = [...knisaDetailsData].map(knisa => {
        if(knisa.EntryNum === restItem.EntryNum && knisa.DetailNum === restItem.DetailNum) {
          knisa = {
            ...restItem,
            Gross: Math.round(knisa.Gross - res.Gross),
            AmountPack: knisa.AmountPack - res.AmountPack
          }
        }
        return knisa
      })
      let pushItem = addIDForTheList([{
        ...item,
        ...res
      }])
      newArr.push(...pushItem)
      setKnisaDetailsData(newArr)
      handleOpenDivideModal()
    } catch (err) {
      
    } 
    finally {
      dispatch({type: LOAD_END})
    }
  }

  const saveDevidedItem = (
    item: IEntriesKnisotItem, 
    qty: number, 
    restItem: IEntriesKnisotItem, 
    tranzit: number, 
    packID: number
  ) => {
    if(qty < 1 || item.AmountPack <= qty){
      handleNotifications("can not divided", {type: "error"})
      return
    }
    const putData = {
      AmountPack: +qty,
      DetailNum: item.DetailNum,
      EntryNum: item.EntryNum,
      Gross: Math.round((item.Gross / item.AmountPack) * qty),
      OutPackId: packID ? packID : item.OutPackId,
      Tranzit: tranzit
    }
    fetchDivide(putData, restItem, item)
  }

  const divideModalP = {
    item: selectedItemForDivide,
    saveFunc: saveDevidedItem,
    warehouseList: warehouseList || [],
    packageList: packageList || []
  }

  return (
    <OrderlikeLayout label={id ? t("knisa_create_page_title_ids", {id: `#${knisaTitleData?.EntryNum}`})  : t("knisa_create_page_title")}>
      {
        isAllowToShowTitle &&
          <ColumModal 
            title={
              knisaTitleData ? 
              t("knisa_create_page_title_ids", {id: `#${knisaTitleData.EntryNum}`}) :
              t("knisa_create_modal_title_title")
            }
            Children={ModalKnisaTitle}
            controlFunc={() => handleControlTitle(!!knisaTitleData)}
            childrenProps={modalProps}
          />
      }
      {
        isOpenProduct &&
          <ColumModal 
            title={selectedItem ? t("knisa_create_modal_product_title_edit") : t("knisa_create_modal_product_title_create")}
            Children={ModalKnisaAddProduct}
            controlFunc={handleControlProduct}
            childrenProps={addProductProps}
          />
      }
      {
        isOpenDivideModal &&
          <ColumModal 
            title={`${selectedItemForDivide?.EntryNum}-${selectedItemForDivide?.DetailNum} פיצול כניסה`}
            Children={DivideModal}
            childrenProps={divideModalP}
            controlFunc={handleOpenDivideModal}
          />
      }
      <TitleCreateKnisa
        knisaTitleData={knisaTitleData}
        handleControlTitle={handleControlTitle}
      />
      {
        knisaTitleData &&
          <ControlSearchPannelLayout>
            <SearchInput 
              input={searchDetails}
              setInput={setSearchDetails}
              value={t("knisa_create_page_search_placeholder")}
            />
            {
              isOffice && 
                <CircleActiveFilterBtn
                  func={handleControlProduct}
                >
                  <PlusIcon />
                </CircleActiveFilterBtn>
            }
          
          </ControlSearchPannelLayout>

      }
      <BodyScrollContainer>
        {
          knisaDetailsData &&
            <TitlesList 
              list={render}
              uniqueKey='id'
              Element={EntryDetailsItem}
              noPadding={false}
              backgroundColor='#e9f3f2'
              additionalData={detailsProps}
            />
        }
      </BodyScrollContainer>
      <ButtonLayout>
        <ControlButton 
          lable={t("buttons:back")}
          handleClick={handleBack}
        />
        
      </ButtonLayout>             
    </OrderlikeLayout>
  )
}

export default CreateKnisa