import axios from 'axios'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { LOAD, LOAD_END, LOGOUT, PATCH_PALLET_NUM } from '../../../../../../../store/actions/actionsTypes'
import { BarCodeIcon, PackageIcon, ScalesIcon, ShekelIcon, ShopBagIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import SearchInput from '../../../../../../generic/inputs/inputSearch'
import WarningMessage from '../../../../../../generic/messages/WarningMessage'
import Title from '../../../../../../generic/Title/Title'
import { EXIST_PATH, SAVE_COMMENT_ON_PALLET, SAVE_SELECTED_GOOD } from '../../../../../../modules/API/settings'
import { LikutModals } from '../../../../../../modules/Context/CefiNum'
import { updateStatusAzmana } from '../../../../../../modules/requests/updateAzmanaStatus'
import { SelectPanelWrapper } from '../../../secondbar/azmanotmain/SelectBar'
import { Decorator, MainWrapper } from '../../../secondbar/mainbar2'
import CloseModalAzmanot from '../../../secondbar/modalAzmanot/buttons/closeModalAzmanot'
import SaveAzmanotBtn from '../../../secondbar/modalAzmanot/buttons/saveAzmanotBtn'
import { ControlPanelWrapper } from '../../../secondbar/modalAzmanot/modalAzmanot'
import { HeaderInformationInnerContainer, HeaderInformationInnerItem, HeaderInformationItem, HeaderInformationWrapper, HeaderItemInfo } from '../LikutSectionMain'
import TypeCalcSelect from '../../../market/Collect/Components/generic/TypeCalcSelect'
import { useTranslation } from 'react-i18next'
import SearchGrower from '../../../knisot/Create/Components/SearchGrower'
import TextField from '../../Components/TextField'
import { handleNotifications } from '../../../../../../generic/utils/handleNotifications'

const MainSection = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(233,240,240, 0.7);
    z-index: 3;
    padding: 0 0.5em;
`
const ContentSection = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(233,240,240,0.5);
    z-index: 1;
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    gap: 1em;
    padding: 0.5em 0;
    `

const ContentSectionBody = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background: #fdfdfd;
    padding: 0.5em 0.5em;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 0.5em;
`
const IconWrapper = styled.div`
    height: 3.2em;
    min-width: 3.2em;
    border-radius: 50%;
    background: #e9f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9e9f9f;
    font-size: 1.1em;
`
const ImageContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0.5em;
    height: 3.5em;
    width: 3.5em;
    border-radius: 50%;
    background: #fdfdfd;
`

const MessageWrapper = styled.div`
    /* background: red; */
    padding: 0 1em 0 0;
    width: 350px;
`
export default function LikutEntryModal({
    setLikutModalToggle, goodsCode, entryInformation,
    azmanotListDefault, getToken, ProductID, setDefaultPacks,
    defaultPacks, likutInfo, updateExistPallets, getAzmanotDetailes,
    setInputFocus, defaultPackageList, packageType, checkForSiumLikut,
    errAlert, orderTitle, activeResons, user, persents, 
    testForInitialPAcksOfAmount, isWeightCalculation, growersList
}) {
    const [t] = useTranslation(['likut', 'buttons']);
    // const rights = useSelector(state => state.mainReducer.user.rights)
    const isOffice = user.rights.includes("OFFICE")
    const isDisableTakeGoodsIfNotEnoughRest = useSelector(item => item.defaults.defaults.filter(e => e.Int_ID === 379)[0]?.Logical_Value);
    const defaultArizaGrowerID = useSelector(item => item.defaults.defaults.filter(e => e.Int_ID === 412)[0]?.Num_Value);
    const defaultArizaTranzit = useSelector(item => item.defaults.defaults.filter(e => e.Int_ID === 5)[0]?.Num_Value);
    const defaultArizaID = useSelector(item => item.defaults.defaults.filter(e => e.Int_ID === 382)[0]?.Num_Value);
    const isEditPrice = useSelector(item => item.defaults.defaults.filter(e => e.Int_ID === 599)[0]?.Logical_Value);
    const [typeCalc, setTypeCalc] = useState(0);
    const [growerID, setGrowerID] = useState(defaultArizaGrowerID);
    const withoutSrika = (defaultPacks?.NoTagCollection || isOffice) && !goodsCode;
    
    let initialWeigth = withoutSrika ? (defaultPacks?.OrderWeight / defaultPacks?.OrderPacks) // take weight from order
        : isWeightCalculation && entryInformation?.ProductCalculationMethod !== 1 ? (entryInformation?.AvgWeightPerPack - entryInformation?.PackageTareWeight) // take weight from srika and takes off tara (NettoNetto), brodmigdal on
            : entryInformation?.AvgWeightPerPack; // take weight from srika, brodmigdal off
    
    const isMandatoryWeight = entryInformation ? entryInformation.MandatoryWeight : defaultPacks.MandatoryWeight
    
    const selectIconForPalletType = () => {
        if (!defaultPacks?.PackID) return ''
        let filtered = defaultPackageList.filter(e => e?.PackID === defaultPacks?.PackID)
        let result = filtered[0]
        return result
    }

    const {Pack_Type, Volume} = selectIconForPalletType()
    
    // calculate cost for 1 item in percents
    const costForOneItemInPercents = () => {
        if (Pack_Type === '1' || Pack_Type === '2') return 0
        
        return (1 / (Volume || 60) * 100)
    }

    // -goods qty allowed to put on the pallet
    const allowedRestOfGoods = (100 - persents) / costForOneItemInPercents()

    const defaultRestGoodQty = () => {
        let restOfOrder = defaultPacks ? defaultPacks?.OrderPacks - defaultPacks?.SupplyQty : 0;
        let restOfPallet = allowedRestOfGoods;
        let restInSrika = entryInformation?.BalanceAmount
        if(testForInitialPAcksOfAmount && (restInSrika < restOfPallet && restInSrika < restOfOrder)) return restInSrika
        if((restOfPallet <= 0) || restOfOrder <= 0) return 0
        else if(restOfPallet > restOfOrder) return restOfOrder
        else return restOfPallet
    }
    const calcWeigthFunc = () => {
        const needWeigth = defaultRestGoodQty() * (defaultPacks?.OrderWeight / defaultPacks?.OrderPacks)
        const weigthPerOneItemInSrika = initialWeigth
        if (Pack_Type === '1') {
            return Math.floor(needWeigth / 1)
        } else {
            // check the smallest discrepancy in the calculations, return lowest
            let floor = Math.floor(needWeigth / weigthPerOneItemInSrika)
            let ceil = Math.ceil(needWeigth / weigthPerOneItemInSrika)
            const calcResult = (Math.abs(needWeigth - floor * weigthPerOneItemInSrika) >= Math.abs(needWeigth - ceil * weigthPerOneItemInSrika) ? ceil : floor)
            return calcResult > 0 ? calcResult : defaultPacks?.SupplyQty === 0 ? 1 : 0
            //  || defaultPacks?.SupplyQty === 0 ? 1 : 0
        }
    }
    // final culculation of the good rest
    const currentRestOfgoods = isWeightCalculation && (Pack_Type !== '2') ? Math.round(calcWeigthFunc()) : Math.round(defaultRestGoodQty())

    const warningMessage = () => {
        let restOfOrder = defaultPacks ? defaultPacks?.OrderPacks - defaultPacks?.SupplyQty : 0;
        let restOfPallet = allowedRestOfGoods;
        let restInSrika = entryInformation?.BalanceAmount
        if(testForInitialPAcksOfAmount && (restInSrika < restOfPallet && restInSrika < restOfOrder)) return ['שים לב לכמות', 'אין מספיק יתרות בכניסה']
        if(restOfPallet <= 0 && restOfOrder > 0) return ['שים לב', 'אין מקום על המשטח']
        else if(restOfPallet > restOfOrder) return null
        else return ['שים לב', 'אין מקום על המשטח']
    }

    const [resonState, setResonState] = useContext(LikutModals);
  

    const [packsQnt, setPacksQnt] = useState(currentRestOfgoods < 0 ? 0 : currentRestOfgoods);
    const defaultWithoutMishtah = useSelector(item => item.defaults.defaults === null ? '' : item.defaults.defaults.filter(e => {
        return e.Int_ID === 14 && e?.Sub_ID?.trim() === '2'

    })[0].Num_Value)
    
    const isSelectbleWeight = useSelector(item => item.defaults.defaults.filter(e => e.Int_ID === 573)[0]?.Logical_Value);
    const [weights, setWeights] = useState(
        Pack_Type !== '1' ?
        isMandatoryWeight ? 0 : packsQnt * initialWeigth
            : packsQnt
    );
    const [price, setPrice] = useState(0);
    const dispatch = useDispatch()
    
    const disableSaveIfCannotTakeMoreThenRest = useMemo(() => {
        return isDisableTakeGoodsIfNotEnoughRest && packsQnt > entryInformation?.BalanceAmount
    }, [packsQnt, isDisableTakeGoodsIfNotEnoughRest, entryInformation]) 
    const disableMessage = ['אין מספיק יתרות בכניסה']
  
    
    
    const handlePrice = (e) => {
        setPrice(e)
    }
    
    const acceptFunction = () => {
        if(disableSaveIfCannotTakeMoreThenRest) {
            handleNotifications(disableMessage, {type: "warning"})
            return
        }
        if (activeResons) {
            if (Pack_Type === '1' && Math.abs(weights - defaultPacks?.SupplyQty - defaultPacks?.OrderPacks) <= 1) {
                saveSelectedGood(resonState?.props?.problemID, resonState?.props?.resonID)
                return
            }
            if (+resonState?.props?.resonID === 1) {
                if (packsQnt === calcWeigthFunc()) {

                    saveSelectedGood(resonState?.props?.problemID, resonState?.props?.resonID)
                }
                else if (Pack_Type === '1' && Math.abs((weights + defaultPacks?.SupplyQty) - defaultPacks?.OrderPacks) <= 2) {
                    saveSelectedGood(resonState?.props?.problemID, resonState?.props?.resonID)

                }
                else {
                    // check if packtype - kg, and delta <= 1 accept to save
                    // if (Pack_Type === '1' && Math.abs(packsQnt - defaultPacks) <= 1) {
                    //     saveSelectedGood(resonState?.props?.problemID, resonState?.props?.resonID)
                    //     return
                    // }
                    const condition = isWeightCalculation ? packsQnt > currentRestOfgoods : packsQnt > (defaultPacks ? defaultPacks?.OrderPacks - defaultPacks?.SupplyQty : +Infinity)
                    if (condition) {
                            
                        setResonState({
                            resonTag: 'moreThenNeed',
                            action: saveSelectedGood,
                            active: true,
                            props: {
                                setPacks: setPacksQnt,
                                calcFunc: calcWeigthFunc,
                                packType: Pack_Type,
                                defPack: defaultPacks
                            }
                        })
                    }
                    else if (packsQnt < currentRestOfgoods) {

                        setResonState({
                            resonTag: 'lessThenNeed',
                            action: saveSelectedGood,
                            active: true,
                            props: {
                                setPacks: setPacksQnt,
                                calcFunc: calcWeigthFunc,
                                packType: Pack_Type,
                                defPack: defaultPacks
                            }
                        })
                    }
                    else {
                        // if good is not exist and chosed ishur minael, added scaned good in mishtah
                        saveSelectedGood(resonState?.props?.problemID, resonState?.props?.resonID)
                    }
                }

            } else {
                const condition = isWeightCalculation ? packsQnt > currentRestOfgoods : packsQnt > (defaultPacks ? defaultPacks?.OrderPacks - defaultPacks?.SupplyQty : +Infinity)
                if (condition) {
                    setResonState({
                        resonTag: 'moreThenNeed',
                        action: saveSelectedGood,
                        active: true,
                        props: {
                            setPacks: setPacksQnt,
                            calcFunc: calcWeigthFunc,
                            packType: Pack_Type,
                            defPack: defaultPacks
                        }
                    })
                }
                else if (packsQnt < currentRestOfgoods) {

                    setResonState({
                        resonTag: 'lessThenNeed',
                        action: saveSelectedGood,
                        active: true,
                        props: {
                            setPacks: setPacksQnt,
                            calcFunc: calcWeigthFunc,
                            packType: Pack_Type,
                            defPack: defaultPacks
                        }
                    })
                }
                else {
                    // if good is not exist and chosed ishur minael, added scaned good in mishtah
                    saveSelectedGood(resonState?.props?.problemID, resonState?.props?.resonID)
                }
            }
        }

        else {
            saveSelectedGood()
        }
    }
    
    const saveSelectedGood = async (problemId, resonId) => {
        if (weights && packsQnt === 0) {
            handleNotifications(`חובה לבחור כמות`, {type: "warning"})
            return
        }
        if(isMandatoryWeight && +weights <= 0) {
            handleNotifications(`חובה לבחור משקל`, {type: "warning"})
            return
        }
        dispatch({ type: LOAD })
        setResonState({
            resonTag: null,
            action: null,
            active: false,
            props: {}
        })
        try {
            const weightFinal =
            Pack_Type === '2' || Pack_Type === "1" ? isMandatoryWeight ? +weights : +packsQnt :
                // withoutSrika ? (defaultPacks?.OrderWeight / defaultPacks?.OrderPacks) :
                isWeightCalculation && withoutSrika ? +weights :   
                isWeightCalculation ? (initialWeigth + (entryInformation?.ProductCalculationMethod !== 1 ? entryInformation?.PackageTareWeight : 0)) * +packsQnt 
                        : +weights

            let res = await axios({
                method: withoutSrika ? 'post' : 'patch',
                url: withoutSrika ? SAVE_COMMENT_ON_PALLET : SAVE_SELECTED_GOOD,
                headers: {
                    'Authorization': 'Bearer ' + getToken
                },
                data: withoutSrika ? {
                    "QualityID": 0,
                    "WarehouseID": defaultArizaTranzit || 0,
                    "GrowerID": growerID || defaultArizaGrowerID,
                    "ProductID": defaultPacks?.ProductID,
                    'VarietyID': defaultPacks?.VarietyID,
                    'PalletNum': +likutInfo?.pallet?.palletNum,
                    'OrderNum': likutInfo?.withoutAzmana ? 0 : +likutInfo?.azmanaNum,
                    'Qty': Pack_Type === '1' ? Math.ceil(+weights) : +packsQnt,
                    'Weight': weightFinal,
                    'PalletType': +likutInfo?.pallet?.palletNum === 999999 ? likutInfo?.pallet?.palletType : defaultWithoutMishtah,
                    'OrderRowRecID': defaultPacks?.RecID || 0,
                    "CustomerID": likutInfo?.withoutAzmana ? likutInfo?.clientId : 0,
                    "ScanProblem": isWeightCalculation && resonId === 1 ? 0 : problemId || 0,
                    "ChangeReason": isWeightCalculation && resonId === 1 ? 0 : resonId || 0,
                    "Type_SaleCalculation": typeCalc,
                    "PackID": likutInfo?.withoutAzmana ? defaultArizaID : defaultPacks?.PackID !== 0 ? defaultPacks?.PackID : defaultArizaID,
                    "SalePrice": price || 0

                }
                    :
                    {
                        "QualityID": 0,    
                        "ProductID": 0,
                        'VarietyID': 0,
                        "Warehouse": defaultArizaTranzit || 0,
                        'EntryNum': goodsCode,
                        'PalletNum': +likutInfo?.pallet?.palletNum,
                        'OrderNum': likutInfo?.withoutAzmana ? 0 : +likutInfo?.azmanaNum,
                        'Qty': Pack_Type === '1' ? Math.ceil(+weights) : +packsQnt,
                        'Weight': weightFinal,
                        'PalletType': +likutInfo?.pallet?.palletNum === 999999 ? likutInfo?.pallet?.palletType : defaultWithoutMishtah,
                        'OrderRowRecID': defaultPacks?.RecID || 0,
                        "CustomerID": likutInfo?.withoutAzmana ? likutInfo?.clientId : 0,
                        "ScanProblem": isWeightCalculation && resonId === 1 ? 0 : problemId || 0,
                        "ChangeReason": isWeightCalculation && resonId === 1 ? 0 : resonId || 0,
                        "Type_SaleCalculation": typeCalc,
                        "PackID": likutInfo?.withoutAzmana ? defaultArizaID : defaultPacks?.PackID !== 0 ? defaultPacks?.PackID : defaultArizaID
                    }
            })

            
            if(!likutInfo?.withoutAzmana) {
                updateStatusAzmana(+likutInfo?.azmanaNum, user, 1)
            }
            dispatch({ type: PATCH_PALLET_NUM, payload: res.data.palletNum });
            dispatch({ type: LOAD_END });
            handleNotifications(`פעולה בוצעה בהצלחה`, {type: "success"})
            
            updateExistPallets(res?.data?.palletNum);
            if (!likutInfo.withoutAzmana) {
                getAzmanotDetailes(+likutInfo?.azmanaNum);
            }
            // 
            setLikutModalToggle(false);
            setInputFocus();
            checkForSiumLikut();
        } catch (err) {
            dispatch({ type: LOAD_END })
            setResonState({
                resonTag: 'success',
                active: true,
                props: {
                    title: 'תקלת תקשורת',
                    text: `בדוק שוב את הפריט ברשימה לאחר רענון הדף`,
                    mood: 'false'
                }
            })
            if (!err.response) {
                if (!likutInfo.withoutAzmana) {
                    getAzmanotDetailes(+likutInfo?.azmanaNum);
                }
                handleNotifications(`קרתה תקלה נסה שוב`, {type: "error"})
                
                setLikutModalToggle(false);
            }
            if (err?.response?.data?.data?.length > 0) {
                errAlert(err?.response?.data?.data, 'msg')
            }
            else {
                handleNotifications(`${err?.response?.data?.palletNum} ${err?.response?.data?.message}`, {type: "error"})
                
            }
            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            }
        }
    }
    useEffect(() => {
        if (Pack_Type === '1') {
            setWeights(packsQnt)
        } else {
            setWeights((isMandatoryWeight ? 0 : packsQnt * initialWeigth).toFixed(2))
        }
    }, [packsQnt])
    const handleTypeCalc = (e) => {
        setTypeCalc(e)
    }
    const setScaleFunc = (value) => {
        if (Pack_Type === '1') {
            setPacksQnt(value);
        }
        setWeights(value)
    }

    let TitleText = withoutSrika ?
        `${defaultPacks?.ProductDesc?.trim()} ${defaultPacks?.VarietyDesc?.trim()} ${defaultPacks?.SizeID?.trim() || ''}`
        :
        `${entryInformation?.ProductDesc.trim()} ${entryInformation?.VarietyDesc.trim() || ''} ${entryInformation?.SizeDesc?.trim() || ''}`

    let GrowerName = withoutSrika ? orderTitle?.Customer_Name?.trim() : entryInformation?.GrowerName

    let bacPic = defaultPacks?.ImageUrl === '' ? 'url("https://pngicon.ru/file/uploads/otkrytaja-korobka-128x85.png") center center no-repeat' : `url("${EXIST_PATH}${defaultPacks?.ImageUrl}") center center no-repeat`
    return (
        <MainSection>
            <MainWrapper>
                <Decorator />
                <Title label={TitleText} />
                <HeaderInformationWrapper>
                    <HeaderInformationInnerContainer>
                        <HeaderInformationInnerItem>
                            <ImageContainer style={{ background: bacPic, backgroundSize: 'contain' }} />
                            <HeaderInformationItem>
                                <HeaderItemInfo>
                                    {GrowerName}
                                </HeaderItemInfo>
                                <HeaderItemInfo>
                                    <UserIcon />
                                </HeaderItemInfo>
                            </HeaderInformationItem>
                            <HeaderInformationItem primal>
                                {
                                    goodsCode ?
                                        [
                                            <HeaderItemInfo>
                                                {goodsCode}
                                            </HeaderItemInfo>,
                                            <HeaderItemInfo>
                                                <BarCodeIcon />
                                            </HeaderItemInfo>
                                        ]
                                        : ''
                                }

                            </HeaderInformationItem>
                            <HeaderInformationItem primal>
                                <HeaderItemInfo >
                                    {entryInformation?.Pack_Name}
                                </HeaderItemInfo>
                                <HeaderItemInfo>
                                    {
                                        Pack_Type === '1' ?
                                            <ScalesIcon />
                                            : Pack_Type === '2' ?
                                                <ShopBagIcon />
                                                : <PackageIcon />
                                    }
                                </HeaderItemInfo>
                            </HeaderInformationItem>
                        </HeaderInformationInnerItem>
                    </HeaderInformationInnerContainer>
                </HeaderInformationWrapper>
                <ContentSection>
                    {/* <SelectPanelWrapper>
                        <SearchInput value={`${entryInformation?.ProductDesc.trim()} ${entryInformation?.VarietyDesc.trim()}`} disabled iconHide />
                    </SelectPanelWrapper> */}
                    <ContentSectionBody>
                        {
                            withoutSrika ?
                                <SelectPanelWrapper>
                                  <SearchGrower 
                                    defaultID={growerID || defaultArizaGrowerID}
                                    setGrowerID={setGrowerID}
                                    list={growersList}
                                    required
                                  />
                                </SelectPanelWrapper>
                                : ''
                        }
                        {
                            Pack_Type !== '1' ?
                                <SelectPanelWrapper>
                                    <IconWrapper style={{ color: Pack_Type === '1' || Pack_Type === '2' ? 'tomato' : '#9e9f9f' }}><PackageIcon /></IconWrapper>
                                    {
                                        defaultPacks ? 
                                            <IconWrapper>{defaultPacks?.OrderPacks - defaultPacks?.SupplyQty}</IconWrapper> : ''
                                    }
                                    <SearchInput input={packsQnt} setInput={setPacksQnt} value={'כמות'} iconHide type={'number'} />
                                    {
                                        Pack_Type === '2' ? <IconWrapper style={{ color: '#fff', background: 'tomato' }}>יח</IconWrapper>
                                            : ''
                                    }

                                </SelectPanelWrapper>
                                : ''
                        }
                        {
                            Pack_Type !== '2' || isMandatoryWeight ?
                                <SelectPanelWrapper>
                                    <IconWrapper style={{ color: Pack_Type === '1' || Pack_Type === '2' ? 'tomato' : '#9e9f9f' }}><ScalesIcon /></IconWrapper>
                                    {
                                        defaultPacks && Pack_Type === '1' ? 
                                            <IconWrapper>
                                                {defaultPacks?.OrderPacks - defaultPacks?.SupplyQty}
                                            </IconWrapper> : 
                                                isWeightCalculation ? 
                                                    <IconWrapper>
                                                        {Math.round(defaultPacks?.OrderWeight - defaultPacks?.SupplyWeight)}
                                                    </IconWrapper> :
                                                ''
                                    }
                                    <SearchInput isDisableFocusSelect={false} input={weights} value={'משקל'} setInput={setScaleFunc} iconHide type={'number'} disabled={!isMandatoryWeight && !isSelectbleWeight} />
                                    {
                                        Pack_Type === '1' ? <IconWrapper style={{ color: '#fff', background: 'tomato' }}>קייג</IconWrapper>
                                            : ''
                                    }
                                </SelectPanelWrapper>
                                : ''
                        }
                        {
                            isEditPrice &&
                                <SelectPanelWrapper>
                                    <IconWrapper><ShekelIcon /></IconWrapper>
                                    {
                                        defaultPacks && <IconWrapper>{price}</IconWrapper>
                                    }
                                    <SearchInput input={price} value={'מחיר'} setInput={handlePrice} iconHide type={'number'} />
                                    <TypeCalcWrapper>  
                                        <TypeCalcSelect 
                                            handleSelect={handleTypeCalc}
                                            defaultType={typeCalc}
                                        />
                                    </TypeCalcWrapper>
                                </SelectPanelWrapper>
                        }
                        <TextField
                            isWarrning={entryInformation?.BalanceAmount < 0}
                        >
                            יתרות בכניסה: {entryInformation?.BalanceAmount}
                        </TextField>
                        <MessageWrapper>
                            {
                                defaultPacks && (defaultPacks?.OrderPacks - defaultPacks?.SupplyQty > currentRestOfgoods) ? 
                                    <WarningMessage 
                                        messageArray={warningMessage()}
                                    /> 
                                        : ''
                            }
                            {
                                disableSaveIfCannotTakeMoreThenRest && 
                                    <WarningMessage 
                                        messageArray={disableMessage}
                                    /> 
                            }
                        </MessageWrapper>

                    </ContentSectionBody>
                    
                </ContentSection>
                <ControlPanelWrapper>
                    <CloseModalAzmanot closeModalAzmanot={() => { setLikutModalToggle(false); setInputFocus() }} />
                    {
                        withoutSrika ? 
                            growerID ?
                            <SaveAzmanotBtn createAzmana={acceptFunction} /> : 
                            '' :
                            <SaveAzmanotBtn createAzmana={acceptFunction} />
                    }
                </ControlPanelWrapper>
                {/* <button style={{zIndex: '1'}} onClick={() => setLikutModalToggle(false)}>{defaultPacks?.OrderPacks - defaultPacks.SupplyQty}</button>  */}
            </MainWrapper>

        </MainSection>
    )
}

const TypeCalcWrapper = styled.div`
    height: 100%;
    padding: 0.5em 0;
    div {
        height: 100%;

        select {
            height: 100%;
        }
    }
`