import React, {useState, useMemo} from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { IClientBills } from '../../../../../modules/API/Bills/models/clientBills'
import { Bills } from '../../../../../modules/API/Bills/bills'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import ClientBillTitleItem from './Components/ClientBillTitleItem'
import CustomBlockLoader from '../../knisot/generic/CustomBlockLoader'
import { LINKS } from '../../../../../Routs/config'
import ClientBillDetailsMain from './Components/ClientBillDetailsMain'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import RemarkModal from '../../arizot/View/RemarkModal'
import { IRemarkData } from '../../arizot/View/ArizotViewMain'
import DatePickerUniModule from '../../../../../generic/datePicker/datePickerUniModule'
import { useDispatch, useSelector } from 'react-redux'
import { SELECT_DATA_CLIENT_BILLS } from '../../../../../../store/actions/actionsTypes'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import DatePickerMonth, { IDateFormat } from './Components/DatePickerMonth'
import { format } from 'date-fns'

type Props = {
  getBills?: (reqString: string) => Promise<any>;
  url?: string;
  selectItemLink?: string;
  title?: string;
  getDetailes?: (reqString: string) => Promise<any>;
  printUrl?: string;
}
const getFullMonthFromDate = (date: Date) => {
  const start = new Date(date.getFullYear(), date.getMonth(), 1)
  const end = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  return {start, end}
}

const saveDateToSessionStorage = (date: IDateFormat) => {
  sessionStorage.setItem('clientBillsDate', JSON.stringify(date))
}

const getDateFromSessionStorage = () => {
  const date = sessionStorage.getItem('clientBillsDate')
  if(date) {
    const form: IDateFormat = JSON.parse(date)
    return {
      start: new Date(form.start),
      end: new Date(form.end)
    }
  }
  return getFullMonthFromDate(new Date())
}

const ClientsBills= ({
  getBills, url, title, selectItemLink, getDetailes, printUrl
}: Props) => {
  const {path} = useRouteMatch();

  const token: string | null = useSelector((state: RootState) => state.mainReducer.token)
  const isProvidePromise = !!getBills
  const editPath = url || LINKS.bills.clientBills.editGeneral
  const linkToDetails = selectItemLink || LINKS.bills.clientBills.editByID
  const lable = title || 'חשבונית מגדל'
  const setDefaultDate = useSelector((state: RootState) => state.clientBillsReducer.date)
  const [isOpenRemark, setIsOpenRemark] = useState<boolean>(false);
  const [remarkData, setRemarkData] = useState<IRemarkData>();
  const [dateMonth, setDateMonth] = useState<IDateFormat>(() => getDateFromSessionStorage())
  const [search, setSearch] = useState<string>("");
  
  const {data, isLoading} = useDataFetcher<IClientBills[]>(
    isProvidePromise ? getBills : Bills.getBills, `DateFrom=${format(dateMonth.start, 'yyyyMMdd')}&DateTo=${format(dateMonth.end, 'yyyyMMdd')}`
  )

  const handlePrint = async (id: number | string) => {
    const url = printUrl ? `${printUrl}${id}/pdf` : `invoice/grower/${id}/pdf`
    try {
      let response = await fetch(
        `https://my.agroline.co.il/v2/${url}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = await response.blob();
      // Receive PDF Steam as as Blob
      const file = new Blob([data], { type: "application/pdf" }); //Create a Blob from the PDF Stream
      const fileURL = URL.createObjectURL(file); //Build a URL from the file
      window.open(fileURL); //Open the URL on new Window
    } catch (err) {

    }
  }
  
  const render = useMemo(() => {
    if(!data) return []
    return data.filter((item: IClientBills) =>
      item.Name?.trim().includes(search) ||
        item.Invoice.toString().includes(search) 
    )
  },[data, search])

  const handleRemarkModal = () => {
    setIsOpenRemark(!isOpenRemark)
  }

  const selectRemarkData = (data: IRemarkData) => {
    setRemarkData(data)
    handleRemarkModal()
  }

  const additionalProps = {
    selectRemarkData: selectRemarkData,
    selectItemLink: linkToDetails,
    backUrl: path,
    print: handlePrint
  }

  const remarkWindowProps = {
    data: remarkData,
    close: handleRemarkModal
  }
  const handleSelectWholeMonth = (date: Date) => {
    const dateFormed = getFullMonthFromDate(date)
    setDateMonth(dateFormed)
    saveDateToSessionStorage(dateFormed)
  }


  const selectedDate =  Array.isArray(setDefaultDate) ? setDefaultDate : new Date(setDefaultDate)
  return (
    <Switch>
      <Route path={path} exact>
        <OrderlikeLayout label={lable}>
          {
            isOpenRemark ? 
              <ColumModal 

                title={remarkData?.title || ''}
                Children={RemarkModal}
                controlFunc={handleRemarkModal}
                childrenProps={remarkWindowProps}
              />
            : null
          }
          <ControlSearchPannelLayout>
           
            <DatePickerMonth 
              defaultDate={dateMonth.start}
              handleDate={handleSelectWholeMonth}
            />
            <SearchInput
              input={search}
              setInput={setSearch}
            />
          </ControlSearchPannelLayout>
          <BodyScrollContainer>
            {
              isLoading ? <CustomBlockLoader /> :
                <TitlesList 
                  list={render}
                  uniqueKey={'Invoice'}
                  Element={ClientBillTitleItem}
                  noPadding={false}
                  additionalData={additionalProps}
                  backgroundColor={'#E9F3F2'}
                />
            }
          </BodyScrollContainer>
        </OrderlikeLayout>
      </Route>
      <Route 
        path={editPath}
        render={() => 
          <ClientBillDetailsMain 
            backUrl={path} 
            getByID={getDetailes}
          />}
      />
    </Switch>
  )
}

export default ClientsBills