import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { useDetectOutsideClick } from '../hooks/useDetectOutsideClick';

interface TooltipProps {
    tooltipText: string;
    children: React.ReactNode;
}
interface TooltipTextProps {
    left: number;
  }

  const isDeviceMobile = () => {
    console.log('ontouchstart' in window ||
      navigator.maxTouchPoints > 0 )
     return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 
    );
  }
  
const DropdownTooltip = ({ tooltipText, children }: TooltipProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [left, setLeft] = useState(0);
    const isOpen = !isDeviceMobile()
    // Функция пересчёта горизонтального смещения тултипа
    const updatePosition = () => {
      if (containerRef.current && tooltipRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        const defaultLeft = containerRect.width / 2 - tooltipRect.width / 2;
        const globalLeft = containerRect.left + defaultLeft;
        let newLeft = defaultLeft;
  
        // Если тултип выходит за левый край окна
        if (globalLeft < 0) {
          newLeft = -containerRect.left;
        }
        // Если тултип выходит за правый край окна
        else if (globalLeft + tooltipRect.width > window.innerWidth) {
          newLeft = window.innerWidth - containerRect.left - tooltipRect.width;
        }
        setLeft(newLeft);
      }
    };
  
    useEffect(() => {
      updatePosition();
      window.addEventListener('resize', updatePosition);
      return () => window.removeEventListener('resize', updatePosition);
    }, [tooltipText, children]);
    return (
      <TooltipContainer isOpen={isOpen} ref={containerRef}>
        {children}
        <TooltipText ref={tooltipRef} left={left}>{tooltipText}</TooltipText>
      </TooltipContainer>
    );
  };
export default DropdownTooltip


const TooltipText = styled.div<TooltipTextProps>`
    
  visibility: hidden;
  width: clamp(50px, 6em, 8em);
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 999;
  top: 125%;
  left: ${({ left }) => `${left}`}px;
  /* margin-left: -100px; */
  opacity: 0;
  transition: opacity 0.3s;

  &::after {
    content: "";
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.6) transparent;
  }
`;

const TooltipContainer = styled.div<{isOpen: boolean}>`
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:hover ${TooltipText} {
    visibility: visible;
    opacity: ${({isOpen}) => isOpen ? 1 : 0};
  }
`;