import React, {useState} from 'react'
import { IPalletDetails } from '../../../../../../modules/API/Pallets/models/palletDetails'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { AppleIcon, PackageIcon, PalletIcon } from '../../../../../../generic/icons/Icons'
import SelectFromTheList from '../../../../../../generic/customSearchComponents/SelectFromTheList'
import { IPalletTypeItem } from '../../../../../../modules/API/Defaults/models/pallets'
import styled from 'styled-components'
import CircleNumericInput from '../../../../../../generic/inputs/CircleNumericInput'
import { IPackagesItem } from '../../../../../../modules/API/Defaults/models/packages'

interface IProps extends IPalletDetails {
  palletTypes: IPalletTypeItem[],
  packageList: IPackagesItem[],
  handlePatchItem: (recID: number, fieldName: string, value: string | number) => void

}

const PalletDetalelItem = ({
  palletTypes, packageList, ProductDesc, Quantity, RecID,
   Pallet_Type, Quantity_Pallets, Pack_Name, PackID, handlePatchItem
}: IProps) => {
  const [selectedPalletType, setSelectedPalletType] = useState<number>(Pallet_Type)
  const [selectedPackType, setSelectedPackType] = useState<number>(PackID)

  const handleChangeQtyPallets = (e: number) => {
    handlePatchItem(RecID, 'Quantity_Pallets', e)
  }
  const handleChangeQtyPacks = (e: number) => {
    handlePatchItem(RecID, 'Quantity', e)
  }
  const handleChangePalletType = (e: string) => {
    const toNumber = Number(e)
    setSelectedPalletType(toNumber)
    handlePatchItem(RecID, 'Pallet_Type', toNumber)
    const isWithoutPallet = palletTypes.find(item => item.Pallete_Type === toNumber)?.Real_Pallete === 0
    
    if (isWithoutPallet) {
      console.log(isWithoutPallet)
      setTimeout(() => {
        handlePatchItem(RecID, 'Quantity_Pallets', 0)
      }, 100)
    }
  }

  const handleChangePackType = (e: string) => {
    const toNumber = Number(e)
    setSelectedPackType(toNumber)
    handlePatchItem(RecID, 'PackID', toNumber)
    console.log(toNumber)
  }
  return (
    <TitleLayout>
      <TitleRow>
        <TitleRowItem name={ProductDesc.trim()} icon={<AppleIcon />}/>
      </TitleRow>
      <TitleRow>
        <ItemWrapper>
          <SelectFromTheList 
            size={'full'}
            lable={'סוג משטח'}
            list={palletTypes}
            selectedValue={selectedPalletType}
            dispalyFieldName='Pallete_Desc'
            itemKey={'Pallete_Type'}
            fieldValue='Pallete_Type'
            selectFunction={handleChangePalletType}
          
          />
          <CircleNumericInput 
            value={Quantity_Pallets}
            changeFunc={handleChangeQtyPallets}
          />
          <TitleRowItem name={''} icon={<PalletIcon />}/>
        </ItemWrapper>
      </TitleRow>
      <TitleRow>
        <ItemWrapper>
          <SelectFromTheList 
            required={false}
            size={'full'}
            lable={'סוג משטח'}
            list={packageList}
            selectedValue={selectedPackType}
            dispalyFieldName='Pack_Name'
            itemKey={'PackID'}
            fieldValue='PackID'
            selectFunction={handleChangePackType}
          
          />
            <CircleNumericInput 
              value={Quantity}
              changeFunc={handleChangeQtyPacks}
            /> 
            
          <TitleRowItem name={''} icon={<PackageIcon />}/>
        </ItemWrapper>

      </TitleRow>
    </TitleLayout>
  )
}
const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  gap: 0.5em;
  align-items: center;
`
export default PalletDetalelItem