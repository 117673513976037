import React, {useState,useMemo, useEffect} from 'react'
import { IWarhouseListItem } from '../../../../../../modules/API/Defaults/models/warhouseList'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
  required?: boolean
  list: IWarhouseListItem[]
  defaultID: number
  setWarehouseID: React.Dispatch<React.SetStateAction<number>> | ((id: number) => void)
  disabled?: boolean
  placeholder?: string
}


const SearchWarehouse = ({
  list, required, setWarehouseID, defaultID, disabled, placeholder
}: Props) => {
  console.log(defaultID)
  const defaultWarehouse = list.filter(item => item.WarehouseID === defaultID)[0]
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [selectedWarehouse, setSelectedWarehouse] = useState<IWarhouseListItem | null>(defaultWarehouse || null)
  const [search, setSearch] = useState<string>(() => defaultWarehouse?.WarehouseDesc.trim() || '')
  console.log(defaultWarehouse)
  const handleSearch = (e: string) => {
    if(e === '') {
      setSelectedWarehouse(null)
      setIsOpenDropDown(false)
      setSearch('')
      setWarehouseID(0)
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: IWarhouseListItem) => {
    setSearch(item.WarehouseDesc.trim())
    setSelectedWarehouse(item)
    setIsOpenDropDown(false)
    setWarehouseID(item.WarehouseID)
  }
  // useEffect(() => {
  //   if(defaultWarehouse) {
  //     setSelectedWarehouse(defaultWarehouse)
  //     setWarehouseID(defaultWarehouse.WarehouseID)
  //     setSearch(defaultWarehouse.WarehouseDesc.trim())
  //   }
  // }, [defaultWarehouse])
  const render = useMemo(() => {
    if(!list) return []
    return list.filter(item => 
      item.WarehouseDesc.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
  }, [search, list])
  
  return (
    <SearchFromTheList
      isOpenList={isOpenDropDown}
      placeHolder={placeholder || 'חפש מחסן'}
      list={render}
      selectFunc={handleSelect}
      searchFunc={handleSearch}
      isNeedFullObject
      input={search}
      itemDisplayName='WarehouseDesc'
      nameIdElement='WarehouseID'
      required={required}
      size={'full'}
      disabled={disabled}
    />
  )
}

export default SearchWarehouse