import styled from "styled-components";
import { format } from "date-fns";
import { he } from "date-fns/locale";
import DatePicker from "react-datepicker";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CalendatDay } from "../icons/Icons";

const Wrapper = styled.label`
    position: relative;
    max-width: ${ props => props.large ? '250px' : '150px'};
    z-index: 1;
    span {
        display: none
    }
    overflow: hidden;
   
   
`
const Input = styled.input`
    height: 3.2em;
    padding: 0.5em; 
    /* max-width: 100%; */
    text-align: center;
    border-radius: 30px;
    background: #eff3f4;
    font-size: 1.1em;
    max-width: ${ props => !props.large ? '57px' : '130px'};
    
    color: #838484;
    outline: none;
    border: 2px solid #eff3f4; 
    :focus {
        animation: 2s pulse infinite;
    }
    @keyframes pulse {
        0% {
            border: 2px solid #eff3f4; 
        }
        50% {
            border: 2px solid #80e081; 
        }
    }
`
const MultyInput = styled(Input)`
    max-width: ${ props => props.large ? '250px' : '57px'};
`
const IconWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
    background: #eff3f4;
    border-radius: 30px;
    z-index: 1;
    cursor: pointer;
    i {
        color: #297f76;
    }
`

export default function DatePickerModule({
    multyDate, setSelectedDateStart, setSelectedDateEnd,  saveWithoutFormat,
    disabled, selected, ignorBrod, defEndDate, large, isNeedWithoutFormated,isDefault, cashFunc,
    endDateMultiple, isNextDay
}) {

    const dayJump = useSelector(item => item.defaults?.defaults?.filter(elem => elem.Int_ID === 338)[0])?.Num_Value || isNextDay ? 1 : 0

    const defDate = new Date(Number(new Date()));
    let editedDate = defDate.setDate(defDate.getDate() + (dayJump));
    let editedEndDate = defDate.setDate(defDate.getDate() - (defEndDate? defEndDate : 0));

    const endDateMultipleFunc = () => {
        const date = new Date();
        const endDate = date.setDate(date.getDate() + endDateMultiple);
        return endDate
    }
    const defaultDate = () => {
        if (selected) {
            if(multyDate) {
                return {
                    startDate: selected[0] ? new Date(selected[0]) : new Date() ,
                    endDate: selected[1] ? new Date(selected[1]): new Date() 
                }
            } else {
                return selected
            }
        } 
        return defEndDate? editedEndDate : ignorBrod ? new Date() : editedDate
    }
    const defStartDate = multyDate && selected ? defaultDate().startDate : defaultDate();
    const defaultEndDate = multyDate && selected ? defaultDate().endDate :endDateMultiple ? endDateMultipleFunc() : new Date();
    const [startDate, setStartDate] = useState(defStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);

    const onChangeMulty = dates => {
        const [start, end] = dates;
        // console.log(dates)
        setStartDate(start);
        setEndDate(end);
        cashFunc(dates)
      };
      
    const onChangeSingle = date => {
        setStartDate(date) 
        cashFunc(date)
    }
    
    
    // console.log(startDate)
    // console.log(selected)
    const formedDateStart = format(startDate, "yyyyMMdd");
    const formedDateEnd = endDate ? format(endDate, "yyyyMMdd") : formedDateStart;
    useEffect(() => {
        if(multyDate) {
            setSelectedDateStart(formedDateStart);
            setSelectedDateEnd(formedDateEnd)
        }
    }, [endDate])

    useEffect(() => {
        if(!multyDate) {
            if(isNeedWithoutFormated){
                saveWithoutFormat(startDate)
            }
            setSelectedDateStart(formedDateStart)
        }
    }, [startDate])
 
    return(
       
            <DatePicker
                tabIndex={-1}
                // large={large}
                selected={startDate}
                onChange={multyDate ? onChangeMulty : onChangeSingle}
                startDate={startDate}
                endDate={multyDate ? endDate : ''}
                shouldCloseOnSelect
                selectsRange={multyDate} 
                dateFormat="dd/MM/yyyy"
                disabled={disabled}
                double={multyDate}
                locale={he}
                customInput={
                    <Wrapper
                        large={large}
                    >
                        {
                            !large ?
                                <IconWrapper>
                                    <CalendatDay />
                                </IconWrapper>
                                : null
                        }
                        {
                            !multyDate ?
                            <Input
                                large={large}
                                value={format(startDate, "dd/MM/yyyy")}
                                readOnly
                            /> :
                            <MultyInput 
                                large={large}
                                value={`${format(startDate, "dd/MM/yyyy")} - ${endDate ? format(endDate, "dd/MM/yyyy") : ""}`}
                                readOnly

                            />

                        }
                    </Wrapper>
                }
                // closeOnScroll
                
        
            />
    );
}

DatePickerModule.defaultProps = {
    isNeedWithoutFormated: false,
    saveWithoutFormat: () => console.log(),
    multyDate: false,
    ignorBrod: false,
    large: false,
    setSelectedDateEnd: () => console.log(),
    isDefault: false,
    cashFunc: () => console.log(),
    endDateMultiple: 0,
    isNextDay: false

}