import React from 'react'
import useDataFetcher from '../../main/second/mainbar/knisot/hooks/useFetchData'
import { ITeudotTypeItem } from '../../modules/API/Defaults/models/teudotTypes'
import { Defaults } from '../../modules/API/Defaults/defaults'
import { IWarhouseListItem } from '../../modules/API/Defaults/models/warhouseList'


const useListsService = () => {
    const {
        data: dockTypeData,
    } = useDataFetcher<ITeudotTypeItem[]>(
        Defaults.getTblTeudotTypes, 'כ'
    )

    const {
        data: warehouseData
    } = useDataFetcher<IWarhouseListItem[]>(
        Defaults.getWarhouseList, "data"
    )
    console.log(warehouseData)
    const getDesctiptionFromTeuda = (id: number) => {
        if(!dockTypeData) return ''
        const dockType = dockTypeData.find(item => item.SerialID === id)
        return dockType?.TeudaDesc || '' 
    }

    const getDesctiptionFromWarehouse = (id: number) => {
        if(!warehouseData) return ''
        const warehouse = warehouseData.find(item => item.WarehouseID === id)
        return warehouse?.WarehouseDesc?.trim() || ''
    }
  return (
    {
        getDesctiptionFromTeuda,
        getDesctiptionFromWarehouse
    }
  )
}

export default useListsService