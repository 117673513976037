import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/reducers/rootReducer'
import { IRights } from '../../modules/API/Defaults/models/rights'

type Props = {
  key: string
}

const useCheckRights = ({key}: Props) => {
  const rightsList: IRights[] = useSelector((state: RootState) => state.defaults.rights)

  const found = useMemo(() => {
    return rightsList.find((item: IRights) => item.Righrs_Key === key)
  }, [rightsList, key])
  return (
    {
      hasRight: !!found,
      desc: found ? found.Rights_Name : '',
    }
  )
}

export default useCheckRights