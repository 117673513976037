import { useSelector } from "react-redux";
import { Defaults } from "../../../../../modules/API/Defaults/defaults";
import { IGrowerNodeGetResponse } from "../../../../../modules/API/Defaults/models/growers";
import { IProductListNodeResponse } from "../../../../../modules/API/Defaults/models/productListNode";
import useDataFetcher from "../../knisot/hooks/useFetchData";
import { RootState } from "../../../../../../store/reducers/rootReducer";
import { IUser } from "../../../../../modules/API/Auth/models/authDataModel";
import { useMemo, useState } from "react";

const useGetKnisaLinesService = () => {
  const user = useSelector((state: RootState) => state.mainReducer.user)
  const {code_internal, rights} = user as IUser
  const isGrower = rights.includes('GROWER')
  const [grower, setGrower] = useState<number>(isGrower ? +code_internal : 0)
  const {
    data: products,
  } = useDataFetcher<IProductListNodeResponse>(
    Defaults.getProductListNode, 'data'
  )
  const {
    data: growers
  } = useDataFetcher<IGrowerNodeGetResponse>(
    Defaults.getGrowerList, 'data'
  )
  
  const formedGrowers = useMemo(() => {
    if (!isGrower) return growers?.growers || []
    return growers?.growers?.filter(g => g.GrowerID === +code_internal) || []
  }, [growers, isGrower, code_internal])
  return (
    {
      products: products?.products || [],
      growers: formedGrowers || [],
      grower: grower,
      setGrower, 
      isGrower: isGrower
    }
  )
}

export default useGetKnisaLinesService;