export const reportTypes = [
  // {id: 0, desc: 'הכל'},
  {id: 1, desc: 'דוח מסכם ברמת נהג'},
  {id: 2, desc: 'דוח מפורט ברמת נהג/קו/תאריך'},
  {id: 3, desc: "דוח מפורט ברמת קו/נהג/תאריך"},
  {id: 4, desc: "דוח מסכם ברמת קבלן/נהג"},
  {id: 5, desc: "דוח מפורט ברמת קבלן/נהג/קו/תאריך"},
  {id: 6, desc: "דוח מסכם ברמת קבלן/תאריך/קווים"},
  {id: 7, desc: "דוח מפורט ברמת קבלן/תאריך/קו"},
]
