// Definition: Likut Translation Thai

const likutResons = {
  "likut_reasons_0": "",
  "likut_reasons_1": "ไม่ตรงน้ำหนัก",
  "likut_reasons_2": "การยืนยันของผู้จัดการ",
  "likut_reasons_3": "ขาดสินค้า",
  "likut_reasons_4": "แยกพาเลท",
  "likut_reasons_5": "สินค้าทดแทน",
  "likut_reasons_6": "ชุด"
}

const likutProblebsVarietions = {
  "likut_problem_tag_notExistInAzmana": "สินค้าไม่มีในคำสั่งซื้อ",
  "likut_problem_tag_moreThenNeed": "จำนวนสูงกว่าคำสั่งซื้อ",
  "likut_problem_tag_lessThenNeed": "จำนวนต่ำกว่าคำสั่งซื้อ",
  "likut_problem_variations_1": "จำนวนสินค้า",
  "likut_problem_variations_2": "การยืนยันของผู้จัดการ",
  "likut_problem_variations_3": "ขาดสินค้า",
  "likut_problem_variations_4": "แยกพาเลท",
  "likut_problem_variations_5": "สินค้าทดแทน",
  "likut_problem_variations_6": "ชุด"
}

export const likutTranslationTH = {
  likut: {
    "likut_orders_search_placeholder": 'ค้นหาคำสั่งซื้อ',
    "likut_pallets_title": "พาเลท",
    "likut_pallets_button_save": "เสร็จสิ้นการรวบรวม",
    "likut_pallets_button_new": "พาเลทใหม่",
    "likut_modal_sium_likut_title": "สรุปคำสั่งซื้อ",
    "likut_modal_printers_printer": "เลือกเครื่องพิมพ์",
    "likut_modal_printers_format": "รูปแบบการพิมพ์",
    "likut_sectionmain_title_new": "พาเลทใหม่",
    "likut_sectionmain_title_withpallet": "พาเลท: {{pallet}}",
    "likut_modal_remarks_title": "หมายเหตุ",
    "likut_sectionmain_inpallet_title": "ในพาเลท",
    "likut_sectionmain_product_swiperight_text": 'ปัญหา?',
    "likut_sectionmain_product_swiperight_button": 'สินค้าหมด',
    "likut_sectionmain_product_swipeleft_text": 'ไม่ต้องสแกน',
    "likut_sectionmain_product_rest_packages_text": ':สินค้าที่เหลือ',
    "likut_sectionmain_product_rest_text": ':สินค้าที่เหลือ',
    "likut_sectionmain_product_problem": ':ปัญหา',
    "likut_sectionmain_pallet_product_qty": 'จำนวน',
    "likut_sectionmain_pallet_product_weight": 'น้ำหนัก',
    "likut_modal_checkpallet_title": "ตรวจสอบพาเลท",
    "likut_modal_checkpallet_weight": "ข้อมูลบนเครื่องชั่ง",
    "likut_modal_addpackage_title": "เพิ่มบรรจุภัณฑ์",
    "likut_modal_addpackage_selector_title": "- เลือกบรรจุภัณฑ์ -",
    "likut_sectionmain_multiplesave_button": "บันทึกรายการที่เลือก",
    ...likutResons,
    ...likutProblebsVarietions
  }
}