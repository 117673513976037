import React from 'react'
import { IEntrieDetails } from '../../../../../../modules/API/Knisot/models/entrie'
import TitleKnisa from '../../Components/TitleKnisa'
import styled from 'styled-components'
import CircleActiveFilterBtn from '../../../../../../generic/particles/CircleActiveFilterBtn'
import { SettingMiksher } from '../../../../../../generic/icons/Icons'

type Props = {
  knisaTitleData: IEntrieDetails | null
  handleControlTitle: (isSave?: boolean) => void
}

const TitleCreateKnisa = ({
  knisaTitleData, handleControlTitle
}: Props) => {
  
  return (
    <MainWrapper>
      
          <SideButton>
            <CircleActiveFilterBtn
                func={() => handleControlTitle(!false)}
            >
                <SettingMiksher />
            </CircleActiveFilterBtn>
          </SideButton>
      
      {
        knisaTitleData &&
        <TitleKnisa 
          data={knisaTitleData}
        />
      }
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  position: relative;
  min-height: 8em;
  width: 100%;
  background: #fff;
  border-radius: 20px;
`
const SideButton = styled.div`
  position: absolute;
  top: calc(50% - 1.5em);
  left: 1em;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 2;
  &:hover {
    background: #E5E5E5;
  }

`
export default TitleCreateKnisa