
const likutResons = {
  "likut_reasons_0": "",
  "likut_reasons_1": "התאמת משקל",
  "likut_reasons_2": "אישור מנהל",
  "likut_reasons_3": "מחסור במלאי",
  "likut_reasons_4": "פיצול בין משטחים",
  "likut_reasons_5": "החלפת מוצר",
  "likut_reasons_6": "משטח קומפלט"
}

const likutProblebsVarietions = {
  "likut_problem_tag_notExistInAzmana": "מוצר לא קיים בהזמנה",
  "likut_problem_tag_moreThenNeed": "כמות גבוהה מההזמנה",
  "likut_problem_tag_lessThenNeed": "כמות נמוכה מההזמנה",
  "likut_problem_variations_1": "התאמת משקל",
  "likut_problem_variations_2": "אישור מנהל",
  "likut_problem_variations_3": "מחסור במלאי",
  "likut_problem_variations_4": "פיצול",
  "likut_problem_variations_5": "החלפת מוצר",
  "likut_problem_variations_6": "משטח קומפלט"
}

export const likutTranslationHE = {
  likut: {
    "likut_orders_search_placeholder": 'חיפוש הזמנה',
    "likut_pallets_title": "משטחים",
    "likut_pallets_button_save": "סיום ליקוט", 
    "likut_pallets_button_new": "משטח חדש", 
    "likut_modal_sium_likut_title": "סיכום הזמנה",
    "likut_modal_printers_printer": "מדפסות",
    "likut_modal_printers_format": "פורמט הדפסה",
    "likut_sectionmain_title_new": "משטח חדש",
    "likut_sectionmain_title_withpallet": "משטח: {{pallet}}",
    "likut_modal_remarks_title": "הערת ליקוט",
    "likut_sectionmain_inpallet_title": "במשטח",
    "likut_sectionmain_product_swiperight_text": 'יש בעיה?',
    "likut_sectionmain_product_swiperight_button": 'אין במלאי',
    "likut_sectionmain_product_swipeleft_text": 'ללא סריקה',
    "likut_sectionmain_product_rest_packages_text": ':יתרה אריזות',
    "likut_sectionmain_product_rest_text": ':יתרה',
    "likut_sectionmain_product_problem": ':גורם',
    "likut_sectionmain_pallet_product_qty": 'כמות',
    "likut_sectionmain_pallet_product_weight": 'משקל',
    "likut_modal_checkpallet_title": "בקרת משטח",
    "likut_modal_checkpallet_weight": "נתונים על סולמות",
    "likut_modal_addpackage_title": "הוספת אריזה ריקה",
    "likut_modal_addpackage_selector_title": "- בחר אריזה -",
    "likut_sectionmain_multiplesave_button": "שמור פריטים שנבחרו",
    ...likutResons,
    ...likutProblebsVarietions

    

    
  }
}