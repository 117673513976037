import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { LOGOUT } from '../../../../../../store/actions/actionsTypes';
import DatePickerModule from '../../../../../generic/datePicker/datePicker';
import { CloseIcon, SettingMiksher } from '../../../../../generic/icons/Icons';
import { GET_DELIVERYCODES, GET_PRODUCT_TYPES, LOAD_ORDERS, WORKER_GROUP } from '../../../../../modules/API/settings';
import { useInterval } from '../../../../../modules/autoCarusel';
import { ContextModal } from '../../../../../modules/modal/modalContext';
import { SelectPanelWrapper } from '../../secondbar/azmanotmain/SelectBar';
import { Decorator } from '../../secondbar/mainbar2';
import LikutMonitorFilter from '../likutMonitorFilter/LikutMonitorFilter';
import { IconWrapperOrders } from '../likutWorkers/LikutSetWorkers';
import ReadyForDeliveryItem from './ReadyForDeliveryItem';
import WorkStatusItem from './WorkStatusItem';
import useDataFetcher from '../../knisot/hooks/useFetchData';
import { Defaults } from '../../../../../modules/API/Defaults/defaults';

const MainWorkStatusWrapper = styled.div`
  position: relative;
  background: #fff;
  max-width: 1300px;
  height: 100%;
  margin: 0 auto;
  border-radius: 30px;
  padding: 0.5em;
  overflow: hidden;
  
`

const WorkStatusContentWrapper = styled.div`

  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;

`

const WorkStatusContentBody = styled.div`
  /* background: rgba(255,255,255, 0.5); */
  position: relative;
  height: 100%;
  width: 100%;
  /* z-index: 1; */
  border-radius: 30px;
  display: flex;
  padding: 0.5em 0.5em 0;
  gap: 0.5em;
  overflow-y: hidden;
  /* rgb(239,243,244) */
`
const GroupContentWrapper = styled.div`
  height: 100%;
  width: 75%;
  background: rgba(239,243,244, 0.6);
  border-radius: 30px;
  display: flex;
  padding: 0.5em 1em;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  /* gap: 0.5em; */
  overflow-y: auto;
  align-content: flex-start;
  gap: 1em;
  /* background: red; */
  ::-webkit-scrollbar {
      width: 0;
  }

`
const ListContentWrapper = styled.div`
  height: 100%;
  width: 25%;
  background: rgba(239,243,244, 0.6);
  border-radius: 30px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  padding: 0.5em;
  overflow-y: auto;
  scroll-behavior: smooth;
  
  ::-webkit-scrollbar {
      width: 0;
  }
`

const FilterField = styled.div`
  position: absolute;
  width: 400px;
  height: 100%;
  right: 0.5em;
  padding: 0.5em;

`


export default function WorkStatusesMain() {
  const dispatch = useDispatch();
  const token = useSelector(token => token.mainReducer.token)
  const [modal, setModal] = useContext(ContextModal)

  const [startDate, setStartDate] = useState(new Date());
  const [azmanotDefault, setAzmanotDefault] = useState(null);
  const [azmanot, setAzmanot] = useState(null);
  const [azmanotGeneralList, setAzmanotGeneralList] = useState([])

  const [isOpen, setIsOpen] = useState(false);
  const [workerGroupList, setWorkerGroupList] = useState([]);
  const [workerKwucaID, setWorkerKwucaID] = useState(null);

  const [deliveryCodeList, setDeliveryCodeList] = useState([]);
  const [deliveryID, setDeliveryID] = useState(null);

  const [prodTypes, setProdTypes] = useState([]);
  const [prodID, setProdID] = useState(null);
  const [transportationID, setTransportationID] = useState(0);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [scrollFlag, setScrollFlag] = useState(false);

  const scrollRef = useRef();

  const {
    data: transportationList
  } = useDataFetcher(
    Defaults.getTransportAreas, "data"
  )
  const scroller = () => {
    // console.log(azmanot[4].length)
    if(!azmanot || !azmanot[4]) return
    if((scrollIndex <= azmanot[4].length - 1) && !scrollFlag) {
      scrollRef?.current?.scrollIntoView({
        behavior: 'smooth'
      })
      setScrollIndex(scrollIndex + 1)
      if(scrollIndex === azmanot[4].length - 1) {
        setScrollFlag(true)
      }
    } 
    else {
      setScrollIndex(0)
      scrollRef?.current?.scrollIntoView({
        behavior: 'smooth'
      })
      setTimeout(() => {
        setScrollFlag(false)
      }, 4000)
      
    }
  }
  useInterval(scroller, 1000)
  
  const handleFilterAzmanot = (azmanot) => {
    let value = azmanot ? azmanot : azmanotDefault

    const filtered = value.filter(item =>
        (deliveryID ? item.Delivery_Code === +deliveryID : true) &&
        (workerKwucaID ? item.PickupGroup === +workerKwucaID : true) &&
        (prodID ? item.GroupID === +prodID : true) &&
        (transportationID ? item.TransferZone === +transportationID : true)
        
    )
    // set default value of whole filtered azmanot for percent calculation in child component
    setAzmanotGeneralList(filtered);

    let reduced = filtered.reduce((acc, cur) => {
      acc[cur.Status] = acc[cur.Status]?.concat(cur) || [].concat(cur)
      return acc
    }, {})
    setAzmanot(reduced);
    setIsOpen(false);
    // return reduced
}
  const getWorkerGroup = async () => {
    try {
      let res = await axios.get(WORKER_GROUP, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      setWorkerGroupList(res.data.workersGroups);


    } catch (err) {
      console.log(err.response)
    }
  }
  const getProdTypes = async () => {
    try {
        let response = await axios.get(`${GET_PRODUCT_TYPES}`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        setProdTypes(response.data.productsGroups)
        
    } catch (err) {
        console.log(err.response)

    }

}
  const getDeliveryCodes = async () => {
    try {

      let res = await axios.get(GET_DELIVERYCODES, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      setDeliveryCodeList(res?.data?.deliveryCodes)

      // setDeliveryCodes(res?.data?.deliveryCodes)
    } catch (err) {
      console.log(err)
    }
  }

  const getAzmanotList = async () => {

    try {
      let res = await axios.get(LOAD_ORDERS + `fromDate=${startDate}&toDate=${startDate}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })

      handleFilterAzmanot(res.data.orders)
     
      setAzmanotDefault(res.data.orders);

    } catch (err) {
      setModal({
          open: true,
          value: err?.response?.data?.message,
          mood: false
      });
      setTimeout( () =>{ 
          setModal({
              open: false,
              value: '2'
          });
      }, 3000);
      if(err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
          dispatch({type: LOGOUT, payload: false});
      }
    }
  }

  useEffect(() => {
    if (startDate.toString().length > 8) return
    getAzmanotList();
    getWorkerGroup();
    getDeliveryCodes();
    getProdTypes();

  }, [startDate])

  useInterval(getAzmanotList, 30000)

  return (
    <MainWorkStatusWrapper>
      <Decorator />
      <WorkStatusContentWrapper>
        <SelectPanelWrapper>
          <DatePickerModule
            setSelectedDateStart={setStartDate}
          />
          
          <FilterField>
            <IconWrapperOrders style={{left: '87%'}} onClick={() => {setIsOpen(!isOpen)}}>
              {!isOpen ? <SettingMiksher /> : <CloseIcon />}
            </IconWrapperOrders>
            <LikutMonitorFilter
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              workerGroupList={workerGroupList}
              setWorkerKwucaID={setWorkerKwucaID}
              workerKwucaID={workerKwucaID}

              deliveryCodeList={deliveryCodeList}
              setDeliveryID={setDeliveryID}
              deliveryID={deliveryID}

              setProdID={setProdID}
              prodID={prodID}
              prodTypes={prodTypes}
              
              filterFunc={handleFilterAzmanot}
              transportationList={transportationList?.transportAreas || []}
              setTransportationID={setTransportationID}
              transportationID={transportationID}
            />
          </FilterField>
          
        </SelectPanelWrapper>
        <WorkStatusContentBody>
          <GroupContentWrapper>
            {
              azmanot ?
                Object.keys(azmanot)?.map(item =>
                  <WorkStatusItem
                    key={item}
                    data={azmanot[item]}
                    status={+item}
                    azmanotGeneralList={azmanotGeneralList}

                  />
                ) : ''
            }
          </GroupContentWrapper>
          <ListContentWrapper >
              {
                azmanot ? 
                 azmanot[4]?.map((item, index) =>
                    <ReadyForDeliveryItem key={item.OrderID} {...item} index={index} scrollIndex={scrollIndex} curRef={scrollRef} />
                  )
                  : ''
              }
          </ListContentWrapper>
        </WorkStatusContentBody>

      </WorkStatusContentWrapper>
    </MainWorkStatusWrapper>
  )
}
