import axios from 'axios'
import React, { useContext, useEffect, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components';
import { LOAD, LOAD_END, LOGOUT, SELECT_CLIENT } from '../../../../../../store/actions/actionsTypes';
import ControlButton from '../../../../../generic/buttons/ControlButton';
import SearchFromTheList from '../../../../../generic/customSearchComponents/SearchFromTheList';
import Title from '../../../../../generic/Title/Title';
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList';
import { checkPackType } from '../../../../../generic/utils/checkPackType';
import { DELETE_FROM_PALLET, GET_PALLET_EXIST } from '../../../../../modules/API/settings'
import { ContextModal } from '../../../../../modules/modal/modalContext';
import { LINKS } from '../../../../../Routs/config';
import { ContentWrapper, Decorator, MainWrapper } from '../../secondbar/mainbar2';
import useDataFetcher from '../../knisot/hooks/useFetchData';
import { Defaults } from '../../../../../modules/API/Defaults/defaults';
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList';
import { Market } from '../../../../../modules/API/Market/market';
import { Product } from '../../../../../modules/API/Products/products';
import selectBrodMigdal from '../../../../../generic/utils/selectBrodMigdal';
import AddProductModal from '../../market/Collect/Components/AddProductModal';
import ColumModal from '../../../../../generic/Layouts/ColumModal';
import { Pallets } from '../../../../../modules/API/Pallets/pallets';
import PalletDetailesItem from './PalletDetailesItem';
import ProductModal from '../../market/Collect/Components/ProductModal';

const ElementWrapper = styled.div`
    width: 100%;
    background: #fff;
    border-radius: 30px;
    padding: 0.4em 0.5em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    -webkit-box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
`

const InnerElementWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 0.5em;
`

const ControlButtosWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 0.5em;
`

export default function AddPalletDetailes({
    clientList, clientListDefault, setClientList,
    growersList, getPalletCurrent
}) {
    const { id } = useParams();
    const defaults = useSelector((state) => state.defaults.defaults)
    const defaultPackage = selectBrodMigdal(defaults, 382, 'Num_Value');
    const sellerID = useSelector((state) => state.mainReducer.user?.code_internal)
    const history = useHistory()
    const token = useSelector(e => e.mainReducer.token);
    const packagesList = useSelector(e => e.defaults?.packageList);
    const controlPalletsReduxState = useSelector(e => e.controlPallets);
    console.log(controlPalletsReduxState)
    const defaultArizaTranzit = useSelector(item => item.defaults.defaults.filter(e => e.Int_ID === 5)[0]?.Num_Value);
    const defaultPalletType = useSelector(item => item.defaults.defaults === null ? '' : item.defaults.defaults.filter(e => {
        return e.Int_ID === 14 && e?.Sub_ID?.trim() === '1'
    })[0]?.Num_Value)
    const dispatch = useDispatch();
    const [modal, setModal] = useContext(ContextModal);
    const [palletRows, setPalletRows] = useState([]);
    // client select component states
    const clientInputDefault = controlPalletsReduxState?.client?.name?.trim() || ''
    const clientIDDefault = controlPalletsReduxState?.client?.id || null
    const customerIDRef = useRef(clientIDDefault)
    const [clientInput, setClientInput] = useState(clientInputDefault);
    const [clientID, setClientID] = useState(clientIDDefault);
    const [isShowClientList, setIsShowClientList] = useState(false);
    const [isOpenAddProduct, setIsOpenAddProduct] = useState(false)
    const [sizeData, setSizeData] = useState([])
    console.log('123')
    const getExistPalletInfo = async (id) => {
        dispatch({ type: LOAD })
        try {
            let res = await axios.get(`${GET_PALLET_EXIST}${id}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            setPalletRows(res.data.detailsPallet);

        }
        catch (err) {
            console.log(err)
        } finally {
            dispatch({ type: LOAD_END })
        }
    }
    const deleteItemFromPallet = async (id) => {
        dispatch({ type: LOAD })
        try {
            let res = await axios({
                method: 'delete',
                headers: {

                    'Authorization': 'Bearer ' + token

                },
                url: DELETE_FROM_PALLET + id
            })
            const filtered = palletRows.filter(item => item.RecID !== id);
            setPalletRows(filtered);
            setModal({
                open: true,
                value: res?.data?.message,
                mood: true
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);
        } catch (err) {
            setModal({
                open: true,
                value: err?.response?.data?.message,
                mood: false
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            }, 3000);
            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            }
        } finally {
            dispatch({ type: LOAD_END })
        }
    }

    useEffect(() => {
        if (id !== 'new') {
            getExistPalletInfo(id)
        }
    }, [id])

    const clientFilter = (input) => {
        const filtered = clientListDefault.filter(e => e.Customer_Name.trim().toLowerCase().includes(input.toLowerCase()));
        setClientInput(input);
        setClientList(filtered);
        setIsShowClientList(filtered.length > 0 && input ? true : false);
        if (!input.trim()) {
            setClientID(null)

        }
    }

    const selectClient = (id, name) => {
        setClientID(id);
        setClientInput(name);
        setIsShowClientList(false);
        dispatch({ type: SELECT_CLIENT, name: name, id: id })

    }

    const putCustomerToPallet = async () => {
        if (customerIDRef?.current) return handleClickLeavePage()
        if (!clientIDDefault?.current && !clientID) return handleClickLeavePage()
        dispatch({ type: LOAD })
        const data = {
            CustomerID: clientID || 0,
        }
        try {
            Pallets.putCustomerToPallet(id, data)
            setModal({
                open: true,
                value: "לקוח עודכן בהצלחה",
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            handleClickLeavePage()
        } catch (error) {
            setModal({
                open: true,
                value: "לקוח לא עודכן",
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } finally {
            dispatch({ type: LOAD_END })
        }
    }

    const handleClickLeavePage = () => {
        getPalletCurrent();
        history.push(LINKS.mishtahim.control.mainURL)
    }

    const handleOpenAddProduct = () => {
        setIsOpenAddProduct(!isOpenAddProduct)
    }

    const {
        data: dataProductsWithVariety,
    } = useDataFetcher(
        Defaults.getProductsWithVariety, "data"
    )

    const dataProductsTypesWithID = useMemo(() => {
        if (!dataProductsWithVariety) return []

        return addIDForTheList(dataProductsWithVariety)
    }, [dataProductsWithVariety])

    const {
        data: dataPallets
    } = useDataFetcher(
        Pallets.getPalletList, "data"
    )

    const createProduct = async ({
        selectedProduct,
        GrowerID,
        PalletNum,
        Qty,
        Weight,
        PalletType,
        CustomerID,
        PackID,
        Price,
        SizeID
    }) => {
        dispatch({ type: LOAD })
        try {
            if (!selectedProduct) return

            const packType = checkPackType(packagesList, PackID)
            let reqData = {
                QualityID: 0,
                WarehouseID: defaultArizaTranzit || 0,
                GrowerID: GrowerID,
                ProductID: selectedProduct?.ProductID,
                VarietyID: selectedProduct.VarietyID,
                PalletNum: PalletNum,
                OrderNum: 0,
                Qty: packType === '1' ? Weight : Qty,
                Weight: packType === '2' ? Qty : Weight,
                PalletType: PalletType || defaultPalletType || 0,
                OrderRowRecID: 0,
                CustomerID: CustomerID || 0,
                ScanProblem: 0,
                ChangeReason: 0,
                PackID: PackID,
                Reseller_Code: sellerID ? +sellerID : 0,
                SalePrice: Price,
                SizeID: SizeID || ''
            }
            let res = await Market.saveProduct(reqData)
            handleOpenAddProduct()
            setPalletRows(res.lines18)
            history.push(`${LINKS.mishtahim.control.create}${res.palletNum}`);
        } catch (error) {

        } finally {
            dispatch({ type: LOAD_END })
        }
    }

    const selectedPallet = useMemo(() => {
        return {
            pallet: id !== 'new' ? +id : 0,
            client: clientID,
            name: ''
        }
    }, [id, clientID])

    const getSizes = async (productID) => {
        try {
            let res = await Product.getSizesByProduct(productID);
            console.log(res)
            setSizeData(res)
        } catch (err) {}
    }
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [isOpenEditProduct, setIsOpenEditProduct] = useState(false)

    const handleOpenProductEdit = () => {
        setIsOpenEditProduct(!isOpenEditProduct)
    }

    const handleSelectProduct = (product) => {
        setSelectedProduct(product)
        handleOpenProductEdit()
    }

    const patchProduct = (data) => {
        if(!selectedProduct) return
        const tempArr = [...palletRows]
        const result = tempArr.map(item => {
            let result = item
            if(item.RecID === selectedProduct.RecID) {
                result = {
                    ...item,
                    ...data
                }
            }
            return result
        })
        setPalletRows(result)
    }

    const updateProduct = async (data) => {
        dispatch({ type: LOAD })
        try {
            let res = await Market.updateProduct(selectedProduct.RecID, data)
            patchProduct(res)
            
            handleOpenProductEdit()
        } catch (err) {
            console.log(err)
        } finally {
            dispatch({ type: LOAD_END })
        }
    }
    const handleDelete = async (id) => {
        // dispatch({ type: LOAD })
        try {
            await Pallets.deleteProductFromPallet(id)
            const filtered = palletRows.filter(item => item.RecID !== id);
            setPalletRows(filtered);
            setModal({
                open: true,
                value: 'פריט נמחק בהצלחה',
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } catch (err) {
            console.log(err.response)
            setModal({
                open: true,
                value: err?.response?.data?.message,
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } finally {
            // dispatch({ type: LOAD_END })
        }
    }

    const addProductModalProps = {
        data: selectedPallet,
        dataGrowers: { growers: growersList },
        dataPallets: dataPallets,
        packageList: packagesList,
        sellerID: sellerID,
        defaultPallet: defaultPalletType,
        defaultPackage: defaultPackage,
        createProduct: createProduct,
        cashProduct: () => { },
        dataProductsWithVariety: dataProductsTypesWithID,
        getSizes: getSizes,
        sizeData: sizeData
    }

    const productEditProps = {
        data: selectedProduct,
        updateProduct: updateProduct,
        packageList: packagesList,
        growers: growersList,
        pallets: dataPallets?.pallets,
    }

    return (
        <MainWrapper>
            <Decorator />
            <Title label={id === 'new' ? 'משטח חדש' : `${id} :משטח`} />
            {
                isOpenAddProduct &&
                <ColumModal
                    title={id === 'new' ? 'משטח חדש' : `${id} :משטח`}
                    Children={AddProductModal}
                    controlFunc={handleOpenAddProduct}
                    childrenProps={addProductModalProps}
                />
            }
            {
                isOpenEditProduct &&
                <ColumModal
                    title={'עדכון פריט'}
                    Children={ProductModal}
                    controlFunc={handleOpenProductEdit}
                    childrenProps={productEditProps}
                />
            }
            <ElementWrapper>
                <InnerElementWrapper>
                    <SearchFromTheList
                        input={clientInput}
                        searchFunc={clientFilter}
                        placeHolder={'בחור לקוח'}
                        fieldClietns={isShowClientList}
                        list={clientList}
                        itemDisplayName={'Customer_Name'}
                        nameIdElement={'CustomerID'}
                        selectFunc={selectClient}
                        isOpenList={isShowClientList}
                        size={'full'}
                        disabled={!!clientIDDefault}
                    />
                </InnerElementWrapper>
            </ElementWrapper>
            <ContentWrapper>
                <TitlesList
                    list={palletRows}
                    uniqueKey={'RecID'}
                    Element={PalletDetailesItem}
                    additionalData={{
                        withoutOpacity: true,
                        likutWithoutAzmana: true,
                        deleteFromPallet: deleteItemFromPallet,
                        handleSelectProduct: handleSelectProduct,
                        handleDelete: handleDelete
                        
                    }}
                />
            </ContentWrapper>
            <ControlButtosWrapper>
                <ControlButton
                    handleClick={putCustomerToPallet}
                    lable='סיים'
                />
                {
                    <ControlButton
                        handleClick={handleOpenAddProduct}
                        lable='מוצר חדש'
                    />
                }
            </ControlButtosWrapper>
        </MainWrapper>
    )
}
