const likutResons = {
  "likut_reasons_0": "",
  "likut_reasons_1": "عدم تطابق الوزن",
  "likut_reasons_2": "تأكيد مدير",
  "likut_reasons_3": "نفاذ المخزون",
  "likut_reasons_4": "تقسيم المنصات",
  "likut_reasons_5": "استبدال المنتج",
  "likut_reasons_6": "كامل"
}

const likutProblebsVarietions = {
  "likut_problem_tag_notExistInAzmana": "المنتج غير موجود في الطلب",
  "likut_problem_tag_moreThenNeed": "الكمية أعلى من الطلب",
  "likut_problem_tag_lessThenNeed": "الكمية أقل من الطلب",
  "likut_problem_variations_1": "تطابق الوزن",
  "likut_problem_variations_2": "تأكيد مدير",
  "likut_problem_variations_3": "نفاذ المخزون",
  "likut_problem_variations_4": "تقسيم المنصات",
  "likut_problem_variations_5": "استبدال المنتج",
  "likut_problem_variations_6": "كامل"
}

export const likutTranslationAR = {
  likut: {
    "likut_orders_search_placeholder": "بحث الطلب",
    "likut_pallets_title": "المنصات",
    "likut_pallets_button_save": "إنهاء ",
    "likut_pallets_button_new": "منصة جديدة",
    "likut_modal_sium_likut_title": "ملخص الطلب",
    "likut_modal_printers_printer": "حدد الطابعة",
    "likut_modal_printers_format": "تنسيق الطباعة",
    "likut_sectionmain_title_new": "منصة جديدة",
    "likut_sectionmain_title_withpallet": "منصة: {{pallet}}",
    "likut_modal_remarks_title": "ملاحظة",
    "likut_sectionmain_inpallet_title": "في المنصة",
    "likut_sectionmain_product_swiperight_text": 'هل هناك مشكلة؟',
    "likut_sectionmain_product_swiperight_button": 'نفذ من المخزون',
    "likut_sectionmain_product_swipeleft_text": 'بدون مسح',
    "likut_sectionmain_product_rest_packages_text": ':البقية من الحزم',
    "likut_sectionmain_product_rest_text": ':البقية',
    "likut_sectionmain_product_problem": ':مشكلة',
    "likut_sectionmain_pallet_product_qty": 'الكمية', 
    "likut_sectionmain_pallet_product_weight": 'الوزن',
    "likut_modal_checkpallet_title": "فحص المنصة",
    "likut_modal_checkpallet_weight": "بيانات الوزن",
    "likut_modal_addpackage_title": "إضافة عبوة فارغة",
    "likut_modal_addpackage_selector_title": "- حدد العبوة -",
    "likut_sectionmain_multiplesave_button": "حفظ العناصر المحددة",

    ...likutResons,
    ...likutProblebsVarietions
  }
  

}