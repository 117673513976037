import React, {useState} from 'react'
import { IGrowerItem } from '../../../../../modules/API/Defaults/models/growers'
import styled, { keyframes } from 'styled-components'
import { LinkIcon, PhoneIcon, UserIcon } from '../../../../../generic/icons/Icons';
import TitleLayout from '../../kosherTeudot/generic/TitleLayout';
import TitleRow from '../../kosherTeudot/generic/TitleRow';
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem';
import { IInvitationPostData } from '../../../../../modules/API/Auth/models/invitation';
import { handleNotifications } from '../../../../../generic/utils/handleNotifications';

interface IProps extends IGrowerItem {
  getInvite: (data: IInvitationPostData) => void
}

const GrowerItem = ({
  GrowerName, 
  getInvite,
  GroupID,
  GrowerID,
  Phone
}: IProps) => {

  const checkPhone = (phone: string) => {
    return phone.length === 10 && phone[0] === '0' ? phone : ''
  }

  const [phone, setPhone] = useState<string>(() => checkPhone(Phone || ''))
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if(!value[0] ) {
      setPhone('')
      return
    }
    if(value[0] !== '0') {
      handleNotifications("המספר טלפון חייב להתחיל ב-0", {type: 'warning'})
      return
    }
    if(e.target.value.length > 10) return
    setPhone(value)
  }

  const handleSendInvite = () => {
    if(phone.length !== 10) {
      handleNotifications("נא להזין מספר טלפון חוקי", {type: 'warning'})
      return
    }
    getInvite({
      CodeInternal: GrowerID,
      Phone: phone,
      Role: 'grower'
    })
  }
  return (
    <TitleLayout>
      <OutlineButton
      >
      <SubscribeButton
        onClick={handleSendInvite}
        // disabled={phone.length !== 10}
      >
              <Arrow
                className="arrow"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="10"
                viewBox="0 0 38 15"
              >
                <path d="M10 7.519l-.939-.344h0l.939.344zm14.386-1.205l-.981-.192.981.192zm1.276 5.509l.537.843.148-.094.107-.139-.792-.611zm4.819-4.304l-.385-.923h0l.385.923zm7.227.707a1 1 0 0 0 0-1.414L31.343.448a1 1 0 0 0-1.414 0 1 1 0 0 0 0 1.414l5.657 5.657-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364zM1 7.519l.554.833.029-.019.094-.061.361-.23 1.277-.77c1.054-.609 2.397-1.32 3.629-1.787.617-.234 1.17-.392 1.623-.455.477-.066.707-.008.788.034.025.013.031.021.039.034a.56.56 0 0 1 .058.235c.029.327-.047.906-.39 1.842l1.878.689c.383-1.044.571-1.949.505-2.705-.072-.815-.45-1.493-1.16-1.865-.627-.329-1.358-.332-1.993-.244-.659.092-1.367.305-2.056.566-1.381.523-2.833 1.297-3.921 1.925l-1.341.808-.385.245-.104.068-.028.018c-.011.007-.011.007.543.84zm8.061-.344c-.198.54-.328 1.038-.36 1.484-.032.441.024.94.325 1.364.319.45.786.64 1.21.697.403.054.824-.001 1.21-.09.775-.179 1.694-.566 2.633-1.014l3.023-1.554c2.115-1.122 4.107-2.168 5.476-2.524.329-.086.573-.117.742-.115s.195.038.161.014c-.15-.105.085-.139-.076.685l1.963.384c.192-.98.152-2.083-.74-2.707-.405-.283-.868-.37-1.28-.376s-.849.069-1.274.179c-1.65.43-3.888 1.621-5.909 2.693l-2.948 1.517c-.92.439-1.673.743-2.221.87-.276.064-.429.065-.492.057-.043-.006.066.003.155.127.07.099.024.131.038-.063.014-.187.078-.49.243-.94l-1.878-.689zm14.343-1.053c-.361 1.844-.474 3.185-.413 4.161.059.95.294 1.72.811 2.215.567.544 1.242.546 1.664.459a2.34 2.34 0 0 0 .502-.167l.15-.076.049-.028.018-.011c.013-.008.013-.008-.524-.852l-.536-.844.019-.012c-.038.018-.064.027-.084.032-.037.008.053-.013.125.056.021.02-.151-.135-.198-.895-.046-.734.034-1.887.38-3.652l-1.963-.384zm2.257 5.701l.791.611.024-.031.08-.101.311-.377 1.093-1.213c.922-.954 2.005-1.894 2.904-2.27l-.771-1.846c-1.31.547-2.637 1.758-3.572 2.725l-1.184 1.314-.341.414-.093.117-.025.032c-.01.013-.01.013.781.624zm5.204-3.381c.989-.413 1.791-.42 2.697-.307.871.108 2.083.385 3.437.385v-2c-1.197 0-2.041-.226-3.19-.369-1.114-.139-2.297-.146-3.715.447l.771 1.846z"></path>
              </Arrow>
              <LinkIcon />
            </SubscribeButton>
      </OutlineButton>
      <TitleRow>
        <TitleRowItem 
          name={GrowerName}
          icon={<UserIcon />}
        />
      </TitleRow>
      <TitleRow>
        <MainWrapper>
          <InputWrapper>
            <Icon>

              <PhoneIcon />
            </Icon>
            <Input
              type="tel"
              name="text"
              className="input"
              placeholder="053#######"
              value={phone}
              onChange={handlePhoneChange}
            />
           
          </InputWrapper>

        </MainWrapper>
      </TitleRow>
    </TitleLayout>

  );
};
const MainWrapper = styled.div`
  z-index: 1;
  direction: rtl;
  /* width: 100%; */
`
// Ключевая анимация "jello-vertical"
const jelloVertical = keyframes`
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
`;

// Стилизованный компонент для иконки
const Icon = styled.div`
  /* width: 30px; */
  transition: all 0.3s;
  color: #297F76;
`;
const OutlineButton = styled.div`
  position: absolute;
  left: 0.5em;
  top: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #297F76;
`
// Стилизованный контейнер для инпута и кнопки
const InputWrapper = styled.div`
  width: fit-content;
  /* height: 45px; */
  border-radius: 20px;
  padding: 5px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  background-color: #fff;
  gap: 0.5em;
  /* При клике на контейнер иконка увеличивается */
  &:active ${Icon} {
    transform: scale(1.3);
  }
`;

// Стилизованный инпут
const Input = styled.input`
  max-width: 170px;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 15px;
  background-color: #fff;
  color: #75759E;
  font-size: 1em;
  direction: rtl;
  ::placeholder {
    opacity: 0.3;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #292524 inset;
    -webkit-text-fill-color: #ffffff;
  }
`;

// Стилизованная кнопка подписки
const SubscribeButton = styled.button`
  height: 3em;
  width: 95px;
  border: none;
  border-radius: 15px;
  /* color: rgb(0, 0, 0); */
  color: #297F76;
  cursor: pointer;
  background-color: #FDFDFD;
  font-weight: 500;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 1.1em;
  transition: all 0.3s;
  :disabled {
    animation: none;
    opacity: 0.5;
  }
  &:hover {
    color: white;
    
    /* При наведении анимируем стрелку */
    & > svg {
      
      margin-right: 0;
      animation: ${jelloVertical} 0.9s both;
      transform-origin: right;
     
    }
  }

  &:active {
    transform: scale(0.9);
  }
`;

// Стилизованная стрелка внутри кнопки
const Arrow = styled.svg`
  position: absolute;
  margin-right: 150px;
  transition: all 0.3s;
`;
export default GrowerItem