import { format } from 'date-fns';
import { he } from 'date-fns/locale';

import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import styled from 'styled-components';
import { CalendatDay } from '../../../../../../generic/icons/Icons';

export interface IDateFormat {
    start: Date,
    end: Date
}
type Props = {
    defaultDate: Date,
    handleDate: (date: Date) => void
}

const DatePickerMonth = ({
    handleDate, defaultDate
}: Props) => {
    const [startDate, setStartDate] = useState(defaultDate);

   
    const handleChange = (date: Date) => {
        setStartDate(date)
        handleDate(date)

    }
   
  return (
    <Wrapper>

    <DatePicker 
        selected={startDate}
        showMonthYearPicker
        dateFormat={"MM/yyyy"}
        onChange={handleChange}
        showFullMonthYearPicker
        calendarClassName='calendar'
        locale={he}
        customInput={
            <Wrapper>
                <IconWrapper>
                    <CalendatDay />
                </IconWrapper>
            </Wrapper>
        }
        />
        </Wrapper>
  )
}
const Wrapper = styled.div`
    height: 3.5em;
    width: 3.5em;
    border-radius: 50%;
    .calendar {
        span {
            display: none;
        }
        .react-datepicker__month-text {
            font-size: 1.2em;
            color: #297f76;
            padding: 0.3em;
        }
        .react-datepicker__month-text--keyboard-selected {
            background: #297f76;
            color: #fff;
        }
    }
`
const IconWrapper = styled.div`
    
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
    background: #eff3f4;
    border-radius: 30px;
    z-index: 1;
    cursor: pointer;
    i {
        color: #297f76;
    }
`
export default DatePickerMonth