import React, { useEffect, useState, useContext, useMemo } from 'react'
import { Context } from './azmanotContext'
import { useDispatch, useSelector } from 'react-redux'
import './mainbar2.scss'
import { GET_PRODUCT_TYPES, LOAD_ORDERS } from '../../../../modules/API/settings'
import { LOAD, LOAD_END, LOGOUT } from '../../../../../store/actions/actionsTypes'
import ModalAzmanot from './modalAzmanot/modalAzmanot'
import ModalEditAzmana from './modalAzmanot/modalEditAzmana'
import { ContextModal } from '../../../../modules/modal/modalContext'
import styled from 'styled-components'
import Title from '../../../../generic/Title/Title'
import SelectBar from './azmanotmain/SelectBar'
import { statusAzmanotList } from '../../../../generic/artcoded/statusAzmanot'
import { Select, Wrapper } from '../likutExpress/likutWorkers/workersSelectList/WorkersSelectList';
import TitlesList from '../../../../generic/titlesRowItem/TitlesList'
import AzmanotItem from './modalAzmanot/azmanotitem'
import selectBrodMigdal from '../../../../generic/utils/selectBrodMigdal'
import ControlButton from '../../../../generic/buttons/ControlButton'
import { Link, Route, Switch } from 'react-router-dom'
import { Orders } from '../../../../modules/API/Orders/orders'
import { addIDForTheList } from '../../../../generic/utils/addUIDForTheList'
import { updateStatusAzmana } from '../../../../modules/requests/updateAzmanaStatus'
import useWebSocket from '../../../../generic/hooks/useWebSocket'
import { LINKS } from '../../../../Routs/config'
import LogsOrderModal from './modalAzmanot/LogsOrderModal'
// #e9f0f0 //bg
// #287e75 // title-hover color
// #fdfdfd // field bg
// #eff3f4 // inputs
// #e2eeec // shadow

export const SectionMain = styled.div`
    position: fixed;
    background: #e9f0f0;
    width: 100%;
    height: 100%;
    padding: 0.5em;
    @media screen and (min-width: 770px) {
        position: relative;
    }
    @media screen and (max-width: 400px) {
        padding: 0;
    }
    @media screen and (max-height: 500px) {
        position: relative;
        min-height: 1000px;
        overflow-y: scroll;
    }
    
    
`
export const MainWrapper = styled.div`
    position: relative;
    max-width: 1000px;
    height: 100%;
    background: #e9f0f0;
    border-radius: 30px;
    margin: 0 auto;
    padding: 0 0.5em 0.5em;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    overflow: hidden;
    @media screen and (max-width: 400px) {
        border-radius: 0;
    }
    
`
export const Decorator = styled.div`
    position: absolute;
    top: -50em;
    right: -15em;
    height: 1000px;
    width: 1500px;
    border-radius: 40%;
    background: rgba(76,148,141, 0.7);
    @media screen and (max-width: 550px) {
        top: -40em;
        right: -15em;
        height: 800px;
        width: 1000px;
    }
    
`

export const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    border-radius: 30px;
    overflow: hidden;
    background: #e9f3f2;
    z-index: 0;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31);
`

export const TitleListWrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 4.5em;
    color: #838484;
    padding: 0 0.5em;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    background: #fdfdfd;
    z-index: 0;
    overflow: hidden;
    border-radius: 30px;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.31);
    ::before {
        position: absolute;
        content: '';
        width: 3.5em;
        height: 3.5em;
        background: rgba(76,148,141, 0.7);
        right: 1em;
        bottom: -1.5em;
        border-radius: 50%;
        z-index: -1; 
    }
    ::after{
        position: absolute;
        content: '';
        width: 2.5em;
        height: 2.5em;
        background: rgba(76,148,141, 0.7);
        right: 4em;
        top: -1em;
        border-radius: 50%;
        z-index: -1;
    }
`
const TitleItemWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`
const TitleDecor = styled.div`

`
export const AzmanotListWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-width: 100%;
    background: #fdfdfd;
    border-radius: 20px;
    overflow-y: scroll;
    padding: 0.5em 0.5em;
    ::-webkit-scrollbar{
        width: 0;
    }
`
export const ContainerImg = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

function MainBarSecond() {
    const dispatch = useDispatch();
    const [modal, setModal] = useContext(ContextModal)
    const [date, setDate] = useState();
    const [selectedDateStart, setSelectedDateStart] = useState(null);
    const [selectedDateEnd, setSelectedDateEnd] = useState(null);
    const [input, setInput] = useState('')
    const [azmanotDefault, setAzmanotDefault] = useState([])
    const [azmanot, setAzmanot] = useState([])
    const [filterAzmanotStatus, setFilterAzmanotStatus] = useState(false);
    const [statusId, setStatusId] = useState(null);
    const [sortedValue, setSortedValue] = useState('')
    // <Edit>

    const [fileterGoods, setFilterGoods] = useState([])
    const [fileterGoodsDefault, setFilterGoodsDefault] = useState([])
    const [filterGoodName, setFilterGoodName] = useState('');
    const [innerTime, setInnerTime] = useState('');

    const packegeList = useSelector(packages => packages?.defaults?.packageList);
    const defaults = useSelector(e => e.defaults?.defaults);
    // const clientHash = useSelector(packages => packages?.defaults?.clientList?.hashTable);
    const getToken = useSelector(token => token.mainReducer.token);
    const user = useSelector(token => token.mainReducer.user);
    const dayJump = useSelector(item => item.defaults?.defaults?.filter(elem => elem.Int_ID === 338)[0]) || 0;

    const [isOpenLogs, setIsOpenLogs] = useState(false)
    const handleOpenLogs = () => {
        setIsOpenLogs(!isOpenLogs)
    }
    // set visibility for mlai and mhir from Redux data
    const {
        getMlai,
        getMhir,
        getPresetBrod,
        // isDividedByKwucot,
        isSellerSeeAllOrders,
        isBlockCreateOrder
    } = useMemo(() => {
        const isBlockCreateOrder = selectBrodMigdal(defaults, 614, 'Logical_Value');
        const getMlai = selectBrodMigdal(defaults, 546, 'Logical_Value');
        const getMhir = selectBrodMigdal(defaults, 547, 'Logical_Value');
        const getPresetBrod = selectBrodMigdal(defaults, 555, 'Logical_Value');
        // const isDividedByKwucot = selectBrodMigdal(defaults, 549, 'Logical_Value');
        // const isDividedByKwucot = selectBrodMigdal(defaults, 549, 'Logical_Value');
        const isSellerSeeAllOrders = selectBrodMigdal(defaults, 364, 'Logical_Value');
        return {
            getMlai,
            getMhir,
            getPresetBrod,
            // isDividedByKwucot,
            isSellerSeeAllOrders,
            isBlockCreateOrder
        }
    }, [defaults])
    
    const clientHash = useSelector(packages => packages?.defaults?.clientList?.hashTable) || [];
    
    const axios = require('axios');
    const CancelToken = axios.CancelToken.source();

    const {socket, message} = useWebSocket('wss://my.agroline.co.il/ws/ws')
    const whoCanSeeOrder = (customerID) => {
        const rights = user.rights
        const isShowAll = rights?.includes("ADMIN") || rights?.includes("OFFICE") || rights?.includes("DEV")
        if(!isShowAll) {
            if(rights.includes("CUSTOMER")) {
                return +user.code_internal === customerID
            } else if (rights.includes("SELLER")) {
                if (isSellerSeeAllOrders) return true
                return clientHash[customerID]?.ResellerID === +user.code_internal
            }
        } else {
            return true
        }
    
    }

    useEffect(() => {
        console.log('socket')
        let tempObj
        try {
            tempObj = JSON.parse(message)
        } catch {
            tempObj = null
        }
        if (!tempObj) return
        const found = azmanotDefault.find(e => e.OrderID === tempObj?.OrderID)
        if (found) {
            let tempArr = [...azmanot]
            let tempArrDef = [...azmanotDefault]
            if(tempObj.Status === -1) {
                const foundCurrent = azmanot.find(e => e.OrderID === tempObj?.OrderID);
                let indexOfItemDef = azmanotDefault.indexOf(found);
                tempArrDef.splice(indexOfItemDef, 1)
                if(foundCurrent) {
                    let indexOfItem = azmanot.indexOf(foundCurrent);
                    tempArr.splice(indexOfItem, 1)
                }
            } else {
                tempArr = azmanot.map(e => {
                    if(e.OrderID === tempObj?.OrderID) {
                        e = tempObj
                    }
                    return e
                })
                tempArrDef = azmanotDefault.map(e => {
                    if(e.OrderID === tempObj?.OrderID) {
                        e = tempObj
                    }
                    return e
                })
            }
            console.log(tempArr)
            console.log(tempArrDef)
            setAzmanot(tempArr)
            setAzmanotDefault(tempArrDef)
        } else {
            if(tempObj.Status === -1) return
            if(whoCanSeeOrder(tempObj.CustomerID)) {
                if(!input) {
                    setAzmanot([tempObj, ...azmanot])
                }
                setAzmanotDefault([tempObj, ...azmanotDefault])
            }
        }
    }, [message, socket])

    const postToWS = async (data) => {
        try {
            let res = await Orders.passNewOrderToWS(data)
        } catch (err) {

        }
    }

    const removeAzmanot = async OrderID => {
        try{
            let res = await Orders.deleteOrder(OrderID)
            setModal({
                open: true,
                value: res.message,
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            let found = azmanot.find(e => e.OrderID === OrderID)
            setAzmanot(azmanot.filter(azmanot => {
                return azmanot.OrderID !== OrderID
            }))
            if (found) {
                found.Status = -1
                console.log(found)
                postToWS(found)
            }
            updateStatusAzmana(OrderID, user, -1)
        } catch(err) {
            setModal({
                open: true,
                value: err.response.data.message,
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }  
    }

    const getAzmanot = async () => {
        const CancelToken = axios.CancelToken.source();
        try {
            let res = await Orders.getOrders(selectedDateStart, selectedDateEnd)
            console.log(res)
            const sorted = res.orders.sort((a, b) => {
                return a.Delivery_Date - b.Delivery_Date
            }).reverse()

            setAzmanot(sorted)
            setAzmanotDefault(sorted)
        } catch (err) {
            if ((err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired' || err.response?.data.message === 'קונטקסט לא פעיל, כנס מחדש למערכת')) {
                setModal({
                    open: true,
                    value: 'התנתקת מהמערכת, אנא כנס מחדש',
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            }
        }
    }
    console.log(fileterGoodsDefault)
    const getProdTypes = async () => {
        try {
            let response = await axios.get(`${GET_PRODUCT_TYPES}`, {
                headers: {
                    Authorization: 'Bearer ' + getToken
                }
            },
                {
                    cancelToken: CancelToken.token
                })
            setFilterGoods(response.data.productsGroups);
            setFilterGoodsDefault(response.data.productsGroups)
        } catch (err) {
            if (err.response.status !== 404) {
                setModal({
                    open: true,
                    value: err.response.data.message,
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
                if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                    dispatch({ type: LOGOUT, payload: false });
                }
            }
        }
    }
    

    useEffect(() => {
        getProdTypes()
        return () => {
            CancelToken.cancel("Component got unmounted");
        };
    }, [])
    useEffect(() => {
        if (!selectedDateEnd) return
        getAzmanot()
    }, [selectedDateEnd])


    const intersection = (arr1, arr2) => {
        let newArr = [];
        for (let i in arr2) {
            for (let j in arr1) {
                if (arr2[i] === arr1[j]) {
                    newArr.push(arr2[i])
                }
            }
        }
        return newArr
    }
    const filterAzmanot = async (input) => { // 

        const filtered = (filterAzmanotStatus ? azmanotDefault.filter(e => e.Status === +statusId) : azmanotDefault).filter(item => {
            const setToString = item.OrderID + ''
            return item.Customer_Name.toLowerCase().includes(input.toLowerCase()) || setToString.toLowerCase().includes(input.toLowerCase())
        })
        setInput(input);
        setAzmanot(filtered);
    }
    const sortAzmanotList = (target, status) => {
        if (!input.trim()) {
            setAzmanot([])
            const sorted = (status ?
                azmanotDefault.filter(e => (e.Status === +statusId) && (e.Customer_Name.toLowerCase().includes(input.toLowerCase()) || e?.OrderID?.toString().toLowerCase().includes(input.toLowerCase())))
                : azmanotDefault).sort((a, b) => a[target] - b[target]
                )
            setTimeout(() => {
                setAzmanot(sorted.reverse())
            }, 50)
        } else {
            const sorted = intersection(azmanot, (status ? azmanotDefault.filter(e => e.Status === +statusId) : azmanotDefault)).sort((a, b) => a[target] - b[target])
            setTimeout(() => {
                setAzmanot(sorted.reverse())
            }, 50)

        }
    }

    const filterByStatus = (value, sortedValue) => {
        // need edit search
        if (!value) {
            setFilterAzmanotStatus(false)
            sortAzmanotList(sortedValue, false)
        }
        else {
            setFilterAzmanotStatus(true)
            setAzmanot(azmanotDefault.filter(e => e.Status === +value))
        }
    }
    let showPic = azmanot?.length < 1 ? 'flex' : 'none'
    const azmanotListProps = {
        removeAzmanot: removeAzmanot,
        setInnerTime: setInnerTime
    }

   

    return (
        <Context.Provider value={[azmanot, setAzmanot, removeAzmanot, azmanotDefault, setAzmanotDefault]}>
            <Switch>
                <Route exact path={LINKS.orders.clientOrders.mainUrl}>
                    <SectionMain>
                        {
                            isOpenLogs &&
                            <LogsOrderModal closeFunc={handleOpenLogs} />
                        }
                        <MainWrapper>
                            <Decorator />
                            <Title label={'הזמנות לקוח'} />
                            <SelectBar
                                setDate={setDate}
                                setAzmanotDefault={setAzmanotDefault}
                                input={input}
                                setInput={filterAzmanot}
                                dayJump={dayJump}
                                setSelectedDateStart={setSelectedDateStart}
                                setSelectedDateEnd={setSelectedDateEnd}
                            />
                            {
                                user.rights.includes("DEV") ?
                                <LogWrapperButton onClick={handleOpenLogs}>
                                    LOGS
                                </LogWrapperButton> : null

                            }
                            
                            <ContentWrapper>
                                <TitleListWrapper>
                                    <TitleDecor />
                                    <TitleItemWrapper style={{ maxWidth: '20%' }} >
                                        <Wrapper>
                                            <Select onChange={(e) => { setStatusId(e.target.value); filterByStatus(e.target.value, sortedValue) }}>
                                                <option value='' >כל הסטטוסים</option>
                                                {statusAzmanotList.map(item => <option value={item.id + ''} key={item.id}>{item.Desc}</option>)}
                                            </Select>
                                        </Wrapper>

                                    </TitleItemWrapper>
                                    <TitleItemWrapper onClick={() => { sortAzmanotList('Delivery_Date', filterAzmanotStatus); setSortedValue('Delivery_Date') }} >תאריך</TitleItemWrapper>
                                    <TitleItemWrapper onClick={() => { sortAzmanotList('CustomerID', filterAzmanotStatus); setSortedValue('CustomerID') }} style={{ minWidth: '45%' }}>לקוח</TitleItemWrapper>
                                    <TitleItemWrapper onClick={() => { sortAzmanotList('OrderID', filterAzmanotStatus); setSortedValue('OrderID') }} >№</TitleItemWrapper>
                                </TitleListWrapper>
                                <AzmanotListWrapper  >
                                    <ContainerImg style={{ display: showPic }}>
                                        <img src="/images/no-data.png" alt="empty data" />
                                    </ContainerImg>
                                    <TitlesList
                                        list={azmanot}
                                        uniqueKey={'OrderID'}
                                        Element={AzmanotItem}
                                        additionalData={azmanotListProps}
                                    />
                                </AzmanotListWrapper>
                            </ContentWrapper>
                            {
                                isBlockCreateOrder ? 
                                    <ControlButton lable={'כרגע לא ניתן לבצע הזמנה'} /> :
                                        <Link to={LINKS.orders.clientOrders.create}>
                                            <ControlButton
                                                lable='הזמנה חדשה'
                                            />
                                        </Link>

                                
                            }
                        </MainWrapper>
                    </SectionMain>
                </Route>
                    <Route
                        path={LINKS.orders.clientOrders.create}
                        render={() =>
                            <ModalAzmanot
                                azmanot={azmanot}
                                setAzmanot={setAzmanot}
                                date={date}
                                packegeList={packegeList}
                                fileterGoods={fileterGoods}
                                setFilterGoods={setFilterGoods}
                                fileterGoodsDefault={fileterGoodsDefault}
                                setFilterGoodsDefault={setFilterGoodsDefault}
                                getMlai={getMlai}
                                getMhir={getMhir}
                                filterGoodName={filterGoodName}
                                setFilterGoodName={setFilterGoodName}
                                getPresetBrod={getPresetBrod}
                                getAzmanot={getAzmanot}

                            />
                        }
                    />
                    <Route
                        path={LINKS.orders.clientOrders.editGeneral}
                        component={ModalEditAzmana}
                    />
              
            </Switch>
        </Context.Provider>
    );
}

export default MainBarSecond;

const LogWrapperButton = styled.div`
    width: 100%;
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #297F76;
    z-index: 1;
    border-radius: 30px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
`