import { ExternalToast, toast } from "sonner";

interface NotificationData extends ExternalToast {
  type?: 'success' | 'error' | 'warning',
  closeButton?: boolean,
}
export const handleNotifications = (
  message: string,
  data?: NotificationData
) => {
  const { type, closeButton } = data || {};
  if (type === 'success') {
    toast.success(message, { closeButton, ...data });
  } else if (type === 'error') {
    toast.error(message, { closeButton, ...data });
  } else if (type === 'warning') {
    toast.warning(message, { closeButton, ...data });
  } else {
    toast.info(message, { closeButton, richColors: false, ...data });
  }
}