import React, { useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';

export interface CircleCheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type' | "size"> {
  checked?: boolean;
  indeterminate?: boolean;
  onChange?: (checked: boolean) => void;
  size?: string;
}

const checkedSuccess = keyframes`
  from {
    stroke-dasharray: 0 200 158 134.6;
  }
  to {
    stroke-dasharray: 0 162.6 28 134.6;
  }
`;

const CheckboxContainer = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  cursor: none;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  height: 0;
  width: 0;
`;

const CheckmarkSvg = styled.svg<{ checked: boolean; size: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  z-index: 1;
  border-radius: 999px;
  width: ${props => props.size};
  min-width: ${props => props.size};
  max-width: ${props => props.size};
  height: ${props => props.size};
  min-height: ${props => props.size};
  max-height: ${props => props.size};
  transition: fill 0.15s, stroke 0.15s;

  fill: ${props => (props.checked ? 'hsl(108, 62%, 55%)' : '#fff')};
  stroke: ${props => (props.checked ? 'hsl(0, 0%, 100%)' : '#75759E')};
`;

const CheckmarkPath = styled.path<{ checked: boolean }>`
  stroke-dashoffset: 162.6;
  transition: stroke-dashoffset 0.8s linear, stroke-dasharray 0.8s linear;
  ${props =>
    props.checked
      ? css`
          animation: ${checkedSuccess} 0.8s linear both;
          stroke-dasharray: 0 162.6 28 134.6;
        `
      : css`
          stroke-dasharray: 0 200 158 134.6;
        `}
`;

const CircleCheckbox: React.FC<CircleCheckboxProps> = ({
  checked = false,
  indeterminate = false,
  onChange,
  disabled,
  size = '32px',
  ...props
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.target.checked);
  };

  return (
    <CheckboxContainer>
      <HiddenCheckbox
        ref={checkboxRef}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        {...props}
      />
      <CheckmarkSvg viewBox="0 0 44 44" checked={checked} size={size}>
        <CheckmarkPath
          checked={checked}
          d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758"
          transform="translate(-2 -2)"
        />
      </CheckmarkSvg>
    </CheckboxContainer>
  );
};

export default CircleCheckbox;