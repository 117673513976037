import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { handleNotifications } from '../generic/utils/handleNotifications'
import { Auth } from '../modules/API/Auth/auth'
import { IChangePasswordData } from '../modules/API/Auth/models/recovery'



const useAuthService = () => {
  const history = useHistory()
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [isMatchCodeRecovery, setIsMatchCodeRecovery] = useState<boolean>(false)
  const [isAwaitingCodeVerifying, setIsAwaitingCodeVerifying] = useState<boolean>(false)
  const [tel, setTel] = useState<string>('')
  const [codeSMS, setCodeSMS] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')


  const restDidgitsTel = useMemo(() => 10 - tel.length, [tel])
  const handleTelephone = (e: React.ChangeEvent<HTMLInputElement>) => {
    // only numbers
    if(!/^\d+$/.test(e.target.value)) return

    if(!e.target.value[0] ) {
      setTel('')
      return
    }
    if(e.target.value[0] !== '0') {
      return
    }
    if(e.target.value.length > 10) return
    setTel(e.target.value)
  }
  const checkEmail = (email: string) => {
    const regx = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    return regx.test(email)
  }

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value)
  }

  const handleUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(isMatchCodeRecovery) return
    setUserName(e.target.value)
  }

  const handleCodeSms = (code: string) => {
    setCodeSMS(code)
  }

  const handleModal = () => {
    setIsShowModal(!isShowModal)
  }

  const verifyRecoveryCode = async () => {
    setIsAwaitingCodeVerifying(true)
    try {
      await Auth.checkRecoveryCode(codeSMS, tel, userName)
      handleModal()
      setIsMatchCodeRecovery(true)
    } catch (error: any) {
      if(error.response.status === 405) {
        handleNotifications('wrong code', {type: 'error'})
      } else {
        handleNotifications("משהו השתבש", {type: 'error'})
      }
    } finally {
      setIsAwaitingCodeVerifying(false)
    }
   
  }

  const sendSmsRecovery = async () => {
    if(tel.length !== 10) return
    try {
      await Auth.sendRecoveryCode(tel, userName)
      handleModal()
    } catch (error: any) {
      console.log('error', error.response.data)
    }
  }

  const handleChangePassword = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(isAlertPassword) return
    try {
      const putData: IChangePasswordData= {
        Code: codeSMS,
        Password: password,
        Phone: tel,
        UserID: userName
      }
      await Auth.changePassword(putData)
      handleNotifications('password changed', {type: 'success'})
      history.replace('/login')
    } catch (error: any) {
      handleNotifications("משהו השתבש", {type: 'error'})
    } finally {

    }
  }

  const checkPassword = () => {
    return !password || !password[4] || !confirmPassword || password !== confirmPassword
  }
  
  const isAlertPassword = useMemo(() => 
    checkPassword(), 
  [password, confirmPassword])

  return (
    {
      tel,
      userName,
      restDidgitsTel,
      codeSMS,
      isMatchCodeRecovery,
      isAlertPassword,
      isShowModal,
      isAwaitingCodeVerifying,
      handleModal,
      handleTelephone,
      checkEmail,
      verifyRecoveryCode,
      handlePassword,
      handleUserName,
      handleCodeSms,
      handleChangePassword,
      handleConfirmPassword,
      sendSmsRecovery
    }
  )
}

export default useAuthService