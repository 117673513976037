import { useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import React, { useMemo } from "react";
import { mainModules } from "../generic/artcoded/modulesList";
import { RootState } from "../../store/reducers/rootReducer";
import MainBarMain from "../main/second/mainbar/mainbar";
import MiunMain from "../main/second/mainbar/miun/MiunMain";
import { LINKS } from "./config";
import TeudotDetailsMain from "../main/second/mainbar/queries/TeudotDetails/TeudotDetailsMain";
import MarketCollectMain from "../main/second/mainbar/market/Collect/MarketCollectMain";
import MainQ4 from "../main/second/mainbar/procedures/Q4/MainQ4";
import MainQ2 from "../main/second/mainbar/procedures/Q2/MainQ2";
import MainQ8 from "../main/second/mainbar/procedures/Q8/MainQ8";
import PalletLocationMain from "../main/second/mainbar/palletLocation/PalletLocationMain";
import EditCustomerMain from "../main/second/mainbar/Pallets/EditCustomer/EditCustomerMain";
import CustomerBills from "../main/second/mainbar/bills/Customers/CustomerBills";
import PacksMovements from "../main/second/mainbar/arizot/movements/PacksMovements";
import SettingsIndex from "../main/second/mainbar/settings/SettingsIndex";
import NotFound from "../modules/404";
import ReceiptMain from "../main/second/mainbar/receipt/ReceiptMain";
import MlaiSectionMain from "../main/second/mainbar/mlai/MlaiSectionMain";
import DriversReportMain from "../main/second/mainbar/trucks/DriverReports/DriversReportMain";
import KnisotExternalMain from "../main/second/mainbar/knisotExternal/KnisotExternalMain";
import MainQ10 from "../main/second/mainbar/procedures/Q10/MainQ10";
import Index from "../main/second/mainbar/Pallets/LikutPrahim/Index";
import OrderCount from "../main/second/mainbar/orderDohs/count/OrderCount";
import SellerMain from "../main/second/mainbar/seller/SellerMain";
import ProductChangesMain from "../main/second/mainbar/products/ProductChanges/ProductChangesMain";
import TableCustomers from "../main/second/mainbar/defaultTables/Customers/TableCustomers";
import SendInvitePage from "../main/second/mainbar/invite/SendInvitePage/SendInvitePage";
import KnisotLinesMain from "../main/second/mainbar/knisotLines/KnisotLinesMain";

const AppRouting = () => {

  const modules = useSelector(
    (module: RootState) => module.mainReducer.modules
  );

  const redirect = useMemo(() => {
    if (modules) {
      console.log(modules)
      let moduletemp = mainModules(modules[0]?.ModuleId)
      if(!moduletemp) return <Redirect to={"/main/404"} />
      let redirectLink = moduletemp?.subModules[modules[0]?.Sub[0]?.Sub_ModuleID]?.link || moduletemp?.link || '/main/menu1'
      return <Redirect to={redirectLink} />;
    }
  }, [modules]);

  const routerList = useMemo(() => {
    return modules?.map((module, index) => {
      const moduleNum = module.ModuleId;
      const data = mainModules(moduleNum);
      if (module.Sub?.length > 0) {

        return module.Sub.map((submodule) => {
          const submoduleID = submodule.Sub_ModuleID;
          const subModuleData = data.subModules[submoduleID];

          return (
            <Route
              key={`${index}-${moduleNum}-${submodule.Sub_ModuleID}`}
              path={subModuleData?.link}
              component={subModuleData?.Component}
            />
          );
        });
      } else {

        return (
          <Route key={index} path={data?.link} component={data?.Component} />
        );
      }
    });
  }, [modules]);

  return (
    <Switch>
      {routerList}
      <Route path="/main/menu1" component={MainBarMain} />
      <Route path={"/main/procedures/q4"} component={MainQ4}/>
      <Route path={"/main/invites/growers"} component={SendInvitePage}/>
      <Route path={LINKS.knisot.lines.mainURL} component={KnisotLinesMain} />
      <Route path={LINKS.queries.q8.mainURL} component={MainQ8} />
      <Route path={LINKS.mishtahim.likutPrahim.mainURL} component={Index} />
      <Route path={LINKS.arizot.movements.mainUrl} component={PacksMovements} />
      <Route path={LINKS.mishtahim.location.mainURL} component={PalletLocationMain} />
      <Route path="/main/queries/teudot-details" component={TeudotDetailsMain} />
      <Route path={LINKS.mishtahim.editCustomer.mainURL} component={EditCustomerMain} />
      <Route path={LINKS.afaca.driversReport.mainURL} component={DriversReportMain} />
      <Route path={"/main/market"} component={MarketCollectMain} />
      <Route path={LINKS.bills.customerBills.mainURL} component={CustomerBills} />
      <Route path={LINKS.bills.receipt.mainURL} component={ReceiptMain} />
      <Route path={LINKS.mlai.mlai.mainURL} component={MlaiSectionMain} />
      <Route path={LINKS.knisot.external.mainURL} component={KnisotExternalMain} />
      <Route path={LINKS.queries.q10.mainURL} component={MainQ10} />
      <Route path={LINKS.forecasts.seller.mainURL} component={SellerMain} />
      <Route path={LINKS.orders.count.mainUrl} component={OrderCount} />
      <Route path={LINKS.orders.product.mainUrl} component={ProductChangesMain} />
      <Route path="/main/settings" component={SettingsIndex} />
      <Route path="/main/miun/control" component={MiunMain} />
      <Route path={LINKS.defaultTables.clients.mainURL} component={TableCustomers} />
      <Route path="/main/404" component={NotFound} />
      {redirect}
    </Switch>
  );
}

export default React.memo(AppRouting)
