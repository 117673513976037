import React, { useState, useContext, useEffect, useMemo} from 'react'
import BodySettingsLayout from '../Layouts/BodySettingsLayout'
import BodySection from '../Components/Body/BodySection'
import { Auth } from '../../../../../modules/API/Auth/auth'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import { LOGIN_SUCCESS } from '../../../../../../store/actions/actionsTypes'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import axios from 'axios'
import { DatabaseIcon, SubDatabase } from '../../../../../generic/icons/Icons'
import { IInitialState } from '../../../../../../store/initialState'
import { IAuthResponse, ICompanyItem } from '../../../../../modules/API/Auth/models/authDataModel'
import CustomSelectInput from './CustomSelectInput'
import { instance } from '../../../../../modules/API/config'

type IFromatedCompanies = {
  value: number,
  label: string

}
const AccountIndex = () => {
  const dispatch = useDispatch()
  const user: IInitialState = useSelector((state: RootState) => state.mainReducer)

  const [modal, setModal] = useContext(ContextModal)
  const [companies, setCompanies] = useState<IFromatedCompanies[]>([])
  const [isLoadCumanies, setIsLoadCumanies] = useState<boolean>(false)

  // Function to display an error message in a modal.
  const errorResponse = () => {
    setModal({
        open: false,
        value: "משהו השתבש",
        mood: true
    });
    setTimeout(() => {
        setModal({
            open: false,
            value: '2'
        });
    }, 3000);
  }

  const updateInstance = (token: string) => {
    instance.updateToken = token
  }
  const patchDatabase = async (id: string | number) => {
    try {
      const res: IAuthResponse = await Auth.patchDataBaseGo(id, 0)
      sessionStorage.clear()
      dispatch({ type: LOGIN_SUCCESS, payload: res })
      updateInstance(res.token)
      localStorage.setItem('user', JSON.stringify(res.token));
      await getInternalCompanies(id)

    } catch (error) {
      errorResponse()
    }
  }

  const getInternalCompanies = async (id: number | string) => {
    setIsLoadCumanies(true)
    try {
        let response = await Auth.getInternalCompanies(id)
        setCompanies(response.map((company: ICompanyItem) => ({value: +company.id, label: company.name})) || [])
    } catch (err) {
      errorResponse()
    } finally {
      setIsLoadCumanies(false)
    }
  }

  const patchCompany = async (id: string | number) => {
    try {
      const res: IAuthResponse = await Auth.patchDataBaseGo(user.current_company_id || 0, id)
      sessionStorage.clear()
      dispatch({ type: LOGIN_SUCCESS, payload: res })
      updateInstance(res.token)
      localStorage.setItem('user', JSON.stringify(res.token));
    } catch {
      errorResponse()
    }
  }

  useEffect(() => {
    getInternalCompanies(user.current_company_id || 0)
    return () => {
      axios.CancelToken.source().cancel()
    }
  }, [])

  const companiesList = useMemo(() => {
    return user?.companies?.map(company => ({value: company.id, label: company.name})) || []
  }, [user])

  return (
    <BodySettingsLayout>
      <BodySection title='הגדרות חשבון'>
      <CustomSelectInput 
        options={companiesList} 
        placeholder='מאגר מידע'
        onSelect={patchDatabase}
        defaultValue={user?.current_company_id || 0}
        icon={<DatabaseIcon />}
        title='מאגר מידע'
      />
      <CustomSelectInput 
        options={companies} 
        placeholder='חברה פנימית'
        onSelect={patchCompany}
        defaultValue={Number(user?.current_company_internal) || 0}
        icon={<SubDatabase />}
        title='חברה פנימית'
      />
      <CustomSelectInput 
        options={[{value: Number(user?.user?.year), label: user?.user?.year || ''}]} 
        placeholder='עונה'
        onSelect={patchDatabase}
        defaultValue={Number(user?.user?.year) || 0}
        icon={<SubDatabase />}
        title='עונה'
      />
      </BodySection>
    </BodySettingsLayout>
  )
}

export default AccountIndex