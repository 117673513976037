import React, { useEffect } from 'react'
import { FallbackProps } from 'react-error-boundary'
import { Logs } from '../../modules/API/LOGS/logs'
import { IPostErrorData } from '../../modules/API/LOGS/models/postError'
import styled from 'styled-components'
import ImageComponentHead from '../imageComponents/ImageComponentHead'
import Image from '../assets/noData.jpg'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/reducers/rootReducer'

const GeneralFallback = ({
  error,
  resetErrorBoundary
}: FallbackProps) => {
  const history = useHistory()
  const rights = useSelector((state: RootState) => state.mainReducer.user?.rights)
  const isDev = rights?.includes('DEV')
  
  useEffect(() => {
    if (isDev) return
    (async () => {
      const postData: IPostErrorData = {
        Type: error.name,
        Message: error.message,
        Trace: error.stack
      }
      try {
        await Logs.postError(postData)
      } catch (error) {
        console.error(error)
      }
    })()
  }, [error, isDev])
  
  const handleRedirection = () => {
    history.push('/')
  } 
  return (
    <MainWrapper role="alert">
      <ColumnContainer>
        <ImageComponentHead 
          img={Image}
        />
        <Title>משהו השתבש</Title>
        <pre>{error.message}</pre>
        <CardWrapper>
                <CardButton
                  onClick={resetErrorBoundary}
                  
                >
                  Try again
                </CardButton>
                <CardButtonSolid
                  onClick={handleRedirection}
                >
                  home
                </CardButtonSolid>
              </CardWrapper>
      </ColumnContainer>
    </MainWrapper>
)
}

const MainWrapper = styled.div`
  --main-color: #145a32;   
  --submain-color: #1e8449;   
  --bg-color: #ffffff;    
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
`
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`
const Title = styled.h2`
  color: var(--main-color);
  font-size: 1.3em;
  text-align: center;
`
const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5em;
  justify-content: center;
  font-size: 1em;
`;


const CardButton = styled.button`
  width: 76px;
  height: 31px;
  border: 2px solid var(--main-color);
  border-radius: 4px;
  font-weight: 700;
  font-size: 0.9em;
  color: var(--main-color);
  background: var(--bg-color);
  text-transform: uppercase;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background: var(--main-color);
    color: var(--bg-color);
  }
`;

const CardButtonSolid = styled(CardButton)`
  background: var(--main-color);
  color: var(--bg-color);

  &:hover {
    background: var(--bg-color);
    color: var(--main-color);
  }
`;
 
export default GeneralFallback