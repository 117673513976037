import React, { useState, useMemo, useEffect } from 'react'
import { IKavDataItem, IOrderItem } from '../../../../../modules/API/Drivers/models/kavData'
import { TCarDict } from '../../../../../../models/Defaults/carList'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import ColumClientElem from './ColumClientElem'
import { LocationIcon, PackageIcon, PalletIcon, ScalesIcon, SettingsIcon, SpeedIcon, UserIcon } from '../../../../../generic/icons/Icons'
import { SelectWorkerButton } from '../../../../../generic/cards/OrderGethering/OrderGetheringItemWithDetailes'
import CheckBoxWithTitle from '../../../../../generic/particles/CheckBox'
import { currentPalletWeightValue, machineСapacity } from '../helpers/summuryKavInfo'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'

interface IProps extends IKavDataItem {
    carInfo: TCarDict | null | undefined;
    reversed?: boolean;
    changeDriver: (item: IKavDataItem) => void;
    handleSelectToDrag: (elementID: number, kavID: number, CustomerID: number, OrderID: number, isOrder?: boolean
    ) => void
    item?: IKavDataItem;
    index?: number;
    handleOpenPackageOrder?: (item: IKavDataItem) => void;
    driverVisible?: (kavID: number, isShow: boolean) => void;
    handleClickSelectOrder: (item: IOrderItem, area: number, CustomerID: number) => void;
}

const KavItem = ({
    AreaID, AreaDesc, CarId, Customers, carInfo,
    ShowToDriver, handleClickSelectOrder,
    reversed, changeDriver, handleSelectToDrag,
    item, DriverName, driverVisible, index,
    handleOpenPackageOrder
}: IProps) => {
    const [isShowToDriver, setIsShowToDriver] = useState<boolean>(ShowToDriver)
    const ordersAllowToShow = [4, 7, 9]

    const isShowSwitcher = async () => {
        if (AreaID === 0 || !driverVisible) return
        driverVisible(AreaID, !isShowToDriver)
        setIsShowToDriver(!isShowToDriver)
    }

    const handleOpenPackage = () => {
        if (!item || !handleOpenPackageOrder) return
        handleOpenPackageOrder(item)
    }

    const { weightCalc, palletCalc, packageQty } = currentPalletWeightValue(Customers)
    const { CarNum, Max_Weight, Pallets } = machineСapacity(carInfo, CarId)
    const isOverWeigth = (weightCalc > (Max_Weight || 0) && palletCalc > (Pallets || 0)) && AreaID !== 0
    const fillCarByPallets = Math.round((palletCalc / (Pallets || 1)) * 100)
    const handleDriver = (e: React.MouseEvent) => {
        if (!item) return
        e.stopPropagation()
        changeDriver(item)
    }

    useEffect(() => {
        setIsShowToDriver(ShowToDriver)
    }, [ShowToDriver])

    return (


        <Droppable
            droppableId={AreaID + ''}
            type="dropOrder"
        >
            {
                (provided, snapshot) =>
                    <Wrapper
                        tabIndex={isOverWeigth ? (index || 0) + 1 : -1}
                        static={AreaID === 0}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        dragged={snapshot.isDraggingOver}
                        overwidth={isOverWeigth}
                        isShowDriver={isShowToDriver}
                    // key={item.PalletTransferZone}
                    >

                        <TitleWrapper static={AreaID === 0}>
                            <TitleInnerContainer>
                                {
                                    AreaID !== 0 &&
                                    <SideButton>
                                        <CircleActiveFilterBtn
                                            func={handleOpenPackage}
                                        >
                                            <PackageIcon />
                                        </CircleActiveFilterBtn>
                                    </SideButton>
                                }

                                <TitleItem
                                    mainField
                                    onClick={isShowSwitcher}
                                >
                                    {
                                        AreaDesc?.trim()
                                    }
                                    <SpeedIcon color={isShowToDriver ? '#80e081' : 'tomato'} />
                                </TitleItem>

                                <TitleItem >
                                    {
                                        !reversed &&
                                        <SelectWorkerButton
                                            style={{ padding: '0.2em 0.5em', background: '#fff' }}
                                            onClick={handleDriver}

                                        >
                                            <SettingsIcon />
                                        </SelectWorkerButton>
                                    }
                                    {DriverName?.trim()} - {CarNum}

                                    <UserIcon />
                                </TitleItem>
                                <TitleItem >
                                    <InnerRow >
                                        {
                                            !reversed &&
                                            <p>
                                                {Max_Weight} /
                                            </p>
                                        }
                                        <p>
                                            {weightCalc || 0}
                                        </p>
                                    </InnerRow>
                                    <ScalesIcon />
                                </TitleItem>
                                <TitleItem >

                                    {/* <InnerRow reversed={reversed}>
                                                    
                                                </InnerRow> */}
                                    {/* {palletQty} */}
                                    <InnerRow >
                                        {
                                            !reversed &&
                                            <p>
                                                {Pallets} /
                                            </p>
                                        }

                                        <p>

                                            {Math.ceil(palletCalc) || 0}
                                        </p>
                                        {
                                            !reversed &&
                                            <p>
                                                ({fillCarByPallets || 0}%)
                                            </p>
                                        }

                                    </InnerRow>
                                    <PalletIcon />
                                </TitleItem>
                                <TitleItem >
                                    <InnerRow >
                                        <LocationIcon />
                                        {Customers.length}
                                    </InnerRow>
                                    <InnerRow >
                                        <PackageIcon />
                                        {packageQty}
                                    </InnerRow>
                                </TitleItem>

                            </TitleInnerContainer>
                        </TitleWrapper>
                        {
                            Customers.map((item, i) =>
                                <ColumClientElem
                                    {...item}

                                    key={item.CustomerID}
                                    item={item}
                                    index={i}
                                    area={AreaID}
                                    ordersAllowToShow={ordersAllowToShow}
                                    handleSelectToDrag={handleSelectToDrag}
                                    handleClickSelectOrder={handleClickSelectOrder}
                                />
                            )
                        }
                        {provided.placeholder}
                    </Wrapper>
            }
        </Droppable>


    )
}

const Wrapper = styled.div<{ static?: boolean, dragged?: boolean, overwidth?: boolean, isShowDriver?: boolean }>`
    min-width: ${props => props.static ? '280px' : '280px'};
    background: ${props => props.dragged ? 'rgba(41,127,118, 0.5)' : props.isShowDriver ? "#80e081" : props.overwidth ? 'rgba(255,99,71, 0.9)' : '#fff'} ;
    padding:  0.5em;
    position: relative;
    /* overflow: auto; */
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 1em;
    transition: 0.5s;
    /* top: 0; */
    right: 0;
    border-radius: 30px;
    height: 100%;
  

`

const TitleWrapper = styled.div<{ static?: boolean }>`
    position: sticky;
    top: 0;
    width: 100%;
    min-height: ${props => props.static ? '8.7em' : '7.5em'} ;
    padding: 0;
    display: flex;
    /* direction: rtl; */

    justify-content: center;
    align-items: center;
    /* background: #a0bab8 */

`
const SideButton = styled.div`
    position: absolute;
    left: 0.5em;
    bottom: 0.5em;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
`
const TitleInnerContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: #eff3f4;
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    gap: .5em;
    -webkit-box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    padding: 0.5em;

`

const OutBoxContainer = styled.div<{ reversed?: boolean }>`
    position: absolute;
    top: -0.2em;
    left: -.2em;
    width: 2.5em;
    height: 2.5em;
    background: #e9f3f2;
    display: flex;
    flex-direction: ${props => props.reversed ? 'row' : 'row-reverse'};
    align-items: center;
    justify-content: center;
    gap: 0.2em;
    border-radius: 50%;
    padding: 0.3em;
    -webkit-box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    color:#75759e;
    i {
        color: tomato;
    }
`
const TitleItem = styled.div<{ reversed?: boolean, mainField?: boolean }>`
    width: 100%;
    /* background: red; */
    padding: 0 .5em;
    display: flex;
    flex-direction: ${props => props.reversed ? 'row' : 'row-reverse'};
    gap: 0.5em;
    justify-content: flex-end;
    color:#75759e;
    font-weight: ${props => props.mainField ? '600' : '400'};
    /* overflow: hidden; */
    
  text-overflow: ellipsis;
    i {
        color: tomato;
    }
`
const InnerRow = styled.div<{ reversed?: boolean }>`
    display: flex;
    flex-direction: ${props => props.reversed ? 'row-reverse' : 'row'} ;
    gap: 0.4em;
    justify-content: flex-end;
    /* background: red; */

`
export default React.memo(KavItem)