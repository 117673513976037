// FloatingLabelInput.jsx
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  /* min-height: 100vh;
  min-width: 100%; */
  justify-content: center;
  align-items: center;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 300px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  
`;
const SideButton = styled.div`
  position: absolute;
  top: 0.4em;
  left: 0.4em;
  width: 2.5em;
  height: 2.5em;
  border-radius: 1em;
  background: #E5E5E5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 2;
  color: #297F76;
  &:hover {
    background: #7AB0AB;
    color: #fff;
  }
`
const StyledInput = styled.input<{alert?: boolean}>`
  width: 100%;
  padding: 1rem 1rem 1rem 2.5rem; 
  background: inherit;
  border: ${props => props.alert ? "2px solid tomato" : "2px solid #297F76"} ; 
  border-radius: 1rem;   
  outline: none;
  transition: border-color 0.2s;
  direction: rtl; 
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:focus {
    border-color:${props => props.alert ? 'tomato' : "#297F76" }; 
  }

  /* Скрыть placeholder, чтобы не мешал плавающей метке */
  &::placeholder {
    opacity: 0;
  }
`;

// Стили для плавающей метки
const StyledLabel = styled.label`
  position: absolute;
  color: #6b7280;
  font-size: 1rem;
  top: 1rem; 
  right: 1.5rem; 
  pointer-events: none; 
  transition: all 0.15s ease-in-out;
  transform-origin: left top;

  /* Изначально метка остаётся на месте, если input пуст (placeholder-shown) */
  ${StyledInput}:placeholder-shown + & {
    transform: translateY(0);
    font-size: 1rem;
  }

  ${StyledInput}:focus + &,
  ${StyledInput}:not(:placeholder-shown) + & {
    transform: translateY(-2rem) translateX(2rem) scale(0.75);
    color: #297F76; 
  }
`;

const Icon = styled.div<{alert?: boolean}>`
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: ${props => props.alert ? 'tomato' : "#297F76" };
  width: 18px;
  height: 18px;
`;
const HelperText = styled.span`
  display: block;
  padding-top: 0.25rem; 
  color: #6b7280;     
  font-size: 0.875rem; 
`;
interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  tag: string;
  helperText?: string;
  icon?: React.ReactNode | JSX.Element;
  alert?: boolean;
  isNeedAButton?: boolean;
  handleIconClick?: () => void;
}

const FloatingLabelInput = (props: IProps) => {
  const {tag, helperText, isNeedAButton, icon, handleIconClick} = props;
  return (
    <Container>
      <InputWrapper>
        {
          isNeedAButton && (
            <SideButton 
              onClick={handleIconClick}
            >
              {icon}
            </SideButton>

          )
        }
        <Icon 
          alert={props.alert} 
        >
          {icon}
        </Icon>
        <StyledInput 
          alert={props.alert}
          id={tag}
          /* placeholder=" " важно, чтобы сработал селектор :placeholder-shown */
          placeholder=" " 
          {...props} 
        />
        <StyledLabel htmlFor={tag}>
          {tag}
        </StyledLabel>
        {
          helperText && <HelperText>{helperText}</HelperText>
        }
      </InputWrapper>
    </Container>
  );
};

export default FloatingLabelInput;