import React, {useState} from 'react'
import styled from 'styled-components'
import useLongPress from '../../../../../generic/hooks/useLongPress'
import { BasketIcon, BoxesIcon, CloseIcon, DollarIcon, EditIcon, PackageIcon, PalletIcon, PhoneIcon, ScalesIcon, ShopBagIcon, UserIcon } from '../../../../../generic/icons/Icons'
import { LikutState } from '../types/likutReducer'
import { IOrderTitle } from '../likutSection/types/orderTitle'

type Props = {
    orderTitle: IOrderTitle | null;
    likutInfo: LikutState;
    persents: number;
    isAddIdToDesc: boolean,
    progressColor: string,
    existWeigthQty: number,
    existArizotQty: number
}

const LikutSectionTitle = ({
    orderTitle, likutInfo, isAddIdToDesc,
     persents, progressColor, existWeigthQty, existArizotQty,
     
}: Props) => {
    const [isAbsolutPosition, setIsAbsolutPosition] = useState(false)
    const [isWisibleAdicionalInfo, setIsWisibleAdicionalInfo] = useState(false)
    
    const handleChangePosition = () => {
        setIsAbsolutPosition(!isAbsolutPosition)

        if(!isAbsolutPosition) {
            setTimeout(() => {
                setIsWisibleAdicionalInfo(true)

            }, 700)
        } else {
            setIsWisibleAdicionalInfo(false)
        }
    }
    const handleClose = () => {
        setIsAbsolutPosition(false)
        setIsWisibleAdicionalInfo(false)
        console.log('click')

    }
    const longPress = useLongPress(handleChangePosition, () => console.log('12'))

  return (
    <HeaderInformationWrapper 
        isAbsolut={isAbsolutPosition}
        {...longPress}
    >
        
    <HeaderInformationInnerContainer>
       
        <HeaderInformationInnerItem primal>
            <PersenceWrapper>
                <PersenceInner style={{ transform: `translateY(${100 - (persents > 100 ? 100 : persents)}%)`, background: progressColor }}>

                </PersenceInner>
                <PersenceIndicator style={{ color: persents < 55 ? '#7ab0ab' : '#fdfdfd' }}>
                    {persents}%
                </PersenceIndicator>
            </PersenceWrapper>
        </HeaderInformationInnerItem>
        <HeaderInformationInnerItem>
            <HeaderInformationItem primal>
                <HeaderItemInfo>
                    {likutInfo.azmanaNum}
                </HeaderItemInfo>
                <HeaderItemInfo >
                    <BasketIcon 
                        handle={() => {}}
                    />
                </HeaderItemInfo>
            </HeaderInformationItem>
            <HeaderInformationItem>
                <HeaderItemInfo> 
                    {likutInfo.clientName}
                </HeaderItemInfo>
                {
                    isAddIdToDesc &&
                    <HeaderItemInfo>
                        <IdWrapper>
                            {likutInfo.clientId}
                        </IdWrapper>
                    </HeaderItemInfo>
                }
                <HeaderItemInfo>
                    <UserIcon />
                    
                </HeaderItemInfo>
            </HeaderInformationItem>
            <HeaderInformationItem primal>
                <HeaderItemInfo>
                    {existWeigthQty?.toFixed(2)}
                </HeaderItemInfo>
                <HeaderItemInfo>
                    <ScalesIcon />
                </HeaderItemInfo>
                <HeaderItemInfo>
                    {existArizotQty}
                </HeaderItemInfo>
                <HeaderItemInfo>
                    <PackageIcon />
                </HeaderItemInfo>
            </HeaderInformationItem>
            
        </HeaderInformationInnerItem>
        
    </HeaderInformationInnerContainer>
    {
                isWisibleAdicionalInfo &&
                <>
                    <SideButton
                        onClick={handleClose}
                        onTouchStart={handleClose}
                    >
                        <CloseIcon
                            handler={() => {}}
                        />  
                    </SideButton>
                    <HeaderInformationItem primal style={{display: "flex"}}>
                        <HeaderItemInfo>
                            {orderTitle?.GivenPieces} / {orderTitle?.Pieces} <ShopBagIcon />
                        </HeaderItemInfo>
                        <HeaderItemInfo>
                            {orderTitle?.GivenPackages} / {orderTitle?.Packages} <PackageIcon />
                        </HeaderItemInfo>
                        <HeaderItemInfo>
                            {orderTitle?.GivenWeight} / {orderTitle?.Weight} <ScalesIcon />
                        </HeaderItemInfo>
                    </HeaderInformationItem>
                    <HeaderInformationItem primal style={{ display: orderTitle?.Remarks?.trim() ? 'flex' : 'none', color: 'tomato' }}>
                        <HeaderItemInfo isGrow>
                            {orderTitle?.Remarks?.trim()}
                        </HeaderItemInfo>
                        <HeaderItemInfo>
                            <EditIcon />
                        </HeaderItemInfo>
                    </HeaderInformationItem>
                    <HeaderInformationItem primal style={{ display: (typeof likutInfo?.pallet?.palletDesc === 'string') ? 'flex' : 'none' }}>
    
                        <HeaderItemInfo >
                            {likutInfo?.pallet?.palletDesc}
                        </HeaderItemInfo>
                        <HeaderItemInfo>
                            <PalletIcon />
                        </HeaderItemInfo>
                    </HeaderInformationItem>
                    <HeaderInformationItem primal style={{ display: orderTitle?.Seller_Desc?.trim() ? 'flex' : 'none' }}>
                        <HeaderItemInfo>
                            {orderTitle?.Seller_Desc?.trim()}
                        </HeaderItemInfo>
                        <HeaderItemInfo>
                            <DollarIcon />
                        </HeaderItemInfo>
                    </HeaderInformationItem>
                    <HeaderInformationItem primal style={{ display: orderTitle?.Phone?.trim() ? 'flex' : 'none' }}>
                        <HeaderItemInfo>
                            {orderTitle?.Phone?.trim()}
                        </HeaderItemInfo>
                        <HeaderItemInfo>
                            <PhoneIcon />
                        </HeaderItemInfo>
                    </HeaderInformationItem>
                   
                
                </>
            }
</HeaderInformationWrapper>
  )
}

export default LikutSectionTitle

export const HeaderInformationWrapper = styled.div<{isAbsolut: boolean}>`
    position: ${props => props.isAbsolut ? 'absolute' : 'relative'};
    top: ${props => props.isAbsolut ? '2.2em' : ''};
    left: ${props => props.isAbsolut ? '0.5em' : ''};
    background: #fdfdfd;
    height: ${props => props.isAbsolut ? '100%' : '8em'};
    width: ${props => props.isAbsolut ? '96%' : ''};
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    z-index: 2;
    border-radius: 30px;
    padding: ${props => props.isAbsolut ? '3em 0.7em': '0.7em'} ;
    -webkit-box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71); 
    box-shadow: -2px 8px 2px -6px rgba(41,127,118,0.71);
    user-select: none;
    transition: all 0.7s ease-in-out;
`
export const HeaderInformationInnerContainer = styled.div`
    position: relative;
    width: 100%;
    /* height: 100%; */
    /* background: #eff3f4; */
    border-radius: 25px;
    display: flex;

`
export const HeaderInformationInnerItem = styled.div<{primal?: boolean}>`
    height: 100%;
    width: ${props => props.primal ? '30%' : '100%'};
    /* background: red; */
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: ${props => props.primal ? 'flex-start' : 'flex-end'};
    gap: 0.5em;
`
export const HeaderInformationItem = styled.div<{primal?: boolean}>`
    /* background: blue; */
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 0.5em;
    color: #8a8aad;
    font-size: ${props => props.primal ? '1em' : '1.2em'};
`
export const HeaderItemInfo = styled.div<{isGrow?: boolean}>`
    /* font-size: ${props => props.isGrow ? '1.1em' : '1em'}; */
    border: ${props => props.isGrow ? '1px solid tomato' : 'none'};
    padding: ${props => props.isGrow ? '0 0.5em' : '0'};
    border-radius: 10px;
    color: ${props => props.isGrow ? 'tomato' : ''};
`
const PersenceWrapper = styled.div`
    position: relative;
    width: 3.5em;
    height: 3.5em;
    border-radius: 50%;
    border: 1px solid #7ab0ab;
    overflow: hidden;
`
const PersenceInner = styled.div`
    position: relative;
    width: 3.5em;
    height: 3.5em;
    background: red;
    /* transform: translateY(${100 - 50}%); */
    border-top-right-radius: 16%;
    /* border-top-left-radius: 12%; */
    transition: 0.7s;
    /* -webkit-clip-path: ellipse(99% 86% at 42% 86%);
    clip-path: ellipse(99% 86% at 42% 86%); */
    ::after {
        position: absolute;
        content: '';
        width: 3em;
        height: 1em;
        top: -0.75em;
        left: 0em;
        border-radius: 50%;
        z-index: 2;
        background: #fdfdfd;
    }
    
`
const PersenceIndicator = styled.div`
    position: absolute;
    top: 0;
    /* background: red; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
`
const IdWrapper = styled.span`
    padding: 0.1em 0.3em;
    box-shadow:0px 0px 2px gray;
    border-radius: 5px;
    
`
const SideButton = styled.div`
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 0px 2px gray;
    color: gray;
    z-index: 3;
`
