import React from 'react'
import styled from 'styled-components'

interface Props extends React.HTMLAttributes<HTMLSelectElement> {

}

const SortSelector = (props: Props) => {
 
  return (
    <MainWrapper>
      <Selector
        {...props}
      >
        <option value={'0'}>
          סידור הפצה
        </option>
        <option value={'1'}>
          סידור ליקוט
        </option>
      </Selector>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  padding: 0.2em;
`
const Selector = styled.select`
  width: 250px;
  height: 40px;
  padding: 0.5em;
  direction: rtl;
  border-radius: 15px;
  border: none;
  appearance: none;
  color: #8A97AC;
  cursor: pointer;
  font-size: 1.2em;
  outline: none;
`
export default SortSelector