import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { PhoneIcon, UserIcon } from '../generic/icons/Icons'
import FloatingLabelInput from './InputField'
import useDebounce from '../main/second/mainbar/seller/Helpers/useDebounce'
import TwoFactorForm from './TwoFactorForm'
import { Auth } from '../modules/API/Auth/auth'
import { handleNotifications } from '../generic/utils/handleNotifications'
import { IRegistrationPostData } from '../modules/API/Auth/models/authDataModel'
import { loadDefaults } from '../modules/requests/loadDefaults'
import { LOAD_DEFAULTS, LOAD_MODULES, LOGIN_SUCCESS, TABLE_OF_TABLES } from '../../store/actions/actionsTypes'
import { Defaults } from '../modules/API/Defaults/defaults'
import { useDispatch } from 'react-redux'
import { instance } from '../modules/API/config'

type Props = {}
const regex = /^(?=.*[a-zA-Z])(?=.*\d).{4,}$/

const RegistrationPage = (props: Props) => {
  // get params from url
  const dispatch = useDispatch()
  const [tel, setTel] = useState<string>('')
  const [isMatchCode, setIsMatchCode] = useState<boolean>(false)
  const [userName, setUserName] = useState<string>('')
  const [isExistUserName, setIsExistUserName] = useState<boolean>(false)
  // use for check if user name is available -->
  const [debouncedUserName, setDebouncedUserName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [codeSMS, setCodeSMS] = useState<string>('')
  const [isAwaitingCodeVerifying, setIsAwaitingCodeVerifying] = useState<boolean>(false)
  const location = useLocation()
  const token = location.search.split('=')[1]

  const handleTelephone = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(!e.target.value[0] ) {
      setTel('')
      return
    }
    if(e.target.value[0] !== '0') {
      return
    }
    if(e.target.value.length > 10) return
    setTel(e.target.value)
  }

  const handleModal = () => {
    setIsShowModal(!isShowModal)
  }
  const sendSms = async () => {
    if(tel.length !== 10) return
    try {
      const res = await Auth.sendSMS(tel)
      handleModal()
    } catch (error: any) {
      console.log('error', error.response.data)
    }
  }
  const verifySMS = async () => {
   
    if(codeSMS.split('').length !== 4) return
    setIsAwaitingCodeVerifying(true)
    try {
      const res: {status: string} = await Auth.verifySMS(codeSMS, tel)

      setIsMatchCode(res.status === "ok")
      if(res.status === "ok") {
        handleNotifications('קוד נכון', {type: 'success'})
        setIsShowModal(false)
      }

    } catch (error: any) {
      if(error.response.status === 405) {
        console.log('error', error.response.data)
        handleNotifications(error.response.data.error, {type: 'error'})
      }
    } finally {
      setIsAwaitingCodeVerifying(false)
    }
  };

  const handleCodeSms = (code: string) => {
    setCodeSMS(code)
  }

  const debounced = useDebounce(userName, 2000)

  const handleUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsExistUserName(false)
    setUserName(e.target.value)
  }

  useEffect(() => {
    // check if exist one digit and one letter
    // if(!regex.test(debouncedUserName)) return
    (async () => {
      try {
        const res = await Auth.checkUserName(debouncedUserName)
        setIsExistUserName(false)
      } catch (error: any) {
        if(error.response.data.error === "User already exists") {
          setIsExistUserName(true)
        }
      } finally {
  
      }
    })()

  }, [debouncedUserName])

  useEffect(() => {
    if(debounced.length < 3) return
    console.log('debounced', debounced)
    setDebouncedUserName(debounced)
  }, [debounced])
  
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }
  const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value)
  }
  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault()
    try {
      const dataPost: IRegistrationPostData = {
        Code: codeSMS,
        Password: password,
        Phone: tel,
        UserID: userName,
        Token: token,
        FullName: userName
      }
      const res = await Auth.registration(dataPost)
      localStorage.setItem('user', JSON.stringify(res.token));
      instance.updateToken = res.token
      dispatch({ type: LOGIN_SUCCESS, payload: res });
      let modules = await Defaults.getAvailableModules()
      dispatch({type: LOAD_MODULES, payload: modules})
      await loadDefaults(res.token, LOAD_DEFAULTS, TABLE_OF_TABLES, dispatch)
    } catch (error: any) {
      if(error.response.status === 405) {
        handleNotifications(error.response.data.error, {type: 'error'})
      }
      handleNotifications(error.response.data.error, {type: 'error'})

    }

  }
  const restDidgitsTel = 10 - tel.length

  const isAlertPassword = 
    !!password && 
    !!confirmPassword && 
    password !== confirmPassword && 
    !regex.test(password)

  const isAllowRegister = !isAlertPassword && !isExistUserName && isMatchCode
  return (
    <Section>
      {
        isShowModal ?
        <FormWrapper>
          <TwoFactorForm 
            setCodeSMS={handleCodeSms}
            codeSMS={codeSMS}
            isLoading={isAwaitingCodeVerifying}
            verify={verifySMS}
            closeForm={handleModal}
          />

        </FormWrapper> : ""

      }
      <Container>
        <Wrapper
          onSubmit={handleSubmit}
        >
          <MainBlock>
          <ContentWrapper logo>
              <Tag htmlFor="login" logo>
                  הרשמה   
                  
                  <BackBox />
              </Tag>
          </ContentWrapper>
          {/* <h1>Registration Page</h1> */}
          <ContentWrapper>
            <FloatingLabelInput
              alert={isExistUserName}
              value={userName}
              tag='שם משתמש'
              icon={<UserIcon />}
              autoComplete='off'
              onChange={handleUserName}
              helperText={
                isExistUserName ? 'שם משתמש תפוס' : 
                !userName ? 'שם משתמש חייב להכיל לפחות ספרה ואות' :
                regex.test(userName) ? '' 
                : 'שם משתמש חייב להכיל לפחות ספרה ואות'
              }
            />
          </ContentWrapper>
          <ContentWrapper>
                            
              <FloatingLabelInput 
                  tag='סיסמה'
                  icon={<i className="fas fa-lock"></i>}
                  onChange={handlePassword} 
                  type={'password'}
                  autoComplete='new-password'
                  alert={isAlertPassword}
              />
          </ContentWrapper>
          <ContentWrapper>
                            
              <FloatingLabelInput 
                  tag='לאשר את הסיסמה'
                  icon={<i className="fas fa-lock"></i>}
                  onChange={handleConfirmPassword} 
                  type={'password'}
                  autoComplete='new-password'
                  alert={isAlertPassword}
              />
          </ContentWrapper>
          <ContentWrapper>  
              <FloatingLabelInput 
                  tag='טלפון נייד'
                  icon={<PhoneIcon />}
                  value={tel}
                  disabled={isMatchCode}
                  onChange={handleTelephone} 
                  type={'tel'}
                  helperText={`נשארו ${restDidgitsTel} ספרות`}
              />
          </ContentWrapper>
         
         
          {
            !isMatchCode ?
            <ContentWrapper button>
              <Button
              type='button'
                onClick={sendSms}
                // disabled={tel.length !== 10 || !userName || !password || !confirmPassword}
              >
                <p>בדיקת תקפות הטלפון</p>
                <i className="fas fa-spinner"></i>
              </Button>
            </ContentWrapper>
            : isAllowRegister ?
            <>
              <ContentWrapper>
                <Button
                  type='submit' 
                  onClick={handleSubmit}
                  disabled={!isAllowRegister}
                >
                  הרשמה
                </Button>
              </ContentWrapper>
            
            </> : ''
          }
          
          <Link to={`/login?token=${token}`}>כניסה</Link>
          
          </MainBlock>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default RegistrationPage

const Section = styled.section`
    width: 100%;
    height: 100vh;
    background: #e9f0f0;
    position: fixed;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`
const FormWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    background-color: rgba(0,0,0,0.5);
    padding: 0 0.5em;
`
const Container = styled.div`
    width: 100%;
    height: 100%;
    /* position: absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5em;
    
    
`
const Wrapper = styled.form`
    color: #1F1F1F;
    width: 100%;
    height: 550px;
    background: #fdfdfd;
    border-radius: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.41));
    z-index: 2;
    @media screen and (min-width: 400px) {
        width: 400px;
    }
    /* ::before {
        content: "";
        position: absolute;
        width: 5%;
        height: 85%;
        background: #e9f3f2;
        left: -5%;
        top: 2.2em;
        border-radius: 30px 0 0 30px;
        z-index: -1;


    } */
`
const MainBlock = styled.div`
    width: 100%;
    height: 80%;
    /* background: blue; */
    display: flex;
    flex-flow: column;
    gap: 1em;
    
    align-items: center;
    justify-content: space-between;
    
`
export const ContentWrapper = styled.div<{row?: boolean, logo?: boolean, button?: boolean}>`
    /* width: 80%; */
    height: ${props => props.logo ? '15%' : props.button ? '12%' : '20%'};
    display:flex;
    flex-flow: ${props => props.row ? 'row' : 'column'};
    align-items: ${props => props.row ? 'center' : 'flex-end'} ;
    justify-content: ${props => props.row ? 'flex-end' : 'center'};
    
`

export const IconInput = styled.div`
    position: absolute;
    height: 100%;
    width: 2.5em;
    /* background: red; */
    i {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.5s ease;
        color: #1F1F1F;
    }
`
const Button = styled.button`
    width: 200px;
    padding: 0.5em 1em;
    background: #7ab0ab;
    border: none;
    border-radius: 10px;
    font-size: 1.2em;
    color: #fff;
    cursor: pointer;
    transition: 0.2s ease;

    :active{
        background: #297f76;
    }

    :disabled{
        p {
            display: none;
        }
        i {
            display: block;
            // padding: 0.2em 1em;
        }
    }
    i {
        display: none;
        animation: spin 1s infinite ;
        @keyframes spin {
            0% {transform: rotate(0);}
            100% {transform: rotate(360deg);}
        }
    }
`
export const Tag = styled.label<{logo?: boolean}>`
    font-size: ${props => props.logo ? '2em' : '1em'};
    font-family: sans-serif;
    position: relative;
    z-index: 2;
`
export const BackBox = styled.div`
    position: absolute;
    width: 2.5em;
    left: -0.4em;
    top: 0.5em;
    height: 50%;
    background: rgba(122,176,171, 0.7);
    z-index: -1;
`