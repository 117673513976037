
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import React, { useContext } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { returnResonBynum, useResonDescByNum } from '../../../../../generic/artcoded/resonslikut'
import { EditIcon, ScalesIcon, ShopBagIcon } from '../../../../../generic/icons/Icons'
import { checkPackType } from '../../../../../generic/utils/checkPackType'
import { EXIST_PATH } from '../../../../../modules/API/settings'
import { LikutModals } from '../../../../../modules/Context/CefiNum'
import SwipeRight from './swipe/SwipeRight'
import { useTranslation } from 'react-i18next'
import Checkbox from '../../../../../generic/ui/check-box/Checkbox'
import CircleCheckbox from '../../../../../generic/ui/check-box/CircleCheckbox'
export const SwipeWrapper = styled.div`
    min-height: 5.5em;
    margin-bottom: 0.7em;
    border-radius: 30px;
    overflow-x: hidden;
`
export const EmptyGoodContainer = styled.div`
    height: 5.5em;
    width: 6em;
    background: tomato;
    display: ${props => props.primal? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #fff;
    padding: 0 0.5em;
    cursor: pointer; 
`

export const ItemContainer = styled.div`
    position: relative;
    min-height: 5.5em;
    width: 100%;
    background: ${props => props.color? props.color : "#eff3f4"};
    display: flex; 
    flex-flow: column;
    border-radius: ${props => props.noneBorder ? 'none' : '30px'};
`

export const ImageContainer = styled.div`
    position: absolute;
    top: 1em;
    left: 0.5em;
    height: 4em;
    width: 4em;
    border-radius: 50%;
    background: #fdfdfd;
`
export const TitleContainer = styled.div`
    width: 100%;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1.8em;
    color: #8a8aad;
    font-size: 1.1em;
    font-weight: 600;
    gap: 0.5em;
`

export const DetalisContainer = styled.div`
    width: 100%;
    border-radius: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5em;
    padding-right: 2em;
`

export const DetalisItem = styled.div`
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
`

export const DetalisInnerItem = styled.div`
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8a8aad;
    span {
        font-weight: 600;
    }
`

export const RemarkElement = styled.div`
    position: absolute;
    top: -.3em;
    left: -.3em;
    background: tomato;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    display: flex;
    color: #fff;
    font-size: 0.9em;
    align-items: center;
    justify-content: center;
`
const RemarkBtn = styled.div`
    
    background: yellow;
    width: 2em;
    height: 5.5em;
    z-index: 2;
    display: ${props => props.primal ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    color: #fff;
    background: tomato;
    cursor: pointer;
`

const RemarkFieldWrapper = styled.div`
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
    background: tomato;
    transition: 0.3s;
    transform: ${props => props.primal ? 'translateX(0)' : 'translateX(100%)'};
    border-radius: 30px 0 0 30px;
    display: ${props => props.visible ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    padding: 0.5em;
`
const RemarkInner = styled.textarea`
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 30px 0 0 30px;
    padding: 0.5em;
    font-size: 1.2em;
    direction: rtl;
    border: none;
    resize: none;
    outline: none;

`

export default function LikutSectionGoodsItem({ 
    ProductDesc, ImageUrl, OrderPacks,  VarietyDesc,
    SupplyQty, defaultPackageList, PackID, RecID, OrderWeight, SupplyWeight,
    ChangeReason, selecteble, setDefaultPacks, item, setRecID, 
    recID, setGoodsCode, isWeightCalculation, ProductID,
    editOrderRow, SizeID, Remarks, selectGoodForLikut,  isOffice,
    index, NoTagCollection, ProductCalculationMethod, isAddIdToDesc,
    isAllowMultipleSave, handleSelectOrderLine, isSelected
}) {
    const [t] = useTranslation(['likut']);
    const reason = useResonDescByNum(+ChangeReason)
    const [resonState, setResonState] = useContext(LikutModals);
    const [isChecked, setIsChecked] = useState(!!isSelected);
    const [isOpenRemark, setIsOpenRemark] = useState(false);
    const [isOpenModalForEmptyGood, setIsOpenModalForEmptyGood] = useState(false);
    // console.log(setGoodsCode);
    // console.log(NoTagCollection)
    // 0778855866 misrad tell
    const handleCheckboxChange = (value) => {
        setIsChecked(value)
        handleSelectProduct()
    }
    const handleSelectProduct = () => {
        if(disableSelectCheckBox) return
        handleSelectOrderLine(RecID)
    }
    const disableSelectCheckBox = 
        !isAllowMultipleSave || 
        (!isOffice && !NoTagCollection) || 
        (OrderPacks - SupplyQty) <= 0
   
    const rest = isWeightCalculation ? (OrderWeight - SupplyWeight).toFixed(2) : OrderPacks - SupplyQty;
    let selectResult = checkPackType(defaultPackageList, PackID);
    let showIcon = selectResult === '1' ? <ScalesIcon /> : selectResult === '2' ? <ShopBagIcon /> : ''
    let bacPic = ImageUrl === '' ?
        'url("https://pngicon.ru/file/uploads/otkrytaja-korobka-128x85.png") center center no-repeat' :
        `url("${EXIST_PATH}${ImageUrl}") center center no-repeat`
    return (
        <SwipeWrapper>
            <SwipeableListItem
                swipeRight={{
                    content: <SwipeRight label={t('likut_sectionmain_product_swiperight_text')} />,
                    action: () => {
                        setRecID(RecID);
                        setIsOpenModalForEmptyGood(!isOpenModalForEmptyGood);
                    }
                }}
                swipeLeft={NoTagCollection || isOffice ? {
                    content: <SwipeRight poseition={true} label={t('likut_sectionmain_product_swipeleft_text')} />,
                    action: () => {
                        setGoodsCode('');
                        selectGoodForLikut(index);
                    }
                } : undefined}
                threshold={0.35}
            >
                <EmptyGoodContainer 
                    onClick={() => {
                        editOrderRow(3, 3);
                        setIsOpenModalForEmptyGood(!isOpenModalForEmptyGood)
                    }}
                    primal={recID === RecID && isOpenModalForEmptyGood}
                >
                    {t('likut_sectionmain_product_swiperight_button')}
                </EmptyGoodContainer>
               
                <ItemContainer
                    color={selectResult === '1' ? '#a6f29b' : selectResult === '2' ? '#fce2ae' : '#eff3f4'} 
                    noneBorder
                    onClick={() => {
                        if (selecteble) {
                            setDefaultPacks(item);
                            resonState?.action(true);
                            resonState?.props?.setRowModal(false);
                        }
                    }} 
                >
                    <RemarkFieldWrapper
                        primal={isOpenRemark}
                        visible={Remarks?.trim() !== ''}
                        onClick={() => {
                            setIsOpenRemark(!isOpenRemark)
                        }}
                    >
                        <RemarkInner disabled={true}>
                            {Remarks?.trim()}
                        </RemarkInner>
                    </RemarkFieldWrapper>
                    <ImageContainer style={{ background: bacPic, backgroundSize: 'contain' }} >
                    </ImageContainer>
                    <TitleContainer>
                        <>
                            {SizeID?.trim()} {ProductDesc?.trim()} {VarietyDesc?.trim()} 
                        </>
                        {
                            isAddIdToDesc &&
                            <IdWrapper>
                                {ProductID}
                            </IdWrapper>
                        }
                    </TitleContainer>
                    <DetalisContainer style={{marginBottom: '0.5em'}}>
                       
                        <DetalisItem>
                            <DetalisInnerItem style={{ color: 'tomato' }} >{showIcon}</DetalisInnerItem>
                            {/* <DetalisInnerItem>{OrderPacks - SupplyQty}</DetalisInnerItem> */}
                        </DetalisItem>
                        <DetalisItem>
                            <DetalisInnerItem primal>
                                <SpanWrapper>
                                    {rest}
                                </SpanWrapper>
                                {
                                    isWeightCalculation && NoTagCollection && ProductCalculationMethod ?
                                        ' יח' :
                                        isWeightCalculation ? ' ק"ג' : ''
                                }
                               
                            </DetalisInnerItem>
                            {/* <DetalisInnerItem>{SupplyQty}</DetalisInnerItem> */}
                        </DetalisItem>
                        <DetalisItem>
                            <DetalisInnerItem primal>{t('likut_sectionmain_product_rest_text')}</DetalisInnerItem>
                            {/* <DetalisInnerItem>{OrderPacks}</DetalisInnerItem> */}
                        </DetalisItem>
                        {
                            !disableSelectCheckBox &&
                            <CircleCheckbox 
                                size='1.5em'
                                onChange={handleCheckboxChange}
                                checked={isChecked}

                            />
                        }
                    </DetalisContainer>
                    {
                        isWeightCalculation && NoTagCollection &&  
                            <DetalisContainer style={{marginBottom: '0.5em'}}>
                                <DetalisItem>
                                    <DetalisInnerItem>
                                    <SpanWrapper>
                                        {OrderPacks - SupplyQty}
                                    </SpanWrapper>
                                    </DetalisInnerItem>
                                </DetalisItem>
                                <DetalisItem>
                                    <DetalisInnerItem >{t('likut_sectionmain_product_rest_packages_text')}</DetalisInnerItem>
                                    {/* <DetalisInnerItem>{OrderPacks - SupplyQty}</DetalisInnerItem> */}
                                </DetalisItem>
                            </DetalisContainer>
                    }
                    {
                        reason ?
                            <DetalisContainer>
                                <DetalisItem>
                                    <DetalisInnerItem style={{ color: 'tomato' }} >{reason}</DetalisInnerItem>
                                    {/* <DetalisInnerItem>{OrderPacks - SupplyQty}</DetalisInnerItem> */}
                                </DetalisItem>
                                <DetalisItem>
                                    <DetalisInnerItem >{t('likut_sectionmain_product_problem')}</DetalisInnerItem>
                                    {/* <DetalisInnerItem>{OrderPacks - SupplyQty}</DetalisInnerItem> */}
                                </DetalisItem>
                            </DetalisContainer>
                            : ''
                    }
                </ItemContainer>
                <RemarkBtn 
                    primal={Remarks?.trim() !== ''} 
                    onClick={() => {
                        setIsOpenRemark(!isOpenRemark)
                    }}
                >
                    <EditIcon />
                </RemarkBtn>
            </SwipeableListItem>
        </SwipeWrapper>
    )
}

const IdWrapper = styled.span`
    padding: 0.1em 0.3em;
    /* background: #8CC53D; */
    border-radius: 5px;
    box-shadow: 0px 0px 2px gray;
    font-weight: 400;
    
`

const SpanWrapper = styled.span`
    font-weight: 600;
`
