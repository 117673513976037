export const SunIcon = () =>
    <i className="fa-regular fa-sun"></i>

export const MoonIcon = () =>
    <i className="fa-regular fa-moon"></i>

export const SearchIcon = () => 
    <i className="fas fa-search"></i>

export const UserIcon = () => 
    <i className="fas fa-user"></i>

export const CloseIcon = ({handler}) =>
    <i onClick={handler} className="fas fa-times"></i>  

export const BasketIcon = ({handle}) =>
    <i onClick={handle} className="fas fa-shopping-basket"></i>   

export const PalletIcon = () =>
    <i className="fas fa-pallet"></i> 

export const DatabaseIcon = () =>
    <i className="fa-solid fa-server"></i>

export const SubDatabase = () =>
    <i className="fa-regular fa-building"></i>


export const LangIcon = () =>
    <i className="fa-solid fa-globe"></i>

export const PackageIcon = () =>
    <i className="fas fa-box"></i>

export const ExpectingIcon = () =>
    <i className="far fa-share-square"></i>

export const InvoiceIcon = () =>
    <i className="fas fa-file-invoice"></i>

export const BackHumanIcon = () =>
    <i className="fa-solid fa-person-walking-arrow-loop-left"></i>

export const LogoutIcon = () =>
    <i className="fas fa-sign-out-alt"></i>
    
export const BarIcon = () =>
    <i className="fas fa-bars"></i>

export const PlusIcon = () =>
    <i className="fas fa-plus"></i>

export const MinusIcon = () =>
    <i className="fas fa-minus"></i>

export const ShekelIcon = () =>
    <i className="fas fa-shekel-sign"></i>

export const DollarIcon = () =>
    <i className="fa-solid fa-dollar-sign"></i>

export const EuroIcon = () =>
    <i className="fa-solid fa-euro-sign"></i>

export const ChequeIcon = () =>
    <i className="fa-solid fa-money-check"></i>
    
export const BankIcon = () => 
    <i className="fa-solid fa-building-columns"></i>

export const CreditCardIcon = () => 
    <i className="fa-regular fa-credit-card"></i>

export const CashIcon = () => 
    <i className="fa-solid fa-money-bill"></i>

export const AcceptIcon = () =>
    <i className="fas fa-check"></i>

export const WarehouseIcon = () =>
    <i className="fa-solid fa-warehouse"></i>

export const CaretDownIcon = () =>
    <i className="fa-solid fa-caret-down"></i>


export const ScalesIcon = () =>
    <i className="fas fa-balance-scale-left"></i>

export const DivideIcon = () =>
    <i className="fa-solid fa-divide"></i>

export const BarCodeIcon = () =>
    <i className="fas fa-barcode"></i>

export const EditIcon = () =>
    <i className="fas fa-pen"></i>

export const PrintIcon = () =>
    <i className="fas fa-print"></i>

export const SettingsIcon = () =>
    <i className="fas fa-cog"></i>

export const ShopBagIcon = () =>
    <i className="fas fa-shopping-bag"></i>

export const SettingMiksher = () =>
    <i className="fas fa-sliders-h"></i>

export const EmptyFlag = () =>
    <i className="far fa-flag"></i>

export const FilledFlag = () =>
    <i className="fas fa-flag"></i>

export const CalendatDay = () =>
    <i className="fas fa-calendar-day"></i>

export const ReloadIcon = () =>
    <i className="fas fa-redo"></i>

export const PlaneIcon = () =>
    <i className="fa-regular fa-paper-plane"></i>
    
export const ShipIcon = () =>
    <i className="fa-solid fa-ship"></i>

export const ErrorIcon = () =>
    <i className="fa-solid fa-circle-exclamation"></i>

export const DeleteIcon = () =>
    <i className="fa-solid fa-trash-can"></i>

export const ClickIcon = () =>
    <i className="fa-solid fa-hand-pointer"></i>

export const SpeedIcon = ({color}) =>
    <i style={{color: color}} className="fas fa-shipping-fast"></i>

export const AppleIcon = () =>
    <i className="fas fa-apple-alt"></i>

export const SortIcon = () =>
    <i className="fa-solid fa-sort"></i>
export const AsteriksIcon = () =>
    <i className="fa-solid fa-asterisk"></i>

export const LocationIcon = () =>
    <i className="fa-solid fa-location-dot"></i>

export const LogIcon = () =>
    <i className="fa-solid fa-timeline"></i>

export const ChartIcon = () =>
    <i className="fa-solid fa-chart-line"></i>

    
export const InfoIcon = () =>
    <i className="fa-solid fa-info"></i>

    
export const SwapIcon = () =>
    <i className="fa-solid fa-arrow-right-arrow-left"></i>
 
    
export const StampIcon = () =>
    <i className="fa-solid fa-stamp"></i>
 
    
export const BoxesIcon = () =>
    <i className="fa-solid fa-cubes"></i>
 
    
export const TimeIcon = () =>
    <i className="fa-regular fa-clock"></i>
 
    
export const PhoneIcon = () =>
    <i className="fa-solid fa-mobile-screen-button"></i>

export const ArrowLongIcon = () =>
    <i className="fa-solid fa-arrow-down-long"></i>

export const ChecklistIcon = () =>
    <i className="fa-solid fa-list-check"></i>

export const TrophyIcon = () =>
    <i className="fa-solid fa-trophy"></i>

export const InvoiceMoneyIcon = () =>
    <i className="fa-solid fa-file-invoice-dollar"></i>

export const LinkIcon = () =>
    <i className="fa-solid fa-link"></i>

export const EmailIcon = () =>
    <i className="fa-solid fa-at"></i>

export const VerticalDots = () =>
    <i className="fa-solid fa-ellipsis-vertical"></i>

export const SlidersIcon = () =>
    <i className="fa-solid fa-sliders"></i>

export const FilterIcon = () =>
    <i className="fa-solid fa-filter"></i>
    
export const ArrowIcon = ({position}) => 
    <i 
        className="fa-solid fa-arrow-right"
        style={{
            transform: position === 'left' ? 'rotate(180deg)' :
                position === 'top' ? 'rotate(-90deg)' : 
                    position === 'bottom' ? 'rotate(90deg)' : 'none'
        }}
    ></i>    





