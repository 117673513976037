import React, { useRef, useState } from 'react'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import DropdownByIcon from '../../azmanotRehesh/Layouts/DropdownByIcon'
import { BarCodeIcon, BarIcon } from '../../../../../generic/icons/Icons'
import ScanInput, { IRef } from '../../kosherTeudot/Components/ScanInput'
import useScannedDataHandler from '../../kosherTeudot/Hooks/useScannedDataHandler'
import { format } from 'date-fns'
import useGetKnisaLinesService from '../Services/useGetKnisaLinesService'
import FormComponent from './FormComponent'

type Props = {
  getKnisotLinesByProduct: (productId: number, dates: Date[], growerID: number) => void
  getKnisaLineByBarcode: (barcode: string) => void
}

const GetKnisaLines = ({
  getKnisaLineByBarcode,
  getKnisotLinesByProduct
}: Props) => {
  const refSearch = useRef<IRef | null>(null)
  const [dates, setDates] = useState<Date[]>([new Date(), new Date()])
  const [productID, setProductID] = useState<number>(0)
  // const [growerID, setGrowerID] = useState<number>(0)
  const [search, setSearch] = useState<string>("")

  const {
    products,
    growers,
    grower,
    setGrower,
    isGrower
  } = useGetKnisaLinesService()

  const handleScan = () => {
    const barcode = search
    getKnisaLineByBarcode(barcode)
  }

  const handleSearchByProduct = () => {
    getKnisotLinesByProduct(productID, dates, grower)
  }

  useScannedDataHandler(refSearch, handleScan)

  return (
    <ControlSearchPannelLayout>
        <DropdownByIcon
          icon={<BarIcon />}
        >
          <FormComponent 
            selected={dates}
            onChange={setDates}
            handleSearchByProduct={handleSearchByProduct}
            setGrowerID={setGrower}
            setProductID={setProductID}
            growers={growers}
            products={products}
            growerID={grower}
            productID={productID}
            isGrower={isGrower}
          />
        </DropdownByIcon>
        <ScanInput
          input={search}
          setInput={setSearch}
          ref={refSearch}
          enterFunc={handleScan}
          icon={<BarCodeIcon />}
        />
      </ControlSearchPannelLayout>
  )
}

export default GetKnisaLines