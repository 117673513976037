import { useSelector } from 'react-redux'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
    Link,
    useLocation
} from "react-router-dom"
import { useDispatch } from 'react-redux';
import { LOGOUT, LOAD_PACKAGELIST, LIKUT_WITHOUT_AZMANA, LOAD_END, LOAD_DEFAULTS, LOAD_WORKERS, TABLE_OF_TABLES, LOAD_CLIENTS, LOAD_TRANSPORT_AREAS, LOAD_WORKER_TYPE, LOAD_PERSONAL_RIGHTS } from '../../../store/actions/actionsTypes'
import './second.scss'
import { ContextCefi } from '../../modules/Context/CefiNum'
import LogOutBtn, { IconWrapperControl } from '../../header/logoutBtn/logoutBtn'
import { loadDefaults } from '../../modules/requests/loadDefaults'
import styled from 'styled-components';
import { InvoiceIcon, PackageIcon, PalletIcon } from '../../generic/icons/Icons';
import LoaderSpinner from '../../modules/loadSpinner/LoaderSpinner';
import IfDisconnect from '../../generic/ifDisconnect/IfDisconnect';
import { useDetectOutsideClick } from '../../generic/hooks/useDetectOutsideClick';
import Logo from '../../generic/imageComponents/Logo';
import { mainModules } from '../../generic/artcoded/modulesList';
import AppRouting from '../../Routs/AppRouting';
import { Defaults } from '../../modules/API/Defaults/defaults';
import LangSwitcher from '../../generic/particles/LangSwitcher';
import ColumModal from '../../generic/Layouts/ColumModal';
import PrinterSettingsModal from '../../generic/modals/PrinterSettingsModal';
import { SET_IS_OPEN } from '../../../store/reducers/printer';
import { Toaster } from 'sonner';
import { BrodMigdalProvider } from '../../generic/modals/BrodMigdalModal/BrodMigdalProvider';
import { ErrorBoundary } from 'react-error-boundary';
import GeneralFallback from '../../generic/ErrorBoundary/GeneralFallback';

const MainSection = styled.div`
    font-family: sans-serif;
    text-align: center;
    letter-spacing: 0.5px;
    height: 100%;
`
const ContentWrapper = styled.section`
   height: 100vh;
   width: 100%;
   @media screen and (max-height: 500px) {
        height: 1000px;
        overflow-y: scroll;
    }
`
const SideBarWrapper = styled.div`
    background: #e9f0f0;
    height: 100%;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    z-index: 2;
    padding: 0.5em;
    width: 100%;
    z-index: 9;
    position: fixed;
    background: rgba(0,0,0,0.5);
    padding: 0.5em 0;
    @media screen and (max-width: 770px) {
       
    }
`
const SideBarInnerContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 2em 1em;
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    width: 300px;
    border-radius: 30px 0 0 30px;
    @media screen and (max-width: 770px) {
        
    }

    ::before {
        position: absolute;
        bottom: 0;
        left: -1em;
        height: 99%;
        width: 100px;
        content: '';
        background: #e9f3f2;
        z-index: -1;
        border-radius: 30px;
    }
`

const LogoContainer = styled.div`
    background: #eff3f4;
    width: 100%;
    min-height: 4em;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    padding: 0 0.3em;
    color: #297f76;
    -webkit-box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
    box-shadow: -2px 8px 2px -6px rgb(41 127 118 / 71%);
`

const LogoImgWrapper = styled.div`
    height: 3.5em;
    width: 3.5em;
    background: yellow;
    border-radius: 50%;
`

const NavMenuWrapper = styled.div`
    height: 100%;
    width: 100%;
    background: #eff3f4;
    border-radius: 30px;
    padding: 1.5em 0 0 0.5em;
    overflow-y: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    ::-webkit-scrollbar{
        width: 0;
    }
`

const NavHoverDecoration = styled.div`
    position: absolute;
    width: 130%;
    height: 100%;
    border-radius: 30px ;
    background: #297f76;
    transition: 0.5s ease;
    transform: ${props => props.isLocationHere ? "translateX(10%)" : "translateX(85%)"};
    z-index: -1;
`

const NavItemContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    width: 100%;
`

const NavItemDropMenuItem = styled.li`
    padding: 0.2em .5em;
    width: 100%;
    transition: 0.3s ease;
    display: flex;
    opacity: ${props => props.primal ? '1' : '0'};
    justify-content: flex-end;
    cursor: pointer;
    color: #75759e;
    :hover {
        background: #7ab0ab;
        color: #ffffff;
    }
`

const NavItemDropMenuContainer = styled.ul`
    background: #ffffff;
    width: 90%;
    /* chenge heigth when changing state */
    height: 11em;
    transition: 0.3s ease;
    border-radius: 0 0 0 20px;
    border-right: 4px solid #7ab0ab;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    overflow: hidden;
    gap: 0.5em;
`

const NavItemWrapper = styled.div`
    position: relative;
    background: #ffff;
    color: ${props => props.isLocationHere ? "#fff" : "#75759e"};
    min-height: 3em;
    min-width: 100%;
    border-radius: 30px 0 0 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5em;
    padding: 0 0.2em;
    overflow: hidden;
    transition: 0.5s;
    z-index: 0;
    &:hover{
        color: #ffff;
    }
    &:hover ${NavHoverDecoration} {
        transform: translateX(10%);
    }
`

const IconWrapper = styled.div`
    height: 2.5em;
    width: 2.5em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
`

const ControlWrapper = styled.div`
    min-height: 3.5em;
    width: 100%;
    border-radius: 30px;
    padding: 0.5em 0;
    background: #eff3f4;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
`

export const LoadingModal = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(241,242,243,0.9);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
`

function SecondScreen() {
    const modules = useSelector(module => module.mainReducer.modules);
    // const queryUrl = useLocation()
    const [iOnline, setIsOnline] = useState(window?.navigator?.onLine);
    const [isFullFill, setIsFullFill] = useState(false);
    const [isShowDropMenuLikut, SetIsShowDropMenuLikut] = useState(false);
    const [isShowDropMenuArizot, SetIsShowDropMenuArizot] = useState(false)
    const [isShowDropMenuOrders, setIsShowDropMenuOrders] = useState(false)
    const [isShowDropMenuQueries, setIsShowDropMenuQueries] = useState(false)
    const [isShowDropMenuBills, SetIsShowDropMenuBills] = useState(false);
    const [isShowDropMenuPallets, setIsShowDropMenuPallets] = useState(false);
    const [isShowDropMenuAfacades, setIsShowDropMenuAfacades] = useState(false);
    const [isShowDropMenuKnisa, setIsShowDropMenuKnisa] = useState(false);
    
    const [cefiNum, setCefiNum] = useState('')
    const [toggle, setToggle] = useState(false);
    const [subName, setSubName] = useState('')
    const dispatch = useDispatch()
    const location = useLocation();
    const axios = require('axios');
    const [workersList, setWorkersList] = useState(null)
    const burgerRef = useRef(null)
    const buttonRef = useRef(null)
    const burgerMenuRef = useRef();
    const loadStatus = useSelector(load => load.mainReducer.loading);
    const idForWorker = useSelector(token => token?.mainReducer?.user?.code_internal);
    const getToken = useSelector(token => token.mainReducer.token);
    const getLogin = useSelector(token => token.mainReducer.login);
    const userRuls = useSelector(userStatus => userStatus.mainReducer.user !== null ? userStatus.mainReducer.user.rights : '');
    const userType = useSelector(user => user.mainReducer.user !== null ? user.mainReducer.user.code_internal : '');
    const CancelToken = axios.CancelToken.source();
    const printerState = useSelector(printer => printer.printerReducer)

    useMemo(() => {
        if(!workersList || !idForWorker) return
        dispatch({type: LOAD_WORKER_TYPE, payload: workersList[+idForWorker]?.Role_108})
    },[workersList, idForWorker])
    
    useEffect(() => {
        dispatch({type: LOAD_END})
    }, [])
    
    // listen when leave from settings fetch defaults
    let arr = []
    let temp = useRef(arr);
   
    useEffect(() => {
        const url = '/main/settings/account'
        if(location?.pathname === url) {
            temp.current.push(location?.pathname)
        
        } else {
            if (temp.current[0] === url) {
                nessesaryDataLoad()
            }
            temp.current = []
        }
    }, [location])

    const getPackeges = async () => {
        try {
            let res = await Defaults.getPackages()

            dispatch({ type: LOAD_PACKAGELIST, payload: res?.packages?.filter(item => item.Active) })
           
        } catch (err) {
            console.log(err.response.data)
            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            }
        }

    }
    const getClients = async () => {
        try {
            let res = await Defaults.getCustomers()
            const formatedCustomers = res.customers.filter(item =>
                item.Active
            )
            const reduced = formatedCustomers.reduce((acc, cur) => {
                acc[cur.CustomerID] = cur
                return acc
            }, {})
            const reduxState = {
                list: formatedCustomers,
                hashTable: reduced
            }
            dispatch({type: LOAD_CLIENTS, payload: reduxState})
            // console.log(reduced)
        } catch (err) {
            console.log(err.response)
        }
    }
    // console.log(workersList)
    const getWorkers = async () => {
        try {
            let res = await Defaults.getWorkers()
            dispatch({ type: LOAD_WORKERS, payload: res.workers });
            let reducedArr = res.workers.reduce((acc, cur) => {
                acc[cur.WorkerId] = cur
                return acc
            }, {})
            // console.log(res.data.workers)
            setWorkersList(reducedArr);
            
        } catch (err) {
            console.log(err.response)
        }
    }
    const getRights = async () => {
        try {
            const res = await Defaults.getRights()
            dispatch({ type: LOAD_PERSONAL_RIGHTS, payload: res })
        } catch (err) {
            console.log(err.response)
        }
    }
    const getTransportAreaCodes = async () => {
        try {
            let res = await Defaults.getTransportAreas()
            const data = () => {
                let reduced = res.transportAreas.reduce((acc, cur) => {
                    acc[cur.AreaID] = cur
                    return acc
                }, {})
                return reduced
            }
    
            dispatch({type: LOAD_TRANSPORT_AREAS, payload: {
                array: res.transportAreas,
                dict: data()
            }})


            // console.log(data)
        } catch (err) {
            console.log(err.response)
        }
    }

    const toggleMenuMode = () => {
        setToggle(!toggle);
    }

    const closeMenu = () => {
        setToggle(false)
    }

    useEffect(() => {
        nessesaryDataLoad()
        return () => {
            CancelToken.cancel("Component got unmounted");
        };
    }, [])

    const nessesaryDataLoad = async () => {
        setIsFullFill(false)
        try {
            const promisses = [
                getRights(),
                getTransportAreaCodes(),
                getClients(),
                getPackeges(),
                getWorkers(),
                loadDefaults(getToken, LOAD_DEFAULTS, TABLE_OF_TABLES, dispatch)
            ]
            await Promise.all(promisses)

            setIsFullFill(true)
        } catch (err) {

        } finally {

        }
    }

    useEffect(() => {
        if (userRuls.includes('CUSTOMER')) {
            setSubName('לקוח')
        }
        if (userRuls.includes('GROWER')) {
            setSubName('מגדל')
        }
        if (userRuls.includes('SELLER')) {
            setSubName('משווק')
        }
        if (userRuls.includes("ADMIN")) {
            setSubName('מנהל')
        }
    }, [])

    React.useEffect(() => {
        console.log('Current Version:', process.env.REACT_APP_BUILD_VERSION);
      }, []);
      
    useEffect(() => {
        setIsOnline(window.navigator.onLine)
    }, [window?.navigator?.onLine])

    const checkOnline = () => {
        setIsOnline(window?.navigator?.onLine)
    }
    // console.log(online)
    let loadScreenToggle = loadStatus ? 'flex' : 'none'; // loading spin overlay
    let logoName = useSelector(logo => logo.mainReducer.login !== null ? logo.mainReducer.user : "check connection")
    let sideMenu = toggle ? 'translateX(0)' : 'translateX(100%)'
    let userShowCog = userType !== '0' ? '' : <Link className="wrapper-container__sidebar-menu-control-bottom__wrapper-item" to="/main/settings/account" onClick={toggleMenuMode}>
        <i style={{ color: '#297f76' }} className="fas fa-cog"></i>
    </Link>
 
    const handleControlPront = () => {
        dispatch({type: SET_IS_OPEN, payload: false})
    }

    useDetectOutsideClick(burgerMenuRef, closeMenu)
    return isFullFill ? (
        <MainSection className="wrapper-menu">
            {
                printerState?.isOpen &&
                <ColumModal 
                    title='הגדרות מדפסת'
                    Children={PrinterSettingsModal}
                    controlFunc={handleControlPront}
                    childrenProps={{}}
                />
            }
            <LoadingModal style={{ display: loadScreenToggle }}><LoaderSpinner /></LoadingModal>
            <IfDisconnect status={!iOnline} action={checkOnline} />
          
            <div className="wrapper-container">
                {
                    !toggle &&
                        <div className="wrapper-container__humbMenu" ref={buttonRef}>
                            <input type="button"
                                className="wrapper-container__humbMenu-btn"
                                onClick={toggleMenuMode}
                                ref={burgerRef}
                            />
                            <i style={{ fontSize: '1.7em', color: '#80e081' }} className="fas fa-bars"></i>
                        </div>
                }
                <SideBarWrapper style={{ transform: sideMenu }}>
                    <SideBarInnerContainer ref={burgerMenuRef}>
                        <LogoContainer>
                            <LangSwitcher />
                            <div className="wrapper-container__sidebar-menu-logoname" style={{ display: 'flex', flexFlow: 'column' }}>
                                <p style={{ textAlign: 'right', fontWeight: '600', marginBottom: '0.2em' }}>{logoName.name}</p>
                                <p style={{ textAlign: 'right', fontSize: '0.9em' }}>{logoName?.company_name || ""}</p>
                            </div>
                            <LogoImgWrapper style={{ background: "url('/images/userLogo.jpg') center center no-repeat", backgroundSize: 'cover' }}>
                                <span >
                                </span>
                            </LogoImgWrapper>
                            
                        </LogoContainer>
                        <NavMenuWrapper >                           
                            {
                                modules?.map((module, index) => {
                                    const moduleNum = module.ModuleId
                                    const data = mainModules(moduleNum)
                                    if(!data) return null
                                    const isPositionOnModule = location.pathname.includes(data.link) || location.pathname.split("/")[2].includes(data.link.split("/")[2])

                                    if(module.Sub.length > 0) {
                                        const isPrinal = moduleNum === 3 ? isShowDropMenuArizot 
                                        : moduleNum === 4 ? isShowDropMenuLikut 
                                        : moduleNum === 1 ? isShowDropMenuOrders
                                        : moduleNum === 12 ? isShowDropMenuQueries
                                        : moduleNum === 13 ? isShowDropMenuBills 
                                        : moduleNum === 8 ? isShowDropMenuPallets
                                        : moduleNum === 6 ? isShowDropMenuAfacades
                                        : moduleNum === 11 ? isShowDropMenuKnisa
                                        :''
                                        const handleOnClick = () => {
                                            if(moduleNum === 3) {
                                                SetIsShowDropMenuArizot(!isShowDropMenuArizot)
                                            } else if(moduleNum === 4) {
                                                SetIsShowDropMenuLikut(!isShowDropMenuLikut)
                                            } else if (moduleNum === 1) {
                                                setIsShowDropMenuOrders(!isShowDropMenuOrders)
                                            } else if (moduleNum === 12) {
                                                setIsShowDropMenuQueries(!isShowDropMenuQueries)
                                            } else if (moduleNum === 13) {
                                                SetIsShowDropMenuBills(!isShowDropMenuBills)
                                            } else if (moduleNum === 8) {
                                                setIsShowDropMenuPallets(!isShowDropMenuPallets)
                                            } else if (moduleNum === 6) {
                                                setIsShowDropMenuAfacades(!isShowDropMenuAfacades)
                                            } else if (moduleNum === 11) {
                                                setIsShowDropMenuKnisa(!isShowDropMenuKnisa)
                                            
                                            }
                                        }
                                        return <NavItemContainer key={index}>
                                                <NavItemWrapper isLocationHere={isPositionOnModule} onClick={handleOnClick}>
                                                    <NavHoverDecoration isLocationHere={isPositionOnModule} />
                                                    <p>{data?.name}</p>
                                                    <IconWrapper ><PackageIcon /></IconWrapper>
                                                </NavItemWrapper>
                                                <NavItemDropMenuContainer style={{ height: isPrinal ? 'auto' : '0' }}>
                                                    {
                                                        module.Sub?.map((submodule, index) => 
                                                        {
                                                            const subModuleData = data.subModules[submodule.Sub_ModuleID]
                                                            return <Link 
                                                                        key={index}
                                                                        style={{ width: '100%' }} 
                                                                        to={subModuleData?.link} 
                                                                        onClick={toggleMenuMode}
                                                                    >
                                                                        <NavItemDropMenuItem 
                                                                            primal={isPrinal}
                                                                            onClick={() => { 
                                                                                // if likut bli azmana
                                                                                if(moduleNum === 4 && submodule.Sub_ModuleID === 2) {
                                                                                    dispatch({ type: LIKUT_WITHOUT_AZMANA }) 
                                                                                }
                                                                            }}
                                                                        >
                                                                            {subModuleData?.name}
                                                                        </NavItemDropMenuItem>
                                                                    </Link>
                                                        }
                                                        )
                                                    }
                                                </NavItemDropMenuContainer>
                                            </NavItemContainer>
                                    } else {
                                        return <Link 
                                                    key={index} 
                                                    style={{ width: '100%' }} 
                                                    to={data?.link} 
                                                    onClick={toggleMenuMode}
                                                >
                                                    <NavItemWrapper isLocationHere={isPositionOnModule}>
                                                        <NavHoverDecoration isLocationHere={isPositionOnModule} />
                                                        <p>{data?.name}</p>
                                                        <IconWrapper ><InvoiceIcon /></IconWrapper>
                                                    </NavItemWrapper>
                                                </Link>
                                    }
                                })
                            }
                            {
                                getLogin === 'טסט1' ?
                                    <Link style={{ width: '100%' }} to="/main/test" onClick={toggleMenuMode}>
                                        <NavItemWrapper >
                                            <NavHoverDecoration />
                                            <p>test</p>
                                            <IconWrapper ><PalletIcon /></IconWrapper>
                                        </NavItemWrapper>
                                    </Link>
                                    :
                                    ''
                            }
                        </NavMenuWrapper>
                        <ControlWrapper className="wrapper-container__sidebar-menu-control-bottom">
                            <LogOutBtn />
                            <IconWrapperControl>
                                {userShowCog}
                            </IconWrapperControl>
                        </ControlWrapper>
                        <Logo />
                    </SideBarInnerContainer>
                </SideBarWrapper>
                <ContentWrapper>
                    <BrodMigdalProvider>
                        <ContextCefi.Provider value={[cefiNum, setCefiNum]}>
                            <ErrorBoundary
                                FallbackComponent={GeneralFallback}
                            >
                                <AppRouting />
                            </ErrorBoundary>
                        </ContextCefi.Provider>
                    </BrodMigdalProvider>
                </ContentWrapper>
            </div>
        </MainSection>
    ) :
    <LoadingModal>
        <LoaderSpinner />
    </LoadingModal>
}

export default SecondScreen