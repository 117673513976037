import React, {useMemo} from 'react'
// import { IEntriesItem } from '../../../../../modules/API/Miun/models/sortingList'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { AppleIcon, BarCodeIcon, DeleteIcon, DivideIcon, InvoiceIcon, PackageIcon, PrintIcon, ScalesIcon, UserIcon } from '../../../../../generic/icons/Icons'
import styled from 'styled-components'
import { EXIST_PATH } from '../../../../../modules/API/settings'
import { IPalletTypeItem } from '../../../../../modules/API/Defaults/models/pallets'
import { IPackagesItem } from '../../../../../modules/API/Defaults/models/packages'
import { IEntriesKnisotItem } from '../../../../../modules/API/Knisot/models/entrie'
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import SwipeMenuComponent from '../../Pallets/LikutPrahim/Components/SwipeMenuComponent'
import SwipeContent from './SwipeContent'
import { useTranslation } from 'react-i18next'

interface IProps extends IEntriesKnisotItem {
    hashPallets?: Record<number, IPalletTypeItem>
    hashPacks?: Record<number, IPackagesItem>
    handleSelectItem?: (item: IEntriesKnisotItem) => void
    item: IEntriesKnisotItem,
    print?: (entryNum: number | string) => void,
    isOffice?: boolean,
    handleDeleteItem?: (item: IEntriesKnisotItem) => void
    selectForDivide?: (item: IEntriesKnisotItem) => void
}

const EntryDetailsItem = ({
    Pack_Name, EntryNum, DetailNum, Gross, Invoice, hashPallets,
    VarietyDesc, ProductDesc, SizeDesc, AmountPack, GeneralProductID,
    AmountPallets, TypePallet, hashPacks, CalculationMethod, TypePack,
    Discount, handleSelectItem, item, print, isOffice,  WeightNeto,
    handleDeleteItem, PalletDetailsCount, selectForDivide, GrowerName

}: IProps) => {
    const [t] = useTranslation('knisa')
    const [isShowDelete, setIsShowDelete] = React.useState<boolean>(false)
    const handleSelect = () => {
        if(!handleSelectItem) return 
        handleSelectItem(item)
    }
    // const weightNetto = useMemo(() => {
    //     console.log(hashPallets, hashPacks)
    //     if(!hashPallets || !hashPacks) return Gross
    //     // CaclulationMethod  :0 - weight, : 1: Packs
    //     const isNeedCalcPackage = CalculationMethod === 1 
    //     const percentsDiscount = 100 - (Discount || 0)
    //     const palletsWeight =  ((hashPallets[TypePallet]?.Tare_Weight || 0) * AmountPallets)
    //     const packagesWeight = ((hashPacks[TypePack]?.Tare_Weight || 0) * (isNeedCalcPackage ? AmountPack : 1))
        
    //     return Math.round(
    //         (Gross - palletsWeight - packagesWeight) 
    //     )

    // }, [Discount, hashPallets, TypePallet, AmountPallets, Gross, hashPacks, TypePack, CalculationMethod, AmountPack])
    const handleDelete = (e: React.MouseEvent) => {
        e.stopPropagation()
        if(!handleDeleteItem) return
        handleDeleteItem(item)
    }
    const handleSelectForDivide = () => {
        if(!selectForDivide) return
        selectForDivide(item)
    }
    const handleShowDelete = () => {
        setIsShowDelete(!isShowDelete)
    }
    const handlePrint = (e: React.MouseEvent) => {
        e.stopPropagation()
        if(!print) return
        print(`${EntryNum}-${DetailNum}`)
    }
    const img = GeneralProductID !== 0 ? 
        `${EXIST_PATH}/images/products/${GeneralProductID}.png` 
            : '/emptyBox.png'


  return (
    <TitleLayout>
        <SwipeableListItem
            swipeLeft={
                
                PalletDetailsCount === 0 ? {
                content: <SwipeContent 
                    text={t("knisa_create_element_swipe_delete")}
                    isPrimal
                    position='right'
                />,
                action: handleShowDelete,
                
            } : undefined
            }
            swipeRight={{
                content: <SwipeContent 
                    text={t("knisa_create_element_swipe_edit")}
                />,
                action: handleSelect
            }}
            threshold={0.3}
            blockSwipe={!isOffice}
        >
            <MainWrapper>
                {
                    
                    <SideWrapper>
                        {
                            isOffice && (
                                <IconElement
                                    onClick={handlePrint}
                                >
                                    <PrintIcon />
                                </IconElement>
                            )
                        }
                        {
                            selectForDivide && isOffice && (
                                <IconElement
                                    onClick={handleSelectForDivide}
                                >
                                    <DivideIcon />
                                </IconElement>
                            )
                        }
                      
                    </SideWrapper>
                }
                {/* {
                    selectForDivide && (
                        <SideWrapper
                            onClick={handleSelectForDivide}
                        >
                            
                        </SideWrapper>
                    )
                } */}
                <PictureWrapper>
                    <Img
                        src={img}
                    />
                </PictureWrapper>
                <TitleRow>
                    <TitleRowItem name={`${ProductDesc?.trim()} ${VarietyDesc?.trim()} ${SizeDesc?.trim()}`} icon={<AppleIcon />}/>
                    <TitleRowItem name={`${EntryNum}-${DetailNum}`} icon={<BarCodeIcon />}/>
                </TitleRow>
                <TitleRow>
                    <TitleRowItem name={`${Pack_Name} - ${AmountPack}`} icon={<PackageIcon />}/>
                </TitleRow>
                <TitleRow>
                    <TitleRowItem name={`(נטו) ${WeightNeto.toFixed(1)}`} icon={<ScalesIcon />}/>
                    <TitleRowItem name={`(ברוטו) ${Gross}`} icon={<ScalesIcon />}/>
                </TitleRow>
                    {
                        Invoice !== 0 &&
                        <TitleRow>
                                <TitleRowItem name={`${Invoice}`} icon={<InvoiceIcon />}/>
                        </TitleRow>   
                    }
                    {
                        GrowerName && (
                            <TitleRow>
                                <TitleRowItem name={GrowerName.trim()} icon={<UserIcon />}/>
                            </TitleRow>
                        )
                    }
            </MainWrapper>
            {
                isShowDelete &&
                <DeleteWrapper
                    onClick={handleDelete}
                >
                    <DeleteIcon />
                </DeleteWrapper>
            }
        </SwipeableListItem>
    </TitleLayout>
  )
}
const PictureWrapper = styled.div`
    position: absolute;
    top: calc(50% - 2em);
    width: 4em;
    height: 4em;
    /* background: red; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    /* :hover {
        img {
            display: none;

        }
    } */
`
const DeleteWrapper = styled.div`
  width: 3em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: tomato;
  color: #fff;
  margin-left: 0.5em;
  border-radius: 0 15px 15px 0;
  cursor: pointer;
`

const Img = styled.img`
    user-select: none;
    width: 110%;
    
`
const IconWrapper = styled.div`
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: red; */
`
const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`
const SideWrapper = styled.div`
    position: absolute;
    /* width: 4em; */
    height: 4em;
    left: 4em;
    gap: 0.5em;
    top: calc(50% - 2em);
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: red; */
    transition: 0.3s ease-in-out;
    color: #297F76;
    cursor: pointer;
    
`
const IconElement = styled.div`
    padding: 0.5em;
    :hover {
        color: tomato;
    }
`
export default EntryDetailsItem