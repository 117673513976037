import React, { useState } from 'react'
import styled from 'styled-components'

interface ISwitchProps {
  defaultChecked?: boolean
  onChange?: (checked: boolean) => void,
  index?: string,
  disabled?: boolean
}




const ToggleIOS: React.FC<ISwitchProps> = ({
  index, 
  defaultChecked = false, 
  onChange ,
  disabled
}) => {
  const [checked, setChecked] = useState(defaultChecked)
  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return
    const newVal = e.target.checked
    setChecked(newVal)
    if (onChange) {
      onChange(newVal)
    }
  }

  const ref = index ? index : 'ios-switch'
  return (
      <Container>
        <Checkbox
          type="checkbox"
          checked={checked}
          onChange={handleToggle}
          id={ref}
        />
        <Switch checked={checked} htmlFor={ref}>
          <Slider checked={checked} />
        </Switch>
      </Container>
  
  )
}

const Container = styled.div`
  width: 41px;
  height: 21px;
  position: relative;
`

const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
`

interface SwitchProps {
  checked: boolean
}

const Switch = styled.label<SwitchProps>`
  width: 100%;
  height: 100%;
  display: block;
  background-color: ${({ checked }) => (checked ? '#34C759' : '#e9e9eb')};
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s ease-out;
`

const Slider = styled.span<SwitchProps>`
  width: 21px;
  height: 21px;
  position: absolute;
  left: ${({ checked }) =>
    checked ? 'calc(50% - 21px/2 + 10px)' : 'calc(50% - 21px/2 - 10px)'};
  top: calc(50% - 22px/2);
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-out;
  cursor: pointer;
`
export default ToggleIOS