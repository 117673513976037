import React from 'react'
import styled from 'styled-components'

const Border = styled.div`
  position: absolute;
  border: 0.15em solid #fff;
  transition: all 0.3s 0.08s linear;
  top: 50%;
  left: 50%;
  width: 9em;
  height: 3em;
  transform: translate(-50%, -50%);
  border-radius: 2em;
`

const StyledButton = styled.button`
  font-size: 16px;
  position: relative;
  /* margin: auto; */
  width: 9em;
  cursor: pointer;  
  padding: 1em 2.5em;
  border: none;
  background: #fff;
  transition: all 0.1s linear;
  box-shadow: 0 0.4em 1em rgba(0, 0, 0, 0.1);
  border-radius: 2em;

  &:active {
    transform: scale(0.95);
  }

  &:hover ${Border} {
    width: 9.9em;
    height: 3.7em;
  }
`

const StyledSpan = styled.span`
  color: #464646;
`

interface FancyButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
}

const FancyButton: React.FC<FancyButtonProps> = ({ children, ...props }) => {
  return (
    <StyledButton {...props}>
      <StyledSpan>{children}</StyledSpan>
      <Border />
    </StyledButton>
  )
}

export default FancyButton