import React from 'react'
import styled from 'styled-components'
import FancyButton from './FancyButton'
import SearchGrower from '../../knisot/Create/Components/SearchGrower'
import SearchProduct from '../../forcasts/Products/Components/SearchProduct'
import DatePickerISO from '../../forcasts/Distribution/Components/DatePickerISO'
import { IGrowerItem } from '../../../../../modules/API/Defaults/models/growers'
import { IProductListItemNode } from '../../../../../modules/API/Defaults/models/productListNode'

import DatePickerRangeISO from './DatePickerRangeISO'

type Props = {
  handleSearchByProduct: () => void
  selected: Date[]
  onChange: React.Dispatch<React.SetStateAction<Date[]>>
  closeWindow?: () => void,
  setGrowerID: React.Dispatch<React.SetStateAction<number>>,
  setProductID: React.Dispatch<React.SetStateAction<number>>,
  growers: IGrowerItem[],
  products: IProductListItemNode[],
  growerID: number,
  productID: number,
  isGrower: boolean
}

const FormComponent = ({
  handleSearchByProduct,
  selected,
  onChange,
  closeWindow,
  setGrowerID,
  setProductID,
  growers,
  products,
  growerID,
  productID,
  isGrower,
}: Props) => {
  const handleButtonClick = () => {
    handleSearchByProduct()
    closeWindow && closeWindow()
  }
  return (
    <MainWrapper>
    <Wrapper>
      <DatePickerRangeISO 
        selectedDates={selected}
        onChange={onChange}
      />
      {/* <DatePickerISO
        selected={selected}
        onChange={onChange}
      /> */}
      <SearchProduct
        list={products}
        defaultProductID={productID}
        setDefaultProductID={setProductID}
      />
      <SearchGrower
        list={growers}
        defaultID={growerID}
        setGrowerID={setGrowerID}
        disabled={isGrower}
      />
    </Wrapper>
      <FancyButton
        onClick={handleButtonClick}
      >
        חפש
      </FancyButton>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 250px;
  align-items: center;
`
const Wrapper = styled.div`
  padding: 0.5em;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  background: #fff;
  border-radius: 30px;
`
export default FormComponent