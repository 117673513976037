export enum ETYPES_OF_BM {
  Num_Value = 'Num_Value',
  Text_Value = 'Text_Value',
  Date_Value = 'Date_Value',
  Time_Value = 'Time_Value',
  Logical_Value = 'Logical_Value',
}
export const TYPES_OF_BM = {
  "1":  ETYPES_OF_BM.Num_Value,
  "2":  ETYPES_OF_BM.Text_Value,
  "3":  ETYPES_OF_BM.Date_Value,
  "4":  ETYPES_OF_BM.Time_Value,
  "5":  ETYPES_OF_BM.Logical_Value,
}
