import OrderlikeLayout from '../../../../generic/Layouts/OrderlikeLayout'
import useDataKnisaLines from './Services/useDataKnisaLines'
import BodyScrollContainer from '../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import TitlesList from '../../../../generic/titlesRowItem/TitlesList'
import EntryDetailsItem from '../knisot/Components/EntryDetailsItem'
import ColumModal from '../../../../generic/Layouts/ColumModal'
import DivideModal from '../miun/modal/DivideModal'
import ModalKnisaAddProduct from '../knisot/Create/Components/Modal/ModalKnisaAddProduct'
import { useTranslation } from 'react-i18next'
import GetKnisaLines from './Components/GetKnisaLines'

const KnisotLinesMain = () => {
  const [t] = useTranslation('knisa')

  const {
    knisaDetailsData,
    setKnisaDetailsData,
    getKnisaLineByBarcode,
    getKnisotLinesByProduct,
    handleOpenDivideModal,
    divideModalP,
    isOpenDivideModal,
    selectedItem,
    addProductProps,
    handleControlProduct,
    isOpenProduct,
    detailsProps
  } = useDataKnisaLines()

  return (
    <OrderlikeLayout
      label="שורות כניסה"
    >
      {
        isOpenDivideModal &&
          <ColumModal
            title={`${divideModalP.item?.EntryNum}-${divideModalP.item?.DetailNum} פיצול כניסה`}
            Children={DivideModal}
            childrenProps={divideModalP}
            controlFunc={handleOpenDivideModal}
          />
      }
       {
        isOpenProduct &&
          <ColumModal 
            title={selectedItem ? t("knisa_create_modal_product_title_edit") : t("knisa_create_modal_product_title_create")}
            Children={ModalKnisaAddProduct}
            controlFunc={handleControlProduct}
            childrenProps={addProductProps}
          />
      }
      <GetKnisaLines
         getKnisaLineByBarcode={getKnisaLineByBarcode}
         getKnisotLinesByProduct={getKnisotLinesByProduct}
      />
      
      <BodyScrollContainer>
        {
          knisaDetailsData &&
            <TitlesList
              list={knisaDetailsData}
              uniqueKey='id'
              Element={EntryDetailsItem}
              noPadding={false}
              backgroundColor='#e9f3f2'
              additionalData={detailsProps}
            />
        }
      </BodyScrollContainer>
    </OrderlikeLayout>
  )
}



export default KnisotLinesMain