import React, { useState, useRef, ReactNode, ReactElement } from 'react';
import styled from 'styled-components';
import { useDetectOutsideClick } from '../../../../../generic/hooks/useDetectOutsideClick';

type Props = {
    icon: JSX.Element;
    children: ReactNode;
    isSelected?: boolean;
    isOreintaionRight?: boolean;
    isSidePosition?: boolean;
};

interface FilterP {
    common?: boolean; 
    isOpen?: boolean;
    isOreintaionRight?: boolean;
    isSidePosition?: boolean;
}

interface ChildProps {
    closeWindow: () => void;
}

const DropdownByIcon = ({ icon, children, isSelected, isOreintaionRight, isSidePosition }: Props) => {
    const [isOpenDistrictSelect, setIsOpenDistrictSelect] = useState(false);
    const bodyRef = useRef(null);
    const openCloseBody = () => {
        setIsOpenDistrictSelect(!isOpenDistrictSelect);
    };

    useDetectOutsideClick(bodyRef, () => setIsOpenDistrictSelect(false));

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child as ReactElement<ChildProps>, { closeWindow: openCloseBody });
        }
        return child;
    });

    return (
        <FilterIndicator ref={bodyRef}>
            {isSelected && <SelectedIndicator />}
            <ButtonWrapper onClick={openCloseBody}>
                {icon}
            </ButtonWrapper>
            <FilterBodyWrapper
                isSidePosition={isSidePosition}
                isOreintaionRight={isOreintaionRight}
                isOpen={isOpenDistrictSelect}
            >
                {isOpenDistrictSelect && childrenWithProps}
            </FilterBodyWrapper>
        </FilterIndicator>
    );
};
export const FilterIndicator = styled.div<FilterP>`
    position: relative;
    height: 3.5em;
    min-width: 3.5em;
    background: #eff3f4 ;
    border-radius: 50%;
    transition: 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    color: #297f76;
    display: flex;
    justify-content: center;
    align-items: center;
    
    :hover {
        background: ${props => props.common ? '#eff3f4' : '#297f76'} ;
    }
    
`
const SelectedIndicator = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: tomato;
`

export const ButtonWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: #eff3f4;
    border-radius: 30px;
    align-items: center;
    cursor: pointer;
    transition: 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    :hover {
        transform: translateX(3px) rotate(45deg) translateY(-3px);
        
       
    }
    /* :hover ${FilterIndicator}  {
        background: #297f76 ;
       
    } */
`

export const FilterBodyWrapper = styled.div<FilterP>`
    position: absolute;
    top: ${props => props.isSidePosition ? 'none' : "3.5em"};
    left: ${props => props.isOreintaionRight ? 'none' : '0'};
    right: ${props => props.isOreintaionRight ? '0' : 'none'};
    background: #eff3f4;
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 1em;
    padding: 0.5em;
    border-radius: 30px;
    z-index: 2;
    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
    img {
        min-width: 300px;
    }

`

export const ClearButton = styled.div`
  position: absolute;
  top: 0.5em;
  left: 1em;
  padding: 0.2em 0.4em;
  background: tomato;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;

`
export default DropdownByIcon