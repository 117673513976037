import React from "react";
import styled from "styled-components";
import SearchInput from "../inputs/inputSearch";
import RequiredFieldMark from "../particles/RequiredFieldMark";
import PropTypes from "prop-types";
import { useDetectOutsideClick } from "../hooks/useDetectOutsideClick";
import useFocus from "../hooks/useFocus";

export const SearchWrapper = styled.div`
  position: relative;
  /* background: red; */
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const ListContainerClient = styled.div`
  /* position: absolute; */
  top: 3.5em;
  right: 0;
  width: 100%;
  /* margin: 0 auto; */
  border-radius: 0px 0 5px 10px;
  /* max-height: 180px; */
  overflow: auto;
  z-index: 1;
  /* background: #fdfdfd; */
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-end;
  /* padding: 0.5em; */
  ::-webkit-scrollbar {
    width: 0;
  }
`;
const ClientsItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.3em 0.5em;
  transition: 0.3s;
  color: ${props => props.isColored ? "tomato" : '#868787' };
  /* background: ${(props) => (props.isColored ? "tomato" : "#ffff")}; */
  cursor: pointer;
  &:hover {
    background: #E9F3F2;
  }
  :first-child {
    margin-top: 0.5em;
  }
  :last-child {
    margin-bottom: 0.5em;
  }
`;
const SearchInputWrapper = styled.div`
  width: 100%;
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  border-radius: 30px;
  width: 100%;
  max-height: 200px;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-flow: column;
  /* gap: 0.5em; */
  background: #ffff;
  z-index: 2;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* padding-bottom: 1em; */
`;
function SearchFromTheListGeneral({
    input,
    placeHolder,
    searchFunc,
    fieldClietns,
    list,
    itemDisplayName="text",
    selectFunc,
    nameIdElement="id",
    isOpenList,
    disabled,
    required,
    size="medium",
    isNeedFullObject,
    itemSecondaryName,
    enterFunc=(e) => console.log(e),
    ref,
    isDisableFocusSelect,
    defaultValue,
    itemThirdName,
    markedLinePattern
}) {


    const refList = React.useRef(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);
    const handleSelect = (e, item) => {
        if(disabled) return
        if (isNeedFullObject) {
            selectFunc(item, e);
        } else {
            selectFunc(item[nameIdElement], item[itemDisplayName]?.trim());
        }
        handleClose();
    };

    useDetectOutsideClick(refList, handleClose);
    return (
        <SearchWrapper ref={refList}>
            <RequiredFieldMark visible={required} />
            <Overlay isOpen={disabled ? false : isOpen}>
                <SearchInput
                    input={input}
                    setInput={searchFunc}
                    value={placeHolder}
                    fieldClietns={fieldClietns}
                    disabled={disabled}
                    iconHide={disabled}
                    size={size}
                    enterFunc={enterFunc}
                    ref={ref}
                    defaultValue={defaultValue}
                    isDisableFocusSelect={isDisableFocusSelect}
                />
                <ListContainerClient>
                    {list?.map((item, index) => (
                        <ClientsItem
                            key={item.id ? item.id : index}
                            onClick={(e) => handleSelect(e, item)}
                            isColored={markedLinePattern(item)}
                        >
                            {itemThirdName
                                ? `(${item[itemThirdName]}) ${item[itemDisplayName]} ${item[itemSecondaryName]} `
                                : itemSecondaryName
                                    ? `${item[itemDisplayName]} ${item[itemSecondaryName]}`
                                    : `${item[itemDisplayName]}`}
                        </ClientsItem>
                    ))}
                </ListContainerClient>
            </Overlay>
            <SearchInputWrapper onClick={handleOpen}>
                <SearchInput
                    input={input}
                    setInput={searchFunc}
                    value={placeHolder || "חיפוש"}
                // fieldClietns={fieldClietns}
                    disabled={disabled}
                    iconHide={disabled}
                    size={size}
                    enterFunc={enterFunc}
                // ref={ref}
                    defaultValue={defaultValue}
                // isDisableFocusSelect={isDisableFocusSelect}
                />
            </SearchInputWrapper>
        </SearchWrapper>
    );
}

SearchFromTheListGeneral.propTypes = {
    list: PropTypes.array.isRequired,
    input: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    searchFunc: PropTypes.func.isRequired,
    placeHolder: PropTypes.string,
    fieldClietns: PropTypes.bool,
    itemDisplayName: PropTypes.string.isRequired,
    selectFunc: PropTypes.func.isRequired,
    nameIdElement: PropTypes.string.isRequired,
    isOpenList: PropTypes.bool,
    size: PropTypes.oneOf(["full", "large", "medium", "small"]),
    isNeedFullObject: PropTypes.bool,
    itemSecondaryName: PropTypes.string,
};

SearchFromTheListGeneral.defaultProps = {
    // placeHolder: "search",
    // primary: false,
    // selectFunc: undefined,
    // size: "medium",
    // list: [
    //     { id: 1, text: "test1" },
    //     { id: 2, text: "test4" },
    //     { id: 3, text: "test3" },
    //     { id: 4, text: "test2" },
    // ],
    // itemDisplayName: "text",
    // nameIdElement: "id",
    isOpenList: true,
    disabled: false,
    itemSecondaryName: "",
    itemThirdName: "",
    enterFunc: (e) => console.log(e),
    ref: null,
    isDisableFocusSelect: true,
    defaultValue: "",
    markedLinePattern: () => false
};
const SearchFromTheList = React.memo(SearchFromTheListGeneral);

export default SearchFromTheList;
