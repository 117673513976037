import React, { useMemo, useState } from 'react'
import { ICustomerItem, IOrderItem } from '../../../../../modules/API/Drivers/models/kavData'
import styled from 'styled-components'
import ClientsOrderItem from '../../drivers/ItemComponents/ClientsOrderItem'
import { EditIcon, InvoiceIcon, PackageIcon, PalletIcon, ScalesIcon, UserIcon } from '../../../../../generic/icons/Icons'
import { Draggable } from 'react-beautiful-dnd'
import ColumnOrderElement from './ColumnOrderElement'

interface IProps extends ICustomerItem {
    item: ICustomerItem
    index: number;
    area: number;
    ordersAllowToShow: number[];
    handleSelectToDrag: (elementID: number, kavID: number, CustomerID: number, OrderID: number, isOrder?: boolean
    ) => void;
    handleClickSelectOrder: (item: IOrderItem, area: number, CustomerID: number) => void;

}

const ColumClientElem = ({
    item, index, CustomerID, area, Orders, PO_Num, Customer_Name,
    ordersAllowToShow, handleSelectToDrag, Type, handleClickSelectOrder
}: IProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const isPackageOrder = Orders.length === 0 && PO_Num !== 0
    const calcData = () => {
        if (isPackageOrder) return { weightCurrent: 0, palletsCurrent: 0, packageQty: 0 }

        let reduced = Orders.reduce((acc: Record<string, number>, cur) => {

            if (!ordersAllowToShow.some(e => e === cur.Status)) {
                acc.weight = (acc.weight ? acc.weight : 0) + (cur.Weight || 0)
                acc.pallets = (acc.pallets ? acc.pallets : 0) + (cur.Pallets || 0)
                acc.packageQty = (acc.packageQty ? acc.packageQty : 0) + (cur.Packs || 0)


            } else {
                let palletData = cur.PalletsList.reduce((palletsResult: Record<string, number>, pallet) => {
                    palletsResult.weight = (palletsResult.weight ? palletsResult.weight : 0) + pallet.Gross
                    palletsResult.pallets = (palletsResult.pallets ? palletsResult.pallets : 0) + pallet.PallQty
                    palletsResult.packageQty = (palletsResult.packageQty ? palletsResult.packageQty : 0) + pallet.Qty

                    return palletsResult
                }, {})
                acc.weight = (acc.weight ? acc.weight : 0) + (palletData.weight || 0)
                acc.pallets = (acc.pallets ? acc.pallets : 0) + (palletData.pallets || 0)
                acc.packageQty = (acc.packageQty ? acc.packageQty : 0) + (palletData.packageQty || 0)

                // console.log(palletData.weight)
            }
            return acc
        }, {})
        return { weightCurrent: Math.round(reduced.weight), palletsCurrent: Math.ceil(reduced.pallets), packageQty: reduced.packageQty }
    }
    const { weightCurrent, palletsCurrent, packageQty } = calcData()

    const handleOpen = () => {
        setIsOpen(!isOpen);
    }
    const handleBlock = (e: React.MouseEvent) => {
        e.stopPropagation()
    }
    const isExistRemark = useMemo(() => {
        return Orders.some(order => order.DistributNote.trim())
    }, [Orders])
    const isRehesh = Type === 1
    const isPalletsExist = Orders.filter(order => ordersAllowToShow.some(e => e === order.Status))
    const colorOrderIcon = isPalletsExist.length === 0 ? 'tomato' : isPalletsExist.length === Orders.length ? '#80e081' : 'orange'
    return (
        <Draggable
            draggableId={`${CustomerID} ${area}`}
            // key={CustomerID?.toString() + area}
            index={index}
        >
            {
                (provided, snapshot) =>
                    <MainWrapper
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        onClick={handleOpen}
                        bg={!isRehesh}
                        tabIndex={-1}
                    >
                        <TitleWrapper>
                            {
                                isExistRemark && (
                                    <ArizotIconWrapper>
                                        <EditIcon />
                                    </ArizotIconWrapper>
                                )
                            }
                            <TitleRow>
                                <TitleItem>
                                    <UserIcon />
                                    {Customer_Name}
                                </TitleItem>
                            </TitleRow>
                            {
                                !isPackageOrder ?
                                <>
                                    <TitleRow>
                                        <TitleItem color={colorOrderIcon}>
                                            <InvoiceIcon />
                                            {Orders.length}
                                        </TitleItem>
                                        <TitleItem isBold>
                                            <PalletIcon />
                                            {palletsCurrent}
                                        </TitleItem>
                                    </TitleRow>
                                    <TitleRow>
                                        <TitleItem>
                                            <PackageIcon />
                                            {packageQty}
                                        </TitleItem>
                                        <TitleItem>
                                            <ScalesIcon />
                                            {weightCurrent}
                                        </TitleItem>
                                        {
                                            PO_Num !== 0 && isRehesh &&
                                            <TitleItem isAriza color='tomato'>
                                                <PackageIcon />
                                            </TitleItem>
                                        }
                                    </TitleRow>
                                </>
                                :
                                <TitleRow>
                                    <TitleItem isBold>
                                        <PackageIcon />
                                     אריזה ריקה
                                    </TitleItem>
                                </TitleRow>
                            }
                        </TitleWrapper>
                        {
                            isOpen && !isPackageOrder &&
                            <BodyWrapper
                                onClick={handleBlock}
                            >
                                <BodyTitle>
                                    הזמנות:
                                </BodyTitle>
                                {Orders.map((item, index) =>

                                    <ColumnOrderElement
                                        {...item}
                                        key={item.OrderID + area.toString()}
                                        item={item}
                                        ordersAllowToShow={ordersAllowToShow}
                                        handleSelectToDrag={handleSelectToDrag}
                                        area={area}
                                        CustomerID={CustomerID}
                                        isRehesh={isRehesh}
                                        handleClickSelectOrder={handleClickSelectOrder}
                                    />
                                )}
                            </BodyWrapper>
                        }
                    </MainWrapper>
            }
        </Draggable>
    )
}
const MainWrapper = styled.div<{ bg?: boolean }>`
position: relative;
    width: 100%;
    display: flex;
    flex-flow: column;
    background: ${props => props.bg ? '#eff3f4' : '#ccf3cd'} ;
    padding: 0.5em;
    color: #75759e;
    border-radius: 20px;
    gap: 1em;
`
const ArizotIconWrapper = styled.div`
    position: absolute;
    left: 0.3em;
    top: 0.3em;
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 50%;
    color: tomato;
`
const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;

`
export const TitleRow = styled.div`
    width: 100%;
    display: flex;
    gap: 1em;
    
`
export const TitleItem = styled.div<{ color?: string, isBold?: boolean, isAriza?: boolean }>`
    display: flex;
    gap: 0.5em;
    /* padding-right: ${props => props.isAriza ? '1em' : '0'}; */
    font-weight: ${props => props.isBold ? 'bold' : ''};
    i {
        color: ${props => props.color ? props.color : ''}
    }
    :last-child {
        /* justify-self: flex-start; */
        margin-right: ${props => props.isAriza ? 'auto' : 'none'};
    }
    
`
const BodyWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    /* border-top: 1px solid gray; */
    border-radius: 20px;
    background: #fff;
    padding: 0.5em 0.2em;

`
const BodyTitle = styled.div`
    width: 100%;
    font-size: 1.1em;
    display: flex;
    justify-content: center;
    
`
export default ColumClientElem