import React from 'react'
import styled from 'styled-components'

type Props = {
    children?: React.ReactNode | React.ReactNode[]
    flex?: string
}

const TitleRow = ({children, flex}: Props) => {
  return (
    <MainWrapper
      flex={flex}
    >
        {children}
    </MainWrapper>
  )
}
const MainWrapper = styled.div<{flex?: string}>`
    width: 100%;
    display: flex;
    justify-content: ${props => props.flex ? props.flex : 'flex-end'};
    gap: 1em;
`
export default TitleRow