import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/reducers/rootReducer'
import { IDefaults } from '../../../modules/API/Defaults/models/defaults'
import styled from 'styled-components'
import BrodMigdalItem from './Components/BrodMigdalItem'

type Props = {
  brodMigdalArraOfNumbers: number[]
}


const BrodMigdalModal = ({
  brodMigdalArraOfNumbers
}: Props) => {
  const defaults: IDefaults[] = useSelector((state: RootState) => state.defaults.defaults)
  
  const arrayOfBrodMigdal = useMemo(() => {

    return brodMigdalArraOfNumbers.map((number) => {
      const brodMigdal = defaults.find((item) => item.Int_ID === number)
      return brodMigdal || {} as IDefaults
    })
  }, [defaults, brodMigdalArraOfNumbers]) 
  return (
    <MainWrapper>
      {arrayOfBrodMigdal.map((brodMigdal, index) => (
        <BrodMigdalItem
          key={`${brodMigdal.Int_ID} ${brodMigdal.Sub_ID}`}
          {...brodMigdal}
          item={brodMigdal}
        />
      ))}
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  overflow-y: auto;
  padding: 0 0.2em;
  ::-webkit-scrollbar {
    width: 0;
  }
`
export default BrodMigdalModal