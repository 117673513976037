import styled from 'styled-components'
import TwoFactorForm from './TwoFactorForm'
import FloatingLabelInput from './InputField'
import { ArrowIcon, PhoneIcon, UserIcon } from '../generic/icons/Icons'
import useAuthService from './useAuthService'

// const regex = /^(?=.*[a-zA-Z])(?=.*\d).{4,}$/

const ForgotPasswordPage = () => {
  const {
    tel,
    codeSMS,
    restDidgitsTel,
    userName,
    isShowModal,
    isMatchCodeRecovery,
    isAlertPassword,
    isAwaitingCodeVerifying,
    handleTelephone,
    handleModal,
    handlePassword,
    handleUserName,
    sendSmsRecovery,
    verifyRecoveryCode,
    handleCodeSms,
    handleChangePassword,
    handleConfirmPassword
  } = useAuthService()
  return (
    <Section>
      {
        isShowModal && (
          <FormWrapper>
            <TwoFactorForm
              setCodeSMS={handleCodeSms}
              codeSMS={codeSMS}
              isLoading={isAwaitingCodeVerifying}
              verify={verifyRecoveryCode}
              closeForm={handleModal}
            />
          </FormWrapper>
        )
      }
      <Container>
        <Wrapper onSubmit={handleChangePassword}>
          <MainBlock>
            <ContentWrapper logo>
              <Tag logo>
                איפוס סיסמה
                <BackBox />
              </Tag>
            </ContentWrapper>
            <ContentWrapper>
              <FloatingLabelInput
                value={userName}
                tag='שם משתמש'
                icon={<UserIcon />}
                autoComplete='off'
                onChange={handleUserName}
              />
            </ContentWrapper>
            <ContentWrapper>
              <FloatingLabelInput
                tag='טלפון נייד'
                icon={<PhoneIcon />}
                value={tel}
                disabled={isMatchCodeRecovery}
                onChange={handleTelephone}
                type={'tel'}
                helperText={ restDidgitsTel > 0 ? `נשארו ${restDidgitsTel} ספרות` : undefined}
                // isNeedAButton={restDidgitsTel === 0 && !isMatchCodeRecovery}
                // handleIconClick={sendSmsRecovery}
              />
            </ContentWrapper>
            {
              !isMatchCodeRecovery && (
                <ContentWrapper>
                  <Button onClick={sendSmsRecovery}>
                    שלח קוד
                  </Button>
                </ContentWrapper>
              )
            }
            {
              isMatchCodeRecovery && (
                <>
                  <ContentWrapper>
                    <FloatingLabelInput
                      tag='סיסמה'
                      icon={<i className="fas fa-lock"></i>}
                      onChange={handlePassword}
                      type={'password'}
                      autoComplete='new-password'
                      alert={isAlertPassword}
                    />
                  </ContentWrapper>
                  <ContentWrapper>
                    <FloatingLabelInput
                      tag='לאשר את הסיסמה'
                      icon={<i className="fas fa-lock"></i>}
                      onChange={handleConfirmPassword}
                      type={'password'}
                      autoComplete='new-password'
                      alert={isAlertPassword}
                    />
                  </ContentWrapper>
                </>
              )
            }
            {
              isMatchCodeRecovery && !isAlertPassword && (
                <ContentWrapper>
                  <Button type="submit">
                    שנה סיסמה
                  </Button>
                </ContentWrapper>
              )
            }
          </MainBlock>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default ForgotPasswordPage

const Section = styled.section`
    width: 100%;
    height: 100vh;
    background: #e9f0f0;
    position: fixed;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`
const FormWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    background-color: rgba(0,0,0,0.5);
    padding: 0 0.5em;
`
const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5em;
    
    
`
const Wrapper = styled.form`
    color: #1F1F1F;
    width: 100%;
    height: 550px;
    background: #fdfdfd;
    border-radius: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.41));
    z-index: 2;
    @media screen and (min-width: 400px) {
        width: 400px;
    }
   
`
const MainBlock = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    flex-flow: column;
    gap: 1em;
    
    align-items: center;
    justify-content: flex-start;
    
`
export const ContentWrapper = styled.div<{ row?: boolean, logo?: boolean, button?: boolean }>`
    /* width: 80%; */
    height: ${props => props.logo ? '15%' : props.button ? '12%' : '20%'};
    display:flex;
    flex-flow: ${props => props.row ? 'row' : 'column'};
    align-items: ${props => props.row ? 'center' : 'flex-end'} ;
    justify-content: ${props => props.row ? 'flex-end' : 'center'};
    
`

export const IconInput = styled.div`
    position: absolute;
    height: 100%;
    width: 2.5em;
    i {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.5s ease;
        color: #1F1F1F;
    }
`
const Button = styled.button`
    width: 200px;
    padding: 0.5em 1em;
    background: #7ab0ab;
    border: none;
    border-radius: 10px;
    font-size: 1.2em;
    color: #fff;
    cursor: pointer;
    transition: 0.2s ease;

    :active{
        background: #297f76;
    }

    :disabled{
        p {
            display: none;
        }
        i {
            display: block;
            // padding: 0.2em 1em;
        }
    }
    i {
        display: none;
        animation: spin 1s infinite ;
        @keyframes spin {
            0% {transform: rotate(0);}
            100% {transform: rotate(360deg);}
        }
    }
`
export const Tag = styled.label<{ logo?: boolean }>`
    font-size: ${props => props.logo ? '2em' : '1em'};
    font-family: sans-serif;
    position: relative;
    z-index: 2;
`
export const BackBox = styled.div`
    position: absolute;
    width: 2.5em;
    left: -0.4em;
    top: 0.5em;
    height: 50%;
    background: rgba(122,176,171, 0.7);
    z-index: -1;
`